import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

const useStyles = makeStyles(() => ({

    searchInputBox: {
        backgroundColor: "#fff",
        border: '1px solid rgb(112,112,112,0.4)',
        padding: '0.6rem 1rem',
        // height: '2rem',
        display: 'flex',
        alignItems: 'center',
        margin: '0 0.3rem 0.5rem 0.3rem',
        borderRadius: '3px',
        "& input": {
            width: "95%",
            backgroundColor: "#fff",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            }
        },
        "& i": {
            color: "#888888"
        }
    },


    btnBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        position: 'relative',
        '& > .MuiButtonBase-root': {
            width: '130px',
            height: '35px',
            minWidth: '100px',
            padding: 0,
            fontWeight: 700,
            letterSpacing: 1,
            borderRadius: 2,
            fontSize: '0.65rem'
        },
        '& > .MuiButton-contained': {
            backgroundColor: '#f4511e',
            // color: '#fff',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e'
            }
        },
        '& > .MuiButton-outlined': {
            backgroundColor: '#fff',
            // color: '#f4511e',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

            }
        },
        '& > h1': {
            position: "absolute",
            right: "3%",
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
        }

    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 8
            // backgroundColor: '#F2F2F2'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px',
        },
    },
    toggleContainer: {
        background: theme.palette.white,
        borderRadius: '2rem',
    },
    clearBtn: {
        background: `theme.palette.white !important`
    }
}))

export default useStyles