import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// styles 
import useStyles from './index.styles';

// material UI 
import { Button } from '@mui/material';

const statusButtonArray = [
    {
        name: 'All',
        key: 'all',
    },
    {
        name: 'Not Started',
        key: 'notStarted',
    },
    {
        name: 'In Progress',
        key: 'inProgress',
    },
    {
        name: 'Completed',
        key: 'completed',
    },
];

const ProgressStatusMenu = (props) => {

    const { handleStatusFilterClick, selectedStatusFilter } = props;
    const { isDarkMode } = useSelector(state => state?.commonPersist);

    const classes = useStyles({ isDarkMode });

    // refs : 
    const buttonContainerRef = useRef(null);

    // Handle scrolling to the center button on click
    const handleButtonClick = (itemKey) => {
        handleStatusFilterClick(itemKey);

        const index = statusButtonArray.findIndex(item => item.key === itemKey);
        const buttonWidth = buttonContainerRef.current?.children[index]?.offsetWidth;

        if (buttonContainerRef.current && buttonWidth) {
            const containerWidth = buttonContainerRef.current.offsetWidth;
            const offset = (containerWidth - buttonWidth) / 2;
            buttonContainerRef.current.scrollTo({
                left: index * buttonWidth - offset,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div ref={buttonContainerRef} className={`${classes.progressStatusMenuContainer} scrollbar-hidden`} style={{ overflowX: 'auto', display: 'flex' }}>
            {statusButtonArray?.map((item, index) =>
            (
                <Button
                    key={index}
                    variant="contained"
                    fullWidth
                    size="large"
                    className={`${classes.statusMenuBtn} ${selectedStatusFilter === item.key ? classes.selectedBtn : ""}`}
                    onClick={() => { handleButtonClick(item?.key) }}
                    sx={theme => ({
                        minWidth: 'fit-content',
                        borderRadius: '2rem',
                        color: `${theme.palette.fontColor}`,
                        backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
                        whiteSpace: 'nowrap',
                        boxShadow: 'none !important',
                        "&:hover": {
                            backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
                            boxShadow: 'none !important',
                        },
                    })}
                >
                    {item?.name}
                </Button>
            )
            )}
        </div>
    )
}

export default ProgressStatusMenu;
