import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    otpContainer: {
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: '0.5rem',
        [theme.breakpoints.down("md")]: {
            // marginBottom: '1rem',
        },
    }
}))

export default useStyles