import { makeStyles } from "@mui/styles";
import constants from "../../../../constants";
import palette from "../../../../theme/palette";

const useStyles = makeStyles((theme) => {
    // console.log({theme});
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileDrawer: {
            zIndex: '9999 !important',
            '& .MuiDrawer-paper': {
                width: '100% !important',
                backgroundColor: `${theme.palette.voilet} !important`,
                backgroundImage: 'none !important'
            },
        },
        search: {
            // background: theme.palette.mobileMainContainerBg,
            // backgroundColor: theme.palette.lightVoilet1,
            // backgroundColor: theme.palette.lightVoilet1,
            backgroundColor: `${theme.palette.lightVioletToGrey} !important`,

            '&:hover': {
                backgroundColor: theme.palette.lightVoilet2,
                // backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
        },
        searchBox: {
            position: 'fixed',
            width: '100%',
            minHeight: '2rem',
            minHeight: '44px',
            height: '5%',
            top: '2%',
            padding: '0 1rem',
            zIndex: '2',
            // bottom: '-1rem',
            // top: '50%',
            [theme.breakpoints.down('md')]: {
                left: '0%',
                top: '1%'
            },
            [theme.breakpoints.down('sm')]: {
                left: '0%',
            },

            // transform: 'translate(-50% , -50%)',
            // padding: '1rem 1rem 0.5rem 1rem',
            '& input': {
                width: '100%',
                height: '100%',
                padding: '0 2rem 0 2rem',
                outline: theme.palette.orange,
                borderRadius: '4px',
                '&:focus': {
                    border: `2px solid ${theme.palette.orange}`,
                }
            },
            '& img': {
                opacity: '1',
                position: 'absolute',
                transform: 'translateY(-50%)',
            }
        },
        searchIcon: {
            left: '1.5rem',
            top: '50%',
        },
        closeIcon: {
            right: '1.5rem',
            top: '50%',
        },

        rolllerBox: {
            width: '100%',
            overflowY: 'hidden',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '2%',
            minHeight: '44px',
            height: '5%',
        },
        rollerItem: {
            width: '100%',
            height: 30,
            fontSize: '1rem',
            animation: '$scroll 10s linear infinite',
            position: 'absolute',
            top: '0',
            left: '0',
            visibility: 'hidden',
            color: `${theme.palette.fontColor}c7`,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: '500',
            lineHeight: "2.5rem",
        },
        "@keyframes scroll": {
            "0%": {
                top: '100%',
                visibility: 'visible'
            },
            '10%': {
                top: '5.5%'
            },
            '30%': {
                top: '5.5%'
            },
            '40%': {
                top: '-100%',
                visibility: 'hidden'
            },
            '100%': {
                top: '100%',
                visibility: 'hidden'
            }
        },
        searchInputBox: {
            position: 'relative'
        },

    })
})

export default useStyles