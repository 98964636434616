import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// Components : 
import BulletinBoard from "../../components/bulletin-board";
import BulletinHeader from "../../components/bulletin-board/bulletin-header";

// Styles : 
import { makeStyles } from "@mui/styles";

// Constants : 
import constants from "../../constants";

const Bulletins = () => {

    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);

    const [searchParams, setSearchParams] = useSearchParams();
    const classes = useStyles();

    useEffect(() => {
        if (!searchParams.get('name')) {
            searchParams.set('name', 'Bulletin Board');
            setSearchParams(searchParams, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isTablet]);

    return (
        <div className={classes.root}>
            <BulletinHeader />
            <BulletinBoard />
        </div>
    )
}

export default Bulletins;

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            maxHeight: '100dvh',
            overflowY: 'hidden'
        },
        [theme.breakpoints.up("md")]: {
            maxHeight: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'row',
        }
    }
}))