// export default Game;
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from 'react-lottie-player';
import BillaMotionLottie from '../../../lottie-assets/football/billa.json';
import RotaBillaLottie from '../../../lottie-assets/football/rotaBilla.json';
import HastaBillaLottie from '../../../lottie-assets/football/hastaBilla.json';

// components
import StartScreen from "../common-screens/start-screen";
// import HowToPlay from "../how-to-play/scq-how-to-play";
import GbaWinScreen from "../common-screens/win-screen";
import GbaGameOverScreen from "../common-screens/game-over-screen";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";


// style
import useStyle from "./index.styles";
import palette from "../../../theme/palette";

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

let heartImgPath = '/images/icons/heart.png';
let redHeartWatchImgPath = '/images/icons/heart-red.png';

const Football = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;

    const isMobile = useSelector(state => state?.common?.isMobile);
    const questionData = gameData?.questions;
    const { microskillId, challengeId } = useParams();

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let questionsInGba = questionData.length;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });
    let minBillaTimeAnim = 1.5;
    let maxBillaTimeAnim = 3.5;
    let speedIncreasePerQues = (maxBillaTimeAnim - minBillaTimeAnim) / questionData.length;
    let mxmScore = 120;
    let marksPerQuestion = mxmScore / questionData.length;

    let incorectBgGradient = "linear-gradient(#800e31b5, #f63b34b5)";

    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [gameEndType, setGameEndType] = useState();
    const [attemptData, setAttemptData] = useState('');
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [fSize, setFSize] = useState(1);
    const [incorrectData, setIncorrectData] = useState();
    const [activeQindex, setActiveQindex] = useState(0);
    const [billaAnimationTime, setBillaAnimationTime] = useState(maxBillaTimeAnim);
    const [totalGoals, setTotalGoals] = useState(0);
    const [isAudioPlaying, setIsAudioPlaying] = useState(true);
    const isCorrectRef = useRef(false);
    const [answersText, setAnswerText] = useState('Point towards a number to reveal option');
    const [answerNumber, setAnswerNumber] = useState(null);
    const [ballMoveAngle, setBallMoveAngle] = useState(0);
    const [isBillaVisible, setIsBillaVisible] = useState(false);

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const answersTextRef = useRef();
    const pointShootRef = useRef();
    const ballTmgRef = useRef();
    const billaRef = useRef();
    const goalPostImgRef = useRef();

    const intervalRef = useRef();
    const totalScoreRef = useRef();
    const progressBarRef = useRef();
    const questionRef = useRef([]);
    const qstnBoxRef = useRef(null);
    const lifeTextRef = useRef(null);
    const divLifeRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const timerRef = useRef();
    const gameContainerRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);
    const questionNoRef = useRef();
    const finalChallengeDataRef = useRef();
    const isOptionClickAllowedRef = useRef(true);
    const currentTimeRef = useRef(Date.now());

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);
    const mcqBoxRef = useRef();
    const trackPadRef = useRef();
    const ballRef = useRef();
    const aimBoxRef = useRef();
    const navigationRef = useRef();
    const [ballPos, setBallPos] = useState('0rem');
    const [tailHeight, setTailHeight] = useState(100);
    const [cardFocused, setCardFocused] = useState(null);
    const guideTextRef = useRef();
    const answerCardRef = useRef();
    const stadiumRef = useRef();
    const grassRef = useRef();
    const ballAndGoalKeeperCollisionRef = useRef();
    const isCollidedRef = useRef(false);
    const goalStatusRef = useRef();
    const billaMissedRef = useRef();
    const billaCaughtRef = useRef();
    const lifeLossAnimationRef = useRef();
    const billaMissedLottieRef = useRef();
    const billaCaughtLottieRef = useRef();
    const footbalAudioRef = useRef();
    // const billaSoundRef = useRef();
    const answerBallImgRef = useRef();
    const isDraggingActive = useRef(false);

    let [angle, setAngle] = useState(0);
    const bufferTime = 400;

    useEffect(() => {
        if (isEmpty(attemptData))
            getChallengeDataAsync();
        setFSize(window.innerHeight / 961);
    }, []);

    useEffect(() => {
        if (isGamePage) {
            setTimeout(() => setIsBillaVisible(true), 3000);
        }
    }, [isGamePage])

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    window.addEventListener("resize", resFont);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        if (isPauseOverlay) {
            if (!isEmpty(footbalAudioRef.current)) footbalAudioRef.current.pause();
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (!isEmpty(footbalAudioRef.current)) footbalAudioRef.current.play();
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    useEffect(() => {
        const billa = billaRef.current;
        const goalPostImg = goalPostImgRef.current;
        const handleImageLoad = () => {
            if (billaRef.current && goalPostImgRef.current) {
                // calculations here
                const goalPostHeight = goalPostImg.getBoundingClientRect().height;
                const billaHeight = billa.getBoundingClientRect().height;
                const goalPostPosition = `${goalPostHeight - billaHeight}px`;

                billa.style.top = goalPostPosition;
                billaCaughtRef.current.style.top = goalPostPosition;
                billaMissedRef.current.style.top = goalPostPosition;
            }
        };

        if (goalPostImgRef.current && goalPostImgRef.current.complete) {
            // Image already loaded
            handleImageLoad();
        } else if (goalPostImgRef.current) {
            // Wait for image to load
            goalPostImgRef.current.addEventListener('load', handleImageLoad);
        }

        return () => {
            if (goalPostImgRef.current) {
                goalPostImgRef.current.removeEventListener('load', handleImageLoad);
            }
        };
    }, [billaRef.current, goalPostImgRef.current, isGamePage, isBillaVisible])


    useEffect(() => {
        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE.GAME);
            clearInterval(totalGBATimespentIntervalRef.current);
            totalGBATimespentIntervalRef.current = setInterval(() => {
                totalGBATimespent.current += 1;
            }
                , 1000);
            setAddPause(true);
            totalScoreRef.current = 0;
            if (activeQindex === 0) {
                if (!isEmpty(questionRef.current)) questionRef.current.innerHTML = questionData[activeQindex].question;
            }
        }
    }, [isGamePage]);
    // <========= useEffect that will focus the card based on cardSelection =======>
    useEffect(() => {
        if (cardFocused && cardFocused !== "out-of-range") {
            let styling
            styling = `
                        // outline: 2px solid #F4511E;
                        border: 2px solid #F4511E;
                        color: #fff; 
                        box-shadow: 0px 21px 65px -15px rgba(0,0,0,0.75);
                        background: rgba(0,0,0,0.5);
                        font-size: 0.8rem;
                        z-index: 8;
                        text-align: center;
                        padding: 0.5rem;
                        transform: scale(1.4, 1.3); 
                        font-family: 'Montserrat';
                        font-weight: 500;
                    `
            let cardNum = parseInt(cardFocused.split("-")[1]);
            answerCardRef.current.forEach((element, index) => {
                const boxValue = parseInt(element.id.split('-')[1]);
                element.style = "";
                if (boxValue % 2 === 0) {
                    element.children[0].innerHTML = boxValue;
                } else {
                    element.children[2].innerHTML = boxValue;
                }

                if (boxValue === cardNum) {
                    element.style = styling
                    if (boxValue % 2 === 0) {
                        element.children[0].innerHTML = answersText;
                    } else {
                        element.children[2].innerHTML = answersText;
                    }
                }
            });

        } else {
            answerCardRef?.current?.forEach(element => {
                element.style = ""
            });
        }
    }, [cardFocused])


    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
        }
    }, []);

    const handleBackButtonClick = () => {
        handleTimerPause();

        isGamePausedRef.current = true;
        let currentTime = Date.now();

        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            timespent: totalGBATimespent.current,
        }));
    }

    const handleResumeClick = () => {
        setAddPause(true);
        handleTimerResume();
    }

    const handleTimerPause = () => {
        if (isEmpty(timerRef.current)) return
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
    }

    const handleTimerResume = () => {
        if (isEmpty(timerRef.current)) return
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            totalGBATimespent.current += 1;
        }, 1000);
        setAddPause(true);
    }

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])


    const handleNextQuestion = () => {
        if (parseInt(lifeRef.current.innerHTML) === 0) {
            handleEndOfGamesDueToLifeLoss();
            return
        } else if (activeQindex === questionData.length - 1) {
            handleEndOfQuestions();
            return
        }
        setBillaAnimationTime(maxBillaTimeAnim - ((activeQindex + 1) * speedIncreasePerQues));
        // answersTextRef.current.style.visibility = 'hidden';
        // ballTmgRef.current.style.display = "block";
        guideTextRef.current.style.visibility = "visible";
    }


    const handleEndOfGamesDueToLifeLoss = async () => {
        totalScoreRef.current = Math.ceil(totalScoreRef.current)
        handleGbaState(GBA_SCREEN_STATE.LOOSE);
        setGameEndType('loose');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        const submitEndChallengeAttemptData = await submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: totalScoreRef.current, completion: false, status: "LIFELOSE", timespent: totalGBATimespent.current })
        setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

    }

    const handleEndOfQuestions = () => {
        totalScoreRef.current = Math.ceil(totalScoreRef.current)
        handleGbaState(GBA_SCREEN_STATE.WIN);
        setGameEndType('win');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: totalScoreRef.current, completion: true, status: "COMPLETED", timespent: totalGBATimespent.current })
    }

    useEffect(() => {
        handleProgressBar();
        if (activeQindex === 0) return
        isOptionClickAllowedRef.current = true; // enable option click
    }, [activeQindex]);

    useEffect(() => {
        handleProgressBar();
    }, [progressBarRef.current]);

    const handleProgressBar = () => {
        let progressBarWidth = ((activeQindex + 0) / questionData.length) * 100;
        if (!isEmpty(progressBarRef.current)) {
            progressBarRef.current.style.width = progressBarWidth + "%";
        }
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;

        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            };
        } else {
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                questions: [...finalChallengeDataRef.current.questions, tempQuestionObj],
                timespent: totalTimeSpent,
            };
        }
    };

    const restartGame = async () => {
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        clearInterval(ballAndGoalKeeperCollisionRef.current)
        setGameEndType();
        setActiveQindex(0);
        totalScoreRef.current = 0;
        totalGBATimespent.current = 0;
        setIsGameComplete(false);
        getChallengeDataAsync();
        isOptionClickAllowedRef.current = true;
        setBillaAnimationTime(maxBillaTimeAnim);
        setTotalGoals(0);
        handleGbaState(GBA_SCREEN_STATE.GAME);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            totalGBATimespent.current += 1;
        }, 1000);
        setAddPause(true);
        setIsAudioPlaying(true);
    }
    //
    const handleLifeLoss = () => {
        let lifeValue = parseInt(lifeRef.current.innerHTML) - 1;
        lifeRef.current.innerHTML = lifeValue;
        lifeLossAnimationRef.current.classList.add(`${classes.blinkRed}`);
        setTimeout(() => {
            if (lifeLossAnimationRef.current) {
                lifeLossAnimationRef.current.classList.remove(`${classes.blinkRed}`);
            }
        }, [2000]);
        if (lifeValue === 1) {
            // make the life red
            lifeTextRef.current.style.color = '#FF5757';
            heartRef.current.src = redHeartWatchImgPath;
            lifeTextRef.current.classList.add(`${classes.blinkAnimationEffect}`);
        }
        return lifeValue;
    }
    // <========= checkAnswer, that will check the hovered answer after shoot and make it red or green =======>
    async function checkAnswer(element, boxValue) {
        const correctIndex = questionData[activeQindex].answers.findIndex((item, index) => item.isCorrect)
        goalStatusRef.current.style.display = "block";
        // <=== makes card or red on behalf of answer ===>
        if (!isCollidedRef.current) {
            setTotalGoals(prev => prev + 1);
            goalStatusRef.current.innerHTML = "GOAL!";
            const billaLeftPosition = billaRef.current.getBoundingClientRect().left;
            const billaWidth = billaRef.current.getBoundingClientRect().width;
            // BillaRef
            billaRef.current.style.display = "none";
            billaRef.current.classList.remove(`${classes.billaAnimation}`);
            const emptyArea_X = (window.innerWidth - trackPadRef.current?.clientWidth) / 2;
            // billaMissed
            billaMissedRef.current.style.left = `${billaLeftPosition - emptyArea_X + (billaWidth / 2)}px`;
            billaMissedRef.current.style.display = "block";
            billaMissedLottieRef.current.play();
            element.classList.remove(`${classes.centerFlex}`);
            element.classList.add(`${classes.cardGridAdd}`);
            const boxValue = parseInt(element.id.split('-')[1]);
            const boxEven = boxValue % 2;
            element.children[boxEven === 1 ? 0 : 1].style.display = "block";
            await sleep(bufferTime);
            element.children[boxEven === 1 ? 0 : 1].style.display = "none";
            element.children[boxEven === 1 ? 1 : 2].style.display = "grid";
            await sleep(bufferTime);
            element.children[boxEven === 1 ? 1 : 2].style.display = "none";
            element.children[boxEven === 1 ? 2 : 0].style.display = "none";
            element.classList.add(`${classes.centerFlex}`);
            element.classList.remove(`${classes.cardGridAdd}`);
        } else {
            goalStatusRef.current.innerHTML = "CAUGHT!";
        }
        let styling = `
        transform: scale(1.4, 1.3); 
        // outline: none;
        border: none;
        z-index: 8;
    `
        if (correctIndex + 1 === boxValue) {
            if (isCollidedRef.current) {
                element.style = "";
                handleLifeLoss();
            } else {
                element.style = styling
                totalScoreRef.current += marksPerQuestion;
                // const boxEven = boxValue % 2;
                element.children[3].src = "/images/gba/reveal-the-picture/correct-ans.svg";
                element.children[3].style.display = "block";
                // element.children[boxEven === 1 ? 2 : 0].innerHTML = "CORRECT!";
                element.style.backgroundColor = palette.ilaCorrect1;
            }
            // updateCardResposne("CORRECT" + "!", 0.6, 0);
        } else {
            handleLifeLoss();
            if (!isCollidedRef.current) {
                element.style = styling;
                // const boxEven = boxValue % 2;
                element.children[3].src = "/images/gba/reveal-the-picture/wrong.svg";
                element.children[3].style.display = "block";
                // element.children[boxEven === 1 ? 2 : 0].innerHTML = "WRONG!"
                element.style.backgroundColor = palette.ilaInCorrect1;
            } else {
                element.style = "";
            }
            // updateCardResposne("WRONG" + "!", 0.6, 0);
        }
    }

    // function updateCardResposne(response, font_size, padding_top) {
    //     // response will be the text, font_size for answer text and padding_top for answer card
    //     let cardNum = parseInt(cardFocused.split("-")[1])
    //     answerCardRef.current.forEach((element, index) => {
    //         const boxValue = parseInt(element.id.split('-')[1]);
    //         // I will make the changes here
    //         // if (boxValue === cardNum) {
    //         //     element.firstElementChild.innerHTML = response
    //         //     element.lastElementChild.style = `
    //         //         font-size: ${font_size}rem; 
    //         //         padding-top: ${padding_top}rem;
    //         //     `
    //         // }
    //     });
    // }

    //check ball and goalkeeper collision
    function ballAndGoalKeeperCollision() {
        const ballBounding = ballRef.current.getBoundingClientRect();
        const billaBounding = billaRef.current.getBoundingClientRect();
        const isColliding = (
            ballBounding.left < billaBounding.right &&
            ballBounding.right > billaBounding.left &&
            ballBounding.top < billaBounding.bottom &&
            ballBounding.bottom > billaBounding.top
        );
        if (isColliding) {
            ballRef.current.style.visibility = "hidden";
            isCollidedRef.current = true;
            const billaLeftPosition = billaRef.current.getBoundingClientRect().left;
            const billaWidth = billaRef.current.getBoundingClientRect().width;
            // BillaRef
            billaRef.current.style.display = "none";
            billaRef.current.classList.remove(`${classes.billaAnimation}`);
            const emptyArea_X = (window.innerWidth - trackPadRef.current?.clientWidth) / 2;
            // billaMissed
            billaCaughtRef.current.style.left = `${billaLeftPosition - emptyArea_X + (billaWidth / 2)}px`;
            billaCaughtRef.current.style.display = "block";
            billaCaughtLottieRef.current.play();
            // Clear the collision interval
            clearInterval(ballAndGoalKeeperCollisionRef.current);
        }
    }

    // <========= checkAnswer ends here =======>
    function dragStart(e) {
        e.preventDefault();

        if (e.type === "touchstart") {
            trackPadRef.current?.addEventListener('touchmove', dragging, false);
        } else {
            trackPadRef.current?.addEventListener('mousemove', dragging, false);
        }
    }

    const dragging = useCallback((e) => {
        isDraggingActive.current = true;

        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const controller_width = trackPadRef.current?.clientWidth;
        const controller_height = trackPadRef.current?.clientHeight;
        let client_x, client_y;


        if (e.type === "touchmove") {

            client_x = e.touches[0].clientX;
            client_y = e.touches[0].clientY - (screenHeight - controller_height);
        } else {
            client_x = e.clientX - ((screenWidth - controller_width) / 2);
            client_y = e.clientY - (screenHeight - controller_height);
        }

        const center_x = controller_width / 2;
        const center_y = controller_height;

        const angle = Math.atan2(client_y - center_y, client_x - center_x) * (180 / Math.PI);
        const accurateAngle = isMobile ? angle + 90 : angle + 90;

        answerCardRef.current = document.querySelectorAll('.answerCard');
        const card_width = answerCardRef.current[0].clientWidth + 16;

        const maxAngle = Math.atan2(card_width, controller_height) * (180 / Math.PI);
        setTranslate(accurateAngle, controller_height, card_width, maxAngle);
    }, [activeQindex]);

    // <========= dragEnd, that will trigger on mouseup and touchend =======>
    async function dragEnd(e) {
        e.preventDefault();
        if (!isDraggingActive.current) return;


        isDraggingActive.current = false
        if (isEmpty(cardFocused)) {
            return
        }

        // if (!isEmpty(billaSoundRef.current)) {
        //     footbalAudioRef.current.pause();
        //     billaSoundRef.current.play();
        // }

        if (e.type === "touchstart") {
            guideTextRef.current.style.visibility = "hidden";
            trackPadRef.current?.removeEventListener("touchmove", dragging, false)

        } else {
            // guideTextRef.current.style.display = "none";
            guideTextRef.current.style.visibility = "hidden";
            trackPadRef.current?.removeEventListener("mousemove", dragging, false)
        }

        shootTheBall();
    }
    // <========= dragEnd ends here =======>

    // <========= setTranslate, that rotates the arrow icon and sets the height of arrow tail  =======>
    function setTranslate(accurateAngle, controller_height, card_width, maxAngle) {

        //rotate navigator on hover
        // limiting rotation of navigator
        if (accurateAngle < -1 * maxAngle || accurateAngle > maxAngle) {
            return;
        } else {
            setAngle(accurateAngle);
        }
        // get horizontal distance from top center of controller  
        // let secValue = 1 / Math.cos(accurateAngle * Math.PI / 180);
        // let distance = secValue * controller_height;

        // if (answersTextRef.current?.style.display === "none") {
        // pointShootRef.current.style.display = "none";
        // answersTextRef.current.style.display = "grid";
        // }
        focusTheCard(accurateAngle, maxAngle, controller_height);
    }
    // <========= setTranslate ends here =======>

    // <====== focusTheCard, that set current focused card by determining distance from center to card to card ======>
    function focusTheCard(accurateAngle, maxAngle, controller_height) {
        const card_height = answerCardRef.current[0].clientHeight;
        let secValue;
        let distance;
        // answersTextRef.current.style.gridTemplateColumns = '1fr 4fr';
        // answerBallImgRef.current.style.display = 'flex';
        if (accurateAngle > -maxAngle && accurateAngle < (-maxAngle / 2)) {
            secValue = 1 / Math.cos(((3 * maxAngle) / 4) * Math.PI / 180);
            distance = secValue * controller_height;
            setTailHeight(distance);
            setBallMoveAngle((-3 * maxAngle) / 4)
            setCardFocused('card-1');
            setAnswerNumber(1);
            setAnswerText(questionData[activeQindex].answers[0].answer);
            // answersTextRef.current.style.visibility = "visible";
        } else if (accurateAngle > (-maxAngle / 2) && accurateAngle < 0) {
            secValue = 1 / Math.cos((maxAngle / 4) * Math.PI / 180);
            distance = secValue * controller_height;
            setTailHeight((distance + card_height));
            setBallMoveAngle(-maxAngle / 4)
            setCardFocused('card-2');
            setAnswerNumber(2);
            setAnswerText(questionData[activeQindex].answers[1].answer);
            // answersTextRef.current.style.visibility = "visible";
        } else if (accurateAngle > 0 && accurateAngle < (maxAngle / 2)) {
            secValue = 1 / Math.cos((maxAngle / 4) * Math.PI / 180);
            distance = secValue * controller_height;
            setTailHeight((distance + card_height));
            setBallMoveAngle(maxAngle / 4)
            setCardFocused('card-3');
            setAnswerNumber(3);
            setAnswerText(questionData[activeQindex].answers[2].answer);
            // answersTextRef.current.style.visibility = "visible";
        } else if (accurateAngle > (maxAngle / 2) && accurateAngle < maxAngle) {
            secValue = 1 / Math.cos(((3 * maxAngle) / 4) * Math.PI / 180);
            distance = secValue * controller_height;
            setTailHeight(distance);
            setBallMoveAngle((3 * maxAngle) / 4)
            setCardFocused('card-4');
            setAnswerNumber(4);
            setAnswerText(questionData[activeQindex].answers[3].answer);
            // answersTextRef.current.style.visibility = "visible";
        } else {
            setCardFocused('out-of-range')
        }

    }

    // <========= shootTheBall, this is to shoot the ball, after mouseup and touchend =======>
    const shootTheBall = async () => {
        if (cardFocused && cardFocused !== "out-of-range") {
            ballRef.current.style.visibility = "visible";
            ballAndGoalKeeperCollisionRef.current = setInterval(() => ballAndGoalKeeperCollision(), 100);
            setBallPos('85%');

            let cardNum = parseInt(cardFocused.split("-")[1])
            let styling

            styling = `
                transform: scale(1.4, 1.3); 
                // outline: 2px solid #F4511E;
                border: 2px solid #F4511E;
                color: #F4511E; 
                box-shadow: 0px 21px 65px -15px rgba(0,0,0,0.75);
                background: rgba(0,0,0,0.5);
                font-size: 1rem;
                // font-size: 2rem;
            `

            // if the ball shooted, trackpad will not listen any events untill next question
            trackPadRef.current.style = `pointer-events: none;`;


            // await sleep(bufferTime);
            await sleep(bufferTime);
            ballRef.current.style.display = "none";
            answerCardRef.current.forEach((element, index) => {
                const boxValue = parseInt(element.id.split('-')[1]);
                element.style = ""
                if (boxValue % 2 === 0) {
                    element.children[0].innerHTML = boxValue
                } else {
                    element.children[2].innerHTML = boxValue
                }
                if (boxValue === cardNum) {
                    if (!isCollidedRef.current) {
                        element.style = styling
                    }
                    checkAnswer(element, boxValue);
                    handleOptionShooted(element, boxValue);
                }
            });
        }
    }


    // <===== reset all things after a play ======>
    function reset(element, boxValue) {
        const boxEven = boxValue % 2;
        // element.children[boxEven === 0 ? 0 : 2].innerHTML = boxValue;
        element.children[3].style.display = 'none';
        element.children[boxEven === 1 ? 2 : 0].style.display = "block";
        goalStatusRef.current.style.display = "none";
        goalStatusRef.current.innerHTML = ""
        if (!isEmpty(ballRef.current)) {
            ballRef.current.style.display = 'block';
            ballRef.current.style.visibility = 'hidden';
        }
        if (!isEmpty(aimBoxRef.current)) aimBoxRef.current.style.visibility = "visible";
        clearInterval(ballAndGoalKeeperCollisionRef.current);
        billaMissedRef.current.style.display = "none";
        billaCaughtRef.current.style.display = "none";
        billaCaughtLottieRef.current.stop();
        billaMissedLottieRef.current.stop();
        billaRef.current.style.display = "block";
        billaRef.current.style.left = "50%";
        billaRef.current.classList.add(`${classes.billaAnimation}`);
        isCollidedRef.current = false;
        if (!isEmpty(footbalAudioRef.current)) {
            footbalAudioRef.current.play();
        }
        // if (!isEmpty(billaSoundRef.current)) {
        //     billaSoundRef.current.src = '/audio/football/football-kick.mp3';
        // }
        // answersTextRef.current.style.gridTemplateColumns = '1fr';
        // answerBallImgRef.current.style.display = 'none';
        setAnswerText('Point towards a number to reveal option');

        setBallPos('0rem');
        setAngle('0')
        setTailHeight(100);
        setCardFocused(null)
        answerCardRef.current.forEach(elem => {
            elem.style = ""
        })
        // updateCardResposne("", 0.8, 0)
        trackPadRef.current.style = `pointer-events: all;`
    }
    // <===== reset ends here ======>

    const handleOptionShooted = async (element, boxValue) => {
        let item = questionData[activeQindex].answers[parseInt(cardFocused.split("-")[1]) - 1]

        // // handle score logic
        let currentTime = Date.now();
        if (item.isCorrect) {
            isCorrectRef.current = true;
            handleChallengeAttemptData({
                qId: questionData[activeQindex].id,
                aId: item.id,
                isCorrect: true,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                contextId: questionData[activeQindex].contextId,
                skillId: questionData[activeQindex].skillId,
            });
        } else {
            isCorrectRef.current = false;
            handleChallengeAttemptData({
                qId: questionData[activeQindex].id,
                aId: item.id,
                isCorrect: false,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                skillId: questionData[activeQindex].skillId,
                contextId: questionData[activeQindex].contextId,
            });
        }
        currentTimeRef.current = currentTime;


        await sleep(4 * bufferTime);
        aimBoxRef.current.style.visibility = "hidden";
        await sleep(3 * bufferTime);

        if (questionData.length - 1 >= activeQindex) {
            if (questionData[activeQindex].mandatory && !item.isCorrect) {
                // await sleep(bufferTime * 5)
                reset(element, boxValue)
            } // INCORRECT 
            else {
                // await sleep(bufferTime * 5)
                setActiveQindex(prev => prev + 1)
                reset(element, boxValue)
            }; // CORRECT
            handleNextQuestion();
        } else {
            // await sleep(bufferTime * 5)
            if (questionData[activeQindex].mandatory && !item.isCorrect) {
                return reset(element, boxValue)
            }
        }
    }
    const handleAudioLoad = (e) => {
        footbalAudioRef.current.play();
    }

    const handleAudioMutelick = () => {
        setIsAudioPlaying(prev => {
            if (prev && !isEmpty(footbalAudioRef.current)) {
                footbalAudioRef.current.muted = true;
                // billaSoundRef.current.muted = true;
            }
            else {
                footbalAudioRef.current.muted = false;
                // billaSoundRef.current.muted = false;
            }
            return !prev
        })

    }

    const disableContext = (e) => {
        e.preventDefault()
    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { fontSize: `${fSize}rem` } : { backgroundImage: 'linear-gradient(#000, #000)' }}

            className={classes.cricketRoot} key={componentKey}>
            {
                !isGamePage ? (
                    // !isHowToPlayScreen ? (
                    <>
                        <StartScreen
                            setIsGamePage={setIsGamePage}
                            setIsHowToPlayScreen={setIsHowToPlayScreen}
                            // gradientBg={bgGradientDefault}
                            bg={"/images/gba/football/bg-title.jpg"}
                            logoWidth={'80%'}
                            // gameLogo={"/images/gba/master_blaster_logo.png"}
                            gameLogo={"/images/gba/football/goal-rush.png"}
                            handleGbaState={handleGbaState}

                            startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                            setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                            startScreenGameBgLoaded={startScreenGameBgLoaded}
                            setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                            gameBranding={"/images/gba/football/vs.png"}
                        />
                    </>
                    // ) : (
                    //     <HowToPlay
                    //         title={gameName}
                    //         headerBg={headerBg}
                    //         qstnBoxBg={'#000000'}
                    //         asset1Img={'/spaceRocks/rock.png'}
                    //         asset2Img={'/spaceRocks/rock.png'}
                    //         headerColor='#a34735'
                    //         setIsGamePage={setIsGamePage}
                    //         gameType='cricket'
                    //     />
                    // )
                ) : (
                    !isGameComplete && isEmpty(gameEndType) ? (
                        <>
                            <div ref={lifeLossAnimationRef} className={`${classes.resultStatusContainer}`}></div>
                            <div className={`${classes.gameContainer}`} ref={gameContainerRef}>
                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} style={{ width: 0 }} />
                                </div>
                                {/* QUESTION BOX */}
                                <div className={`${classes.qstnContainer}`}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeTextRef}>
                                                <div className={classes.life} ref={divLifeRef}><span ref={lifeRef}>{maxLife}</span> x </div>
                                                <img src={heartImgPath} className={`disableSelection disablePointerEvents ${classes.icon}`} ref={heartRef} alt="heart" />
                                            </div>
                                            <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeQindex + 1}</span>/{questionData.length}</div>
                                            <div className={`disableSelection ${classes.muteSwitch}`} onClick={handleAudioMutelick}>
                                                {isAudioPlaying ? (
                                                    <img src="/images/gba/learno_casino_sound.png" alt="icon" width={30} className={`disableSelection disablePointerEvents`} />
                                                ) : (
                                                    <img src="/images/gba/learno_casino_mute.png" alt="icon" width={30} className={`disableSelection disablePointerEvents`} />
                                                )}
                                            </div>
                                        </div>
                                        <div className={classes.qstn} ref={questionRef}>
                                            {questionData[activeQindex]?.question}
                                        </div>
                                    </div>
                                </div>
                                {/* video Player */}
                                <div style={{ width: '100%', position: 'relative' }} ref={stadiumRef}>
                                    <video
                                        src="/images/gba/football/soccerbg.mp4"
                                        playsInline
                                        autoPlay={true}
                                        controls={false}
                                        loop
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                    {/* QUESTION BOX */}
                                    {/* <div ref={answersTextRef} className={classes.answerText}>
                                        <div className={classes.answerBallImg} ref={answerBallImgRef}>{answerNumber}</div><div style={{ width: "100%" }}>{answersText}</div></div> */}
                                    {/* Point to shoot */}
                                    {/* <div className={classes.pointShootText} ref={pointShootRef}>Point & Shoot</div> */}
                                    {/* audio tag for crowd sound */}
                                    <audio
                                        ref={footbalAudioRef}
                                        src="/audio/football/football-crowd-large.mp3"
                                        preload="metadata"
                                        onLoadedMetadata={handleAudioLoad}
                                        onEnded={(e) => {
                                            e.target.play();
                                        }}
                                    ></audio>
                                    {/* audio tag for kick and billa sound */}
                                    {/* <audio
                                        ref={billaSoundRef}
                                        src="/audio/football/football-kick.mp3"
                                        preload="metadata"
                                    ></audio> */}
                                </div>


                                <div className={classes.grassContainer} ref={grassRef}>
                                    <img src="/images/gba/football/grass.jpg" className={`disableSelection disablePointerEvents`} style={{ width: '100%', height: '100%' }} alt="" />

                                    <div className={classes.mcqRootContainer}>
                                        <img src="/images/gba/football/goal-post.svg" ref={goalPostImgRef} className={` disableSelection disablePointerEvents ${classes.goalPostImg}`} alt={'goalPost'} />
                                        <div className={classes.mcqBox} ref={mcqBoxRef}>
                                            <div className={`answerCard ${classes.cardBox} card-2 ${classes.centerFlex}`} id='card-2'>
                                                <div style={{ justifySelf: 'end' }}>2</div>
                                                <img src="/images/gba/football/ball.svg" alt="football" className={`disableSelection disablePointerEvents ${classes.cardFootballImg} `} />
                                                <div className={classes.cardScore}>+{totalGoals}</div>
                                                <img className={`disableSelection disablePointerEvents ${classes.answerStatus}`} alt="answer status" />
                                            </div>
                                            <div className={`answerCard ${classes.cardBox} card-3 ${classes.centerFlex}`} id='card-3'>
                                                <img src="/images/gba/football/ball.svg" alt="football" className={`disableSelection disablePointerEvents ${classes.cardFootballImg}`} />
                                                <div className={classes.cardScore}>+{totalGoals}</div>
                                                <div style={{ justifySelf: 'start' }}>3</div>
                                                <img className={`disableSelection disablePointerEvents ${classes.answerStatus}`} alt="answer status" />
                                            </div>
                                            <div className={`answerCard ${classes.cardBox} card-1 ${classes.centerFlex}`} id='card-1'>
                                                <img src="/images/gba/football/ball.svg" alt="football" className={`disableSelection disablePointerEvents ${classes.cardFootballImg}`} />
                                                <div className={classes.cardScore}>+{totalGoals}</div>
                                                <div style={{ justifySelf: 'start' }}>1</div>
                                                <img className={`disableSelection disablePointerEvents ${classes.answerStatus}`} alt="answer status" />
                                            </div>
                                            <div className={`answerCard ${classes.cardBox} card-4 ${classes.centerFlex}`} id='card-4'>
                                                <div style={{ justifySelf: 'end' }}>4</div>
                                                <img src="/images/gba/football/ball.svg" alt="football" className={`disableSelection disablePointerEvents ${classes.cardFootballImg}`} />
                                                <div className={classes.cardScore}>+{totalGoals}</div>
                                                <img className={`disableSelection disablePointerEvents ${classes.answerStatus}`} alt="answer status" />
                                            </div>
                                        </div>
                                        {/* Billa */}
                                        <div className={classes.billaOpacityAnimate}>
                                            {isBillaVisible && <div ref={billaRef} className={`${classes.billa} ${classes.billaAnimation}`}
                                                style={{
                                                    '--animTime': `${billaAnimationTime}s`,
                                                }}
                                            >
                                                <Lottie
                                                    speed={2}
                                                    play={true}
                                                    loop={true}
                                                    animationData={BillaMotionLottie}
                                                    style={{ width: '100%', height: '100%' }}
                                                // rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                                                />
                                            </div>}
                                        </div>
                                        {/* Rota Billa lottie */}
                                        <div ref={billaMissedRef} className={`${classes.billaMissed}`}>
                                            <Lottie
                                                speed={1}
                                                loop={false}
                                                ref={billaMissedLottieRef}
                                                animationData={RotaBillaLottie}
                                                style={{ width: '100%', height: '100%' }}
                                            />

                                        </div>
                                        {/* hasta Billa collide lottie */}
                                        <div ref={billaCaughtRef} className={`${classes.billaCaught}`}>
                                            <Lottie
                                                speed={1}
                                                loop={false}
                                                ref={billaCaughtLottieRef}
                                                animationData={HastaBillaLottie}
                                                style={{ width: '100%', height: '100%' }}
                                            />
                                        </div>

                                        <div
                                            className={`disableSelection ${classes.aimContainer} ${classes.centerFlex}`}
                                            ref={trackPadRef}
                                            onMouseDown={dragStart}
                                            onMouseUp={dragEnd}
                                            onTouchStart={dragStart}
                                            onTouchEnd={dragEnd}
                                        >
                                            <div className={classes.aimBox} ref={aimBoxRef}>
                                                <div className={classes.navigation}
                                                    ref={navigationRef}
                                                    style={{
                                                        '--angle': `${ballMoveAngle}deg`,
                                                        '--height': `${tailHeight}px`
                                                    }}
                                                >
                                                    <div
                                                        className={`${classes.ball}`}
                                                        ref={ballRef}
                                                        style={{
                                                            visibility: 'hidden',
                                                            '--top': ballPos,
                                                            '--transisnTime': `${bufferTime}ms`,
                                                            pointerEvents: 'none',
                                                            userSelect: 'none',


                                                        }}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    ></div>
                                                </div>
                                                {/* <p className={`disableSelection ${classes.ballImg}`} ref={ballTmgRef} style={{ pointerEvents: 'none' }} onContextMenu={(e) => e.preventDefault()}
                                                >
                                                    <img src="/images/gba/football/ball.svg" alt="football" style={{ width: '4rem', height: '4rem', pointerEvents: 'none', userSelect: 'none' }} className={classes.disable} onContextMenu={(e) => e.preventDefault()}
                                                        draggable="false" />
                                                </p> */}

                                                <p className={`disableSelection ${classes.guideText}`} ref={guideTextRef} onContextMenu={disableContext} style={{ '--angle': `${angle}deg` }} >
                                                    <img src="/images/gba/football/ball.svg" alt="football" style={{ width: '4rem', height: '4rem' }} draggable="false" className={`disableSelection disablePointerEvents`} />
                                                </p>

                                            </div>
                                            <p className={classes.goalStatus} ref={goalStatusRef}></p>
                                            <div className={`${classes.pointShootText} ${classes.fadeInfintite}`} ref={pointShootRef}>Point & Shoot</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={120}
                                        bg={""}
                                        obtainedScore={totalScoreRef.current}
                                        totalGoals={totalGoals}
                                        gbaDesignType={'MCQ_Football'}
                                    />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        incorrectAttempt={incorrectData}
                                        bg={""}
                                        bgGradient={incorectBgGradient}
                                        restartGame={restartGame}
                                    />

                                )
                            }
                        </>
                    )
                )
            }
        </div>
    )
}

export default Football;

