import React from "react";

// Components
import AnswersContainer from "../form-answers-container";

// Styles
import useStyles from "./index.styles";

// utils
import isEmpty from "../../../utils/isEmpty";

// API's
import { submitFormAttemptData } from "../../../redux/form/form.api";

const FormContentContainer = (props) => {
    const { formData, attemptLevelData, handleSetAttemptLevelData, handleErrorAttemptDataSubmit } = props;

    const handleQuestionAttemptData = async (questionAttemptData) => {
        const body = {
            attemptId: formData.attemptId,
            formId: formData.formId,
            questions: questionAttemptData.questions
        };

        const resp = await submitFormAttemptData(body);
        if (resp.status !== 200 && resp.status !== 201) {
            handleErrorAttemptDataSubmit();
        }
    };

    const classes = useStyles();

    return (
        <div className={`${classes.mainContentContainer}`}>
            {formData.contexts.map((data, index) => {
                return (
                    <div key={index} className={`${classes.contextQuestionContainer}`}>
                        <div className={`${classes.contextTitle}`}>
                            {isEmpty(data.contextTitle) ? "" : `Section-${index + 1} ${data.contextTitle}`}
                        </div>
                        {data.questions.map((question, index) => (
                            <QuestionUI
                                key={`question-${question.id}`}
                                question={question}
                                index={index + 1}
                                attemptLevelData={attemptLevelData}
                                handleSetAttemptLevelData={handleSetAttemptLevelData}
                                handleQuestionAttemptData={handleQuestionAttemptData}
                            />
                        ))}
                    </div>
                )
            })}
        </div>
    )
}

export default FormContentContainer;

const QuestionUI = ({ question: ques, index, attemptLevelData, handleSetAttemptLevelData, handleQuestionAttemptData }) => {
    const questionId = ques.id;
    const questionType = ques.type;
    const answers = ques.answers;
    const options = ques.options;

    const classes = useStyles();

    return (
        <div key={`question-${questionId}`} className={`${classes.questionContainer}`}>
            <div className={`${classes.questionTitle}`}>
                {`Q-${index} ${ques.question} ${ques.mandatory ? "*" : ""}`}
            </div>

            <AnswersContainer
                questionId={questionId}
                questionType={questionType}
                answers={answers}
                options={options}
                handleQuestionAttemptData={handleQuestionAttemptData}
                attemptLevelData={attemptLevelData}
                handleSetAttemptLevelData={handleSetAttemptLevelData}
            />
        </div>
    );
}