import React, { useState, useEffect, useRef } from 'react';
import Input from '@mui/material/Input'; // Make sure to import OutlinedInput
import useStyles from './index.styles';
import theme from '../../theme';

const OtpInput = (props) => {
    const { otpLength = 6, setOtp } = props;
    const classes = useStyles();

    // States
    const [otpValues, setOtpValues] = useState(Array(otpLength).fill(''));
    const firstInputRef = useRef(null); // Create a ref for the first input

    useEffect(() => {
        setOtp(otpValues.join(''));
    }, [otpValues, setOtp]);

    // Focus on the first input when the component mounts
    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Handle case where a full OTP is entered
        if (value.length === otpLength) {
            const newOtpValues = value.split('').slice(0, otpLength);
            setOtpValues(newOtpValues);
            document.getElementById(`otp-input-${otpLength - 1}`).focus(); // Focus on the last input
            return;
        }

        // Allow only a single digit from 0 to 9 or an empty string
        if (value.match(/^[0-9]$/) || value === '') {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            // Focus next input if value is filled
            if (value && index < otpLength - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otpValues[index]) {
            // If the current input is empty, focus the previous input
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        } else if (e.key === "Delete") {
            // Clear current input if Delete key is pressed
            const newOtpValues = [...otpValues];
            newOtpValues[index] = '';
            setOtpValues(newOtpValues);
        }
    };

    return (
        <div className={classes.otpContainer}>
            {Array.from({ length: otpLength }, (_, index) => (
                <Input
                    key={index}
                    id={`otp-input-${index}`}
                    inputRef={index === 0 ? firstInputRef : null} // Attach ref to the first input
                    type="tel" // Use "tel" to show the numeric keyboard
                    maxLength="1"
                    disableUnderline={true}
                    sx={{
                        fontSize: '1.5rem',
                        textAlign: 'center',
                        margin: '0',
                        aspectRatio: '1/1',
                        height: '100%',
                        [theme.breakpoints.up("sm")]: {
                            border: '1px solid #f4511e'
                        },
                    }}
                    value={otpValues[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    autoComplete="off"
                    placeholder=""
                    margin="normal" // Adjust margins if necessary
                />
            ))}
        </div>
    );
}

export default OtpInput;