import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// mui
import { CircularProgress } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// components
import Scenario from "../../components/spotlight/scenario";
import RecordVideo2 from "../../components/spotlight/record-video-2";
// import RecordVideoFaceDetection from "../../components/spotlight/record-video-face-detection";
import PreviewVideo from "../../components/spotlight/preview";
import SampleRecording from "../../components/spotlight/sample-recording";
import SpotlightInstructions from "../../components/spotlight/spotlight-instructions";
import DialogAlert from "../../components/dialog-alert";
// import FaceDetectionModel from "../../components/spotlight/face-detection";

// redux
import { openDialogue } from "../../redux/common/common-slice";
import { getChallengeData, startChallengeAttempt } from "../../redux/microskill/microskill.api";
import { saveSpotlightVideo } from "../../redux/spotlight/spotlight.api";
import { handleSpotlightScreenState } from "../../redux/spotlight/spotlight-slice";
import { submitEndChallengeAttempt } from "../../redux/gba/gba.api";

// utils
import isEmpty from "../../utils/isEmpty";
import eventBus from "../../utils/eventBus";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";

// axios
import axiosInstance from "../../utils/axios";

// i18next
import { useTranslation } from "react-i18next";


const SCREEN_STATE = constants.SPOTLIGHT_SCREEN_STATE;
const HEIGHT_MARGIN = 4;
const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;

const Spotlight = (props) => {
    // const { isMobile } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles(isMobile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { state } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');
    const authState = useSelector(state => state.auth);
    const spotlightScreenState = useSelector(state => state?.spotlight.screenState);
    const { microskillId, challengeId } = useParams();
    const [videoFile, setVideoFile] = useState();
    const [videoBlob, setVideoBlob] = useState();
    const [audioFile, setAudioFile] = useState();
    const [audioBlob, setAudioBlob] = useState();
    const [duration, setDuration] = useState();
    const [challengeData, setChallengeData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [videoUploadProgress, setVideoUploadProgress] = useState(0);
    const [isStartrecording, setIsStartrecording] = useState(false);
    const [viewSampleRecording, setViewSampleRecording] = useState(false);
    const [viewTranscript, setViewTranscript] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('Unsaved Data');
    const [dialogueConfirmBtnName, setDialogueConfirmBtnName] = useState('Yes');
    const [isOverlay, setIsOverlay] = useState(false);
    const [isDemoVideoPlaying, setIsDemoVideoPlaying] = useState(false);
    const [totalAttempts, setTotalAttempts] = useState(0);
    const [maxAttempts, setMaxAttempts] = useState(0);
    const [isEmptySpeech, setIsEmptySpeech] = useState(false);
    const [fSize, setFSize] = useState(1);
    const [spotlightAnalytics, setSpotlightAnalytics] = useState({});
    const [attemptId, setAttemptId] = useState();

    const showAnalyzeOverlay = false;
    const [isDualChoice, setIsDualChoice] = useState(false);
    const [discardBtnName, setDiscardBtnName] = useState('No');

    const { t } = useTranslation()

    const rectBound = useRef()
    const cameraStreamRef = useRef();
    const [showDetectionScreen, setShowDetectionScreen] = useState(false)
    const [isCameraFeed, setIsCameraFeed] = useState(false);

    // const sleep = time => new Promise(resolve => setTimeout(resolve, time));

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    useEffect(() => {
        /**
            * This effect is used to handle the spotlight screen state
            * It will set the screen state to 3 when the user is returning back from result page
            * unless it will set the screen state to 0
         */

        if (isEmpty(state)) return
        if (isEmpty(state.spotlightScreenState)) return
        console.log("state.spotlightScreenState:", state.spotlightScreenState);
        // dispatch(handleSpotlightScreenState(state.spotlightScreenState));

        window.addEventListener('beforeunload', () => {
            window.history.replaceState({}, '');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const getChallengeDataAsync = async () => {
        let respData = await getChallengeData(microskillId, challengeId);
        if (respData.status === 200 || respData.status === 201) {
            if (!isEmpty(respData?.data?.data?.gameData?.SpotLight)) {
                if (isEmpty(respData?.data?.data?.gameData?.SpotLight?.mediaPath)) {
                    // respData.data.data.gameData.SpotLight.mediaPath = '/sample-documents/sample-video-1.mp4'; // this sample media is deleted
                }
            }
            else {
                setIsDialogOpen(true)
                setDialogueConfirmBtnName('Okay');
                setDialogContent("No data found.");
            }
            const totalAttempts = respData?.data?.data?.userMicroskillChallenge.totalAttempts;
            const maxAttempts = respData?.data?.data?.gameData.SpotLight.maxAttempts;
            setTotalAttempts(totalAttempts);
            setChallengeData({ ...respData?.data?.data?.gameData.SpotLight });
            setMaxAttempts(maxAttempts);

            if (state?.spotlightScreenState === SCREEN_STATE.RECORD) {
                if (totalAttempts >= maxAttempts) {
                    dispatch(handleSpotlightScreenState(SCREEN_STATE.SCENARIO));
                    setIsDialogOpen(true);
                    setDialogContent(t("You have reached the maximum number of attempts for this scenario."));
                    setDialogueConfirmBtnName('Okay');
                    setDiscardBtnName('');


                } else {
                    dispatch(handleSpotlightScreenState(state.spotlightScreenState));
                }
            }
        }
    };

    useEffect(() => {
        if (isEmpty(challengeData)) return
        setIsStartrecording(!challengeData.isTranscript);
        setViewSampleRecording(!isEmpty(challengeData.mediaPath));
        setViewTranscript(challengeData.isTranscript);
        // setIsStartrecording(!challengeData.isTranscript);
    }, [challengeData])

    const backBtnListnerFunction = (data) => {
        handleBackButtonEvent(data.detail.screenStateSpotlight);
    }

    useEffect(() => {
        resFont();
        getChallengeDataAsync();
        // eventBus.on(BACK_BTN_EVENT, (data) => {
        //     handleBackButtonEvent(data.screenStateSpotlight);
        // });
        eventBus.on(BACK_BTN_EVENT, backBtnListnerFunction);
        return () => {
            eventBus.remove(BACK_BTN_EVENT, backBtnListnerFunction);
            dispatch(handleSpotlightScreenState(SCREEN_STATE.SCENARIO));

            // add submit end challenge attempt
        }
    }, []);

    const handleBackButtonEvent = (screenState) => {
        let screen = isEmpty(screenState) ? spotlightScreenState : screenState;
        if (screen === SCREEN_STATE.RECORD || screen === SCREEN_STATE.PREVIEW) {
            setIsDialogOpen(true);
            setDialogContent(t("Going back will lead to loss of data. Do you really want to continue?"))
        } else if (screen === SCREEN_STATE.INSTRUCTION) {
            dispatch(handleSpotlightScreenState(SCREEN_STATE.SCENARIO));
        } else if (screen === SCREEN_STATE.DEMO_VIDEO) {
            dispatch(handleSpotlightScreenState(SCREEN_STATE.SCENARIO));
            // setIsOverlay(true)
            setIsDemoVideoPlaying(false)
        }
        else if (screen === SCREEN_STATE.SCENARIO) {
            navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId)? `?journeyId=${journeyId}` : ''}`);
        }
    }

    const handleBackButtonClick = () => {
        eventBus.dispatch(BACK_BTN_EVENT,
            { screenStateSpotlight: spotlightScreenState }
        );
    }

    const handleDiscardBtnClick = () => {
        setIsDialogOpen(false)
    }

    const handleConfirmBtnClick = () => {
        setIsDialogOpen(false);
        if (spotlightScreenState === SCREEN_STATE.SCENARIO) return
        navigate(-1);
    }

    // const startChallengeAsync = async () => {
    //     let respData = await startChallengeAttempt({ microskillId, challengeId });
    //     if (respData.status === 200 || respData.status === 201) {
    //         setAttemptId(respData?.data?.data.id);
    //     }
    // }

    const startChallengeAsync = async () => {
        let respData = await getChallengeData(microskillId, challengeId);
        if (respData.status === 200 || respData.status === 201) {

            const totalAttempts = respData?.data?.data?.userMicroskillChallenge.totalAttempts;
            const maxAttempts = respData?.data?.data?.gameData.SpotLight.maxAttempts;
            setTotalAttempts(totalAttempts);
            setChallengeData({ ...respData?.data?.data?.gameData.SpotLight });
            setMaxAttempts(maxAttempts);

            if (maxAttempts - totalAttempts <= 0) {
                setIsDialogOpen(true);
                setDialogContent(t("You have reached the maximum number of attempts for this scenario."));
                setDialogueConfirmBtnName('Okay');
                setDiscardBtnName('');
                dispatch(handleSpotlightScreenState(SCREEN_STATE.SCENARIO));

                return;
            } else {
                let respSCAData = await startChallengeAttempt({ microskillId, challengeId });
                if (respSCAData.status === 200 || respSCAData.status === 201) {
                    setAttemptId(respSCAData?.data?.data.id);
                }
            }
        }

        // }
    }

    const handleMediaSubmit = async (attemptId = attemptId) => {
        // console.log("attemptId: ", attemptId);

        setIsSubmitting(true);
        try {
            let audioFormData = new FormData();
            audioFormData.append("challengeId", challengeId);
            audioFormData.append("microskillId", microskillId);
            audioFormData.append("timespent", `${duration}`);
            audioFormData.append("spotLightAudioFile", audioFile);
            audioFormData.append("companyId", authState.companyId);
            audioFormData.append("attemptId", attemptId);
            audioFormData.append("faceAnalytics", JSON.stringify(spotlightAnalytics.faceAnalytics));

            // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let audioResData = await axiosInstance.post(`/spotlight-attempt`, audioFormData, {
                onUploadProgress: (progressEvent) => {
                    let uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
                    let uploadPercentageFixed = Math.round(uploadPercentage);
                    if (progressEvent.lengthComputable) {
                        setVideoUploadProgress(uploadPercentageFixed - 2);
                    }
                    if (uploadPercentageFixed >= 100 && challengeData.ratingMethod === 'AI') {
                        // setShowAnalyzeOverlay(true);
                    }
                }
            });
            let data = audioResData.data.data;
            const saveSpotlightDataReq = {
                challengeId: challengeId,
                microskillId: microskillId,
                attemptId: attemptId,
                companyId: authState.companyId,
                transcript: spotlightAnalytics.transcript,
                jobId: data.jobId,
                faceAnalytics: spotlightAnalytics.faceAnalytics,
                response: (function () {
                    let tempObj = { ...spotlightAnalytics };
                    delete tempObj.transcript;
                    delete tempObj.faceAnalytics;
                    return tempObj;
                })() // removing transcript from response and rest of the fields will be automatically added
            }

            await axiosInstance.post("/save-spotlight-analytics", saveSpotlightDataReq, {
                onUploadProgress: (progressEvent) => {
                    let uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
                    let uploadPercentageFixed = Math.round(uploadPercentage);
                    if (progressEvent.lengthComputable) {
                        setVideoUploadProgress(uploadPercentageFixed);
                        // console.log("uploadPercentageFixed: ", uploadPercentageFixed);
                    }
                }
            });
            setIsSubmitting(false);
            let videoFormData = new FormData();
            videoFormData.append("userSpotlightResponseId", data.id);
            videoFormData.append("spotLightVideoFile", videoFile);
            videoFormData.append("companyId", authState.companyId);

            // let videoRespData = saveSpotlightVideo(videoFormData);
            saveSpotlightVideo(videoFormData).then(res => {
                console.log("spotlight video uploading response: ", res);
            }).catch(error => {
                console.log("spotlight video uploading errored: ", error);
            })

            navigate(`/spotlight-result/${microskillId}/${challengeId}/${data.id}/${data.jobId}?name=${urlParams.get('name')}&attemptId=${attemptId}${!isEmpty(journeyId) ? `&journeyId=${journeyId}` : ''}`, { state: { challengeData, totalAttempts } });
            // console.log("video res data:", videoRespData.data);
        } catch (err) {
            setIsSubmitting(false);
            setVideoUploadProgress(0);
            if (!navigator.onLine) {
                dispatch(openDialogue({
                    isDialogueOpen: true,
                    dialogueTitle: 'Network Error',
                    dialogueContent: t('You seem to be offline, please check your internet and try again.')
                }));
            }
        }
    }


    // face detection code starts
    // const handlePermission = async () => {
    //     // video
    //     try {
    //         setIsCameraFeed(false);
    //         await launchCamera()

    //     } catch (err) {
    //         console.log("video err:", err);
    //         setIsCameraFeed(false)
    //     }
    //     // video
    // };

    const launchCamera = () => {
        rectBound.current = document.getElementById('spotlightBox').getBoundingClientRect();
        let tempVideo = document.querySelector("#faceDetectVideo")
        let videoConfig = {
            mandatory: {
                minWidth: rectBound.current.width,
                minHeight: 600,
                maxWidth: rectBound.current.width,
                maxHeight: rectBound.current.height,
            }
        }
        return new Promise(resolve => {
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: true,
                    // video: videoConfig
                })
                .then(
                    stream => {
                        // setStreamedVideo(stream)
                        cameraStreamRef.current = stream
                        tempVideo.srcObject = stream;
                        // tempVideo.play();
                        setIsCameraFeed(true);
                        resolve();
                    },
                    () => { }
                );
        });
    }

    useEffect(() => {
        if (cameraStreamRef.current) {
            // let camera = cameraStreamRef.current.getVideoTracks()[0].getSettings()
            // let video = document.getElementById("spotlightBox").getBoundingClientRect()
        }
    }, [cameraStreamRef.current])

    // const handleStartScenerioClick = async () => {
    //     setShowDetectionScreen(false)
    //     cameraStreamRef.current.getTracks().forEach(track => {
    //         track.stop();
    //     })
    //     setIsCameraFeed(false)
    // }

    return (
        <div className={`${classes.spotlightRoot} ${classes.centerFlex}`} style={{
            width: '100%',
            height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        }} id="recordVideoRootId">
            {
                !isMobile && (
                    <div className={`${classes.backBtnBox} ${classes.centerFlex}`} onClick={handleBackButtonClick}>
                        <KeyboardBackspaceIcon sx={theme => ({ color: theme.palette.white })} />
                    </div>
                )
            }
            <div className={classes.spotlightBox}
                id="spotlightBox"
                style={{
                    height: `100%`,
                    fontSize: `${fSize}rem`
                }}
            >
                {
                    showDetectionScreen && <video
                        style={{
                            display: isCameraFeed ? 'block' : 'none',
                            position: "relative",
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            // height: rectBound?.current?.height,
                            // width: rectBound?.current?.width,
                            WebkitTransform: 'scaleX(-1)',
                            transform: 'scaleX(-1)',
                            objectFit: 'cover'
                            // zIndex: '-1'
                        }}
                        // width={480}
                        // height={640}
                        id="faceDetectVideo"
                        muted
                        // ref={refVideo}
                        className={classes.videoTag}
                        playsInline
                        autoPlay={true}
                        controls={false}
                    />
                }



                {
                    isEmpty(challengeData) ? (
                        <div style={constants.FULL_HEIGHT_CENTER}>
                            <CircularProgress />
                        </div>
                    ) :
                        (
                            <>
                                {/* {
                                    showDetectionScreen && <FaceDetectionModel
                                        isCameraFeed={isCameraFeed}
                                        handleStartScenerioClick={handleStartScenerioClick}
                                    />
                                } */}
                                {
                                    !showDetectionScreen && spotlightScreenState === SCREEN_STATE.SCENARIO && (
                                        <Scenario
                                            // setScreenState={setScreenState}
                                            screenStateList={SCREEN_STATE}
                                            spotlightData={challengeData}
                                            isStartrecording={isStartrecording}
                                            setIsStartrecording={setIsStartrecording}
                                            viewSampleRecording={viewSampleRecording}
                                            setViewSampleRecording={setViewSampleRecording}
                                            viewTranscript={viewTranscript}
                                            setViewTranscript={setViewTranscript}
                                            totalAttempts={totalAttempts}
                                        />
                                    )
                                }

                                {
                                    !showDetectionScreen && spotlightScreenState === SCREEN_STATE.INSTRUCTION && (
                                        <SpotlightInstructions
                                            // setScreenState={setScreenState}
                                            screenStateList={SCREEN_STATE}
                                            remainingAttempts={maxAttempts - totalAttempts}
                                        />
                                    )
                                }

                                {
                                    !showDetectionScreen && spotlightScreenState === SCREEN_STATE.DEMO_VIDEO && (
                                        <SampleRecording
                                            // setScreenState={setScreenState}
                                            screenStateList={SCREEN_STATE}
                                            spotlightData={challengeData}
                                            isStartrecording={isStartrecording}
                                            setIsStartrecording={setIsStartrecording}
                                            viewSampleRecording={viewSampleRecording}
                                            setViewSampleRecording={setViewSampleRecording}
                                            viewTranscript={viewTranscript}
                                            setViewTranscript={setViewTranscript}
                                            isOverlay={isOverlay}
                                            setIsOverlay={setIsOverlay}
                                            isVideoPlaying={isDemoVideoPlaying}
                                            setIsVideoPlaying={setIsDemoVideoPlaying}
                                        />
                                    )
                                }

                                {
                                    !showDetectionScreen && spotlightScreenState === SCREEN_STATE.RECORD && (
                                        // <RecordVideoFaceDetection
                                        <RecordVideo2
                                            // setScreenState={setScreenState}
                                            screenStateList={SCREEN_STATE}
                                            isMobile={isMobile}
                                            setVideoFile={setVideoFile}
                                            setVideoBlob={setVideoBlob}
                                            setAudioFile={setAudioFile}
                                            setAudioBlob={setAudioBlob}
                                            setDuration={setDuration}
                                            spotlightData={challengeData}
                                            totalAttempts={totalAttempts}
                                            setSpotlightAnalytics={setSpotlightAnalytics}
                                            setIsEmptySpeech={setIsEmptySpeech}
                                            startChallengeAsync={startChallengeAsync}
                                            remainingAttempts={maxAttempts - totalAttempts}
                                        />
                                    )
                                }

                                {
                                    !showDetectionScreen && spotlightScreenState === SCREEN_STATE.PREVIEW && (
                                        <PreviewVideo
                                            // setScreenState={setScreenState}
                                            screenStateList={SCREEN_STATE}
                                            videoFile={videoFile}
                                            videoBlob={videoBlob}
                                            audioBlob={audioBlob}
                                            spotlightData={challengeData}
                                            showAnalyzeOverlay={showAnalyzeOverlay}
                                            isSubmitting={isSubmitting}
                                            videoUploadProgress={videoUploadProgress}
                                            remainingAttempts={maxAttempts - totalAttempts}
                                            // startChallengeAsync={startChallengeAsync}
                                            handleMediaSubmit={handleMediaSubmit}
                                            isEmptySpeech={isEmptySpeech}
                                            attemptId={attemptId}
                                            setIsEmptySpeech={setIsEmptySpeech}
                                        />
                                    )
                                }
                            </>
                        )
                }
            </div>
            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        handleConfirmBtnClick={handleConfirmBtnClick}
                        isDualChoice={isDualChoice}
                        confirmBtnName={dialogueConfirmBtnName}
                        discardBtnName={discardBtnName}
                        handleDiscardBtnClick={handleDiscardBtnClick}
                    />
                )
            }
        </div>
    )
}

export default Spotlight;
