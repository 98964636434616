import React, { useRef, useEffect, useState } from "react";
// import useRouter from "../../utils/useRouter";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import WestIcon from '@mui/icons-material/West';
import MicroskillListCard from "../../components/microskill-list-card";
import { Button, CircularProgress, Fade, Rating, useMediaQuery } from "@mui/material";
import StarIcon from '@mui/icons-material/StarOutline';

import theme from "../../theme";

// components
import MicroskillFeedBack from "../../components/microskill-feedback-form";
import HeaderBackButton from "../../components/layout-elements/header-back-button";

// redux
import { getLearningJourneyMicroskill, markMicroskillFeedbackDismiss } from "../../redux/microskill/microskill.api";
import { setLearnerJourneyEnded } from "../../redux/common/common-slice";

// utils
import isEmpty from "../../utils/isEmpty";
import { getHeaderHeight, isNativeApp } from '../../utils/commonUtil';

// theme
// import palette from "../../theme/palette";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";


const LearningJourneyPage = (props) => {
    const navigate = useNavigate();
    const { learningJourneyId, microskillId } = useParams();
    const dispatch = useDispatch();
    // const router = useRouter();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const learnerJourneyEnded = useSelector(state => state?.common?.learnerJourneyEnded);
    const challengeListRef = useRef();
    const challengeListContainerRef = useRef();
    const challengeListHeaderRef = useRef();
    const challengeListBgRef = useRef();
    const challengeListMobileHeaderRef = useRef();
    // const [showModuleName, setshowModuleName] = useState(false);
    const [microSkillListData, setMicroSkillListData] = useState();
    const [learningJourneyData, setLearningJourneyData] = useState();
    const [completionPer, setCompletionPer] = useState(0);
    const [microskillFeedbackShow, setMicroskillFeedbackShow] = useState(false);
    const [microskillRating, setMicroskillRating] = useState(null);
    // const [isWinHeader, setIsWinHeader] = useState(true);

    const checkIcon = "/images/icons/check_green.png";
    const concentricIcon = "/images/icons/concentric_yellow.png";
    const certificateIcon = "/images/icons/medal.png";
    const btnIcon = "/images/icons/mastero_button_icon.svg";
    const lockIcon = "/images/icons/lock_white.png";
    const { t, i18n } = useTranslation()
    const classes = useStyles(i18n);

    useEffect(() => {
        // eventBus.on(BACK_BTN_EVENT, (data) => {
        //     console.log("challenge list page")
        // });
        // eventBus.remove(BACK_BTN_EVENT, () => { });

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const getJourneyListData = async () => {
        const res = await getLearningJourneyMicroskill(learningJourneyId);
        let data = res?.data?.data;
        let sortedData = [...data.microskills];
        // let sortedData = data?.challenges.sort((a, b) => a.sequence - b.sequence);
        setMicroSkillListData(sortedData);
        setLearningJourneyData(data?.learnerJourney);
        setCompletionPer(Math.round((data?.learnerJourney?.completedMicroskills * 100) / data?.learnerJourney?.totalMicroskills));
        dispatch(setLearnerJourneyEnded(data?.journeyEnded))
    }

    useEffect(() => {
        getJourneyListData()
    }, [])

    useEffect(() => {
        if (isEmpty(learningJourneyData)) return
        let marqueeEl = document.getElementById(learningJourneyData?.microskillId)
        if (isEmpty(marqueeEl)) return
        var overflowX = marqueeEl.offsetWidth < marqueeEl.scrollWidth,
            overflowY = marqueeEl.offsetHeight < marqueeEl.scrollHeight;
        if (overflowX) {
            if (!isEmpty(marqueeEl.firstChild)) {
                marqueeEl.firstChild.classList.add(classes.marqueeAnimation);
            }
        }
    }, [learningJourneyData])

    const handleScroll = () => {
        if (isMobile || isTablet) {
            if (!isEmpty(challengeListContainerRef.current) && challengeListContainerRef.current.scrollTop > 0) {
                if (isEmpty(challengeListMobileHeaderRef.current)) return
                challengeListMobileHeaderRef.current.style.background = '#4f0060';
            } else {
                if (isEmpty(challengeListMobileHeaderRef.current)) return
                challengeListMobileHeaderRef.current.style.background = null;
            }
            return
        }
    }

    const pathIcon = (data) => {
        let icon = checkIcon;
        if (data === 'certificate') {
            icon = certificateIcon;
        }
        else if (data?.completion) {
            icon = checkIcon
        } else if (data.status?.toUpperCase() === 'LOCKED' || data === 'LOCKED') {
            icon = lockIcon
        } else if (data.status?.toUpperCase() === 'CHECK') {
            icon = checkIcon
        } else {
            if ((!data?.completion && data?.inCompleteAttempts > 0 && data?.correctAttempts === 0) || data?.status?.toUpperCase() === 'ACTIVE') {
                icon = concentricIcon
            } else if (data?.status?.toUpperCase() === 'LOCKED') {
                icon = lockIcon
            }
        }
        return (
            <div className={classes.pathway}>
                <div className={`${classes.topPath} topPathBox`} />
                <img src={icon} alt="icon" className={classes.icon}/>
                <div className={`${classes.bottomPath} bottomPathBox`} />
            </div>
        )
    }

    const checkCardBlur = (data) => {
        return data?.status?.toUpperCase() === 'LOCKED';
    }

    const handleBackBtnClick = () => {
        navigate('/learn')
    }

    const handleCertificateClick = () => {
        if (!learningJourneyData?.certified) return
        navigate(`/learning-journey/${learningJourneyData.id}/certificate`);
    }

    const handleFeedbackClick = () => {
        setMicroskillFeedbackShow(true);
    }

    const handleFeedbackDismiss = async () => {
        let payload = { microskillId };
        const respData = await markMicroskillFeedbackDismiss(payload);
        if (respData.status === 200 || respData.status === 201) {
            setMicroskillFeedbackShow(false);
        }
    }

    useEffect(() => {
        if (learningJourneyData?.completion && learningJourneyData?.Microskill.showFeedback && !learningJourneyData?.feedbackSubmitted && !learningJourneyData?.feedbackDismissed && !microskillFeedbackShow) {
            handleFeedbackDismiss()
        }
    }, [microskillFeedbackShow]);

    const handleMicroskillCardClick = (d) => {
        if (!d.status?.toUpperCase().includes('ACTIVE')) return; // if microskill is not active then do nothing (schedule launch)
        navigate(`/challenge-list/${d?.Microskill?.id}?journeyId=${learningJourneyId}`);
    }

    const ProgressBox = () => {
        return (
            <>
                <div className={classes.headerdetails} >
                    My Progress:
                </div>
                <div className={classes.completionBox}>
                    {`Completed ${learningJourneyData?.completedMicroskills} of ${learningJourneyData?.totalMicroskills} Microskills`}
                </div>
                <div className={classes.progressRoot}>
                    <div className={classes.progressBox}
                        style={{ width: `${(learningJourneyData?.completedMicroskills / learningJourneyData?.totalMicroskills) * 100}%` }}>
                        <div className={classes.progress}></div>
                    </div>
                </div>
            </>
        )
    }

    const WinAnimationHeader = () => {
        const [isFadeImg, setIsFadeImg] = useState(true);
        const [isFadeText, setIsFadeText] = useState(false);
        setTimeout(() => {
            setIsFadeImg(false)
        }, 2000);

        useEffect(() => {
            if (!isFadeImg) setIsFadeText(true)
        }, [isFadeImg]);
        // console.log("learningJourneyData:", learningJourneyData);

        return (
            <div className={classes.challengeListHeader}>
                {!isMobile && !isTablet && (
                    <div className={classes.winHeaderBackBtn}>
                        <HeaderBackButton onClick={() => navigate('/learn')} />
                    </div>
                )}
                <div className={`${classes.challengeListHeaderBox} ${classes.winHeader}`}>
                    <Fade in={isFadeImg} >
                        <img src="/images/icons/check_green.png" alt="check" height={80} className={classes.centerAbsoulte} />
                    </Fade>
                    {
                        !isFadeImg && (
                            <Fade in={isFadeText}>
                                <div className={`${classes.centerAbsoulte} ${classes.congratsText}`}>
                                    Congratulations!
                                    <div>"{learningJourneyData.name}" Completed</div>
                                </div>
                            </Fade>
                        )
                    }
                </div>
            </div>
        )
    }

    const Header = () => {
        return (
            <div className={classes.challengeListHeader} ref={challengeListHeaderRef}>
                <div className={classes.challengeListHeaderBox}>
                    <div className={classes.mobileProgressBox}>
                        <ProgressBox />
                    </div>
                    {!isMobile && !isTablet && (
                        <>
                            <div className={classes.headerMainText}>
                                <div className={`${classes.backIcon} ${classes.centerFlex}`} onClick={handleBackBtnClick}> <WestIcon /> </div>
                                {
                                    !isEmpty(learningJourneyData) && (
                                        <div className={classes.marqueeText} id={`${learningJourneyData.id}`}>
                                            <span className={classes.marqueeTextSpan} > {learningJourneyData?.name}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <ProgressBox />
                        </>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={classes.challengeListContainer}
            ref={challengeListContainerRef}
            onScroll={handleScroll}
            onTouchMove={handleScroll}
        >
            <div className={classes.challengeListBox}
                onScroll={handleScroll}
                onTouchMove={handleScroll}
                ref={challengeListRef}
            >
                {
                    isEmpty(microSkillListData) ? (
                        typeof microSkillListData === 'object' && microSkillListData.length === 0 ? (
                            <div className={`${classes.centerFlex} ${classes.noChallengeText}`}>{t("No challenge found")}</div>
                        ) : (
                            <div style={constants.FULL_HEIGHT_CENTER}>
                                <CircularProgress />
                            </div>
                        )
                    ) : (
                        <>
                            <div className={classes.challengeListBg} ref={challengeListBgRef}
                            // style={{ backgroundImage: `url(${isEmpty(learningJourneyData?.Microskill?.OrgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : learningJourneyData?.Microskill?.OrgCoverImage}) ${isMobile ? ', radial-gradient(transparent, black)' : ''}`, }}
                            >
                                {/* mobile */}
                                <div className={classes.challengeListMobileHeader}
                                    ref={challengeListMobileHeaderRef}
                                    // style={{ height: isNativeApp() ? '100px' : '80px' }}
                                    style={{ height: getHeaderHeight() }}
                                >
                                    <div className={`${classes.centerFlex} ${classes.backButton}`} style={{ marginTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null }}
                                        onClick={handleBackBtnClick}>
                                        <HeaderBackButton />
                                        {/* <img src="/images/icons/left_arrow.svg" alt="icon" width={20} /> */}
                                        {/* <KeyboardBackspaceIcon sx={{ color: '#FFF' }} /> */}
                                    </div>
                                    <div className={`${classes.centerFlex} ${classes.moduleName}`}>
                                        {learningJourneyData?.name}
                                    </div>
                                </div>
                                {
                                    learnerJourneyEnded ? (<WinAnimationHeader />) : (<Header />)
                                }
                            </div>
                            <div className={classes.challengeListCardBox}>
                                <ul className={classes.stepperUl}>
                                    {
                                        microSkillListData.map((item, index) => (
                                            <li className={`${classes.stepperLi}`} key={item.microskillId}>
                                                {pathIcon(item)}
                                                <MicroskillListCard
                                                    handleMicroskillCardClick={() => handleMicroskillCardClick(item)}
                                                    data={item}
                                                    index={index}
                                                    isBlur={checkCardBlur(item)}
                                                    message={item.message}
                                                />
                                            </li>
                                        ))
                                    }
                                    {
                                        learningJourneyData?.certified && (
                                            <li className={`${classes.stepperLi}`}>
                                                {pathIcon(completionPer === 100 ? 'CHECK' : 'LOCKED')}
                                                <div className={`${classes.certificateBox}`}>
                                                    {
                                                        completionPer == 100 ? (
                                                            <Button variant="contained" className={classes.certificateBtn}
                                                                onClick={handleCertificateClick} >
                                                                {t("View Certificate")}
                                                            </Button>
                                                        ) : (
                                                            <span>{t("Earn Your Certificate")}{completionPer}</span>
                                                        )
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }
                                    {
                                        learningJourneyData?.completion && learningJourneyData?.Microskill?.showFeedback && !learningJourneyData?.feedbackSubmitted && (
                                            <div className={classes.feedbackConatiner}>
                                                <div className={classes.feedbackHeadline}>{t("Feedback Rating")}:</div>
                                                <Button variant="contained" className={classes.certificateBtn} onClick={handleFeedbackClick}
                                                >
                                                    {t("Rate this Microskill now")}!
                                                </Button>
                                            </div>
                                        )
                                    }

                                    {isMobile || isTablet && (

                                        learningJourneyData?.completion && learningJourneyData?.Microskill?.showFeedback && learningJourneyData?.feedbackSubmitted && (
                                            <div className={classes.feedbackConatiner}>
                                                <div className={classes.feedbackHeadline}>{t("Your Rating")}:</div>
                                                <div className={`${classes.centerFlex} ${classes.feedbackRatingConatiner}`}>
                                                    <div className={classes.feedbackRatingText}>{learningJourneyData?.userFeedback} / 5</div>
                                                    <Rating value={learningJourneyData?.userFeedback} emptyIcon={<StarIcon style={{ color: "#FFF" }} />} readOnly />
                                                </div>
                                            </div>
                                        )

                                    )}

                                    {
                                        learningJourneyData?.completion && learningJourneyData?.Microskill?.showFeedback && !learningJourneyData?.feedbackSubmitted && (
                                            <MicroskillFeedBack openFeedback={microskillFeedbackShow} setOpenFeedback={setMicroskillFeedbackShow} feedbackMicroskillId={learningJourneyData?.microskillId} name={learningJourneyData?.Microskill?.name} ratingValue={microskillRating} setRatingValue={setMicroskillRating} url={'/learn'} />
                                        )
                                    }
                                </ul>
                            </div>
                        </>
                    )
                }
            </div>
        </div >
    )
}

export default LearningJourneyPage;