import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// mui
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

// components
import SideDrawer from '../side-drawer';
import DialogAlert from '../../dialog-alert';
import HeaderBackButton from '../header-back-button';

// redux
import { setHeaderSearchText, setRotateMobile } from '../../../redux/common/common-slice';
// import { handleSpotlightScreenState } from '../../../redux/spotlight/spotlight-slice';

// utils
import commonUtil, { getHeaderHeight } from '../../../utils/commonUtil';
import eventBus from "../../../utils/eventBus";
import isEmpty from '../../../utils/isEmpty';
import { isNativeApp } from '../../../utils/commonUtil';

// theme
import palette from '../../../theme/palette';

// constants
import constants from '../../../constants';

// styles
import useStyles from './index.styles';

const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;
const drawerWidth = 240;
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const displayDrawerStyleMobile = {
    sm: 'block',
    md: 'none'
}
const displayDrawerStyleDesktop = {
    xs: 'none',
    sm: 'none',
    md: 'block'
}
// const SPOTLIGHT_SCREEN_STATE = constants.SPOTLIGHT_SCREEN_STATE;
// const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const pagesToViewRotateBtn = ['scorm', '.ppt', ...constants.IMAGE_EXTENSIONS_TYPE, ...constants.VIDEO_EXTENSIONS_TYPE];

const Header2 = (props) => {
    const { showSearchHeader, showHeaderBackButton } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');

    const { isMobile, isDocViewing } = useSelector(state => state?.common);
    const { microskillId } = useParams();
    const spotlightScreenState = useSelector(state => state?.spotlight.screenState);
    const gbaScreenState = useSelector(state => state?.gba.screenState);
    const isBrowserTabFocused = useSelector(state => state?.common?.isBrowserTabFocused);
    const queryParams = new URLSearchParams(window.location.search);
    const containerRef = useRef(null);
    const isRotatedMobile = useSelector(state => state?.common?.isRotatedMobile);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('Unsaved Data');
    const [searchInputValue, setSearchInputValue] = useState('');

    useEffect(() => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('')
    }, [window.location.pathname])

    useEffect(() => {
        return () => {
            eventBus.remove(BACK_BTN_EVENT);
        }
    }, []);
    useEffect(() => {
        if (!isBrowserTabFocused) handleBackButtonClick();
    }, [isBrowserTabFocused])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleSearchBtnClick = () => {
        setIsSearchVisible(true)
    };
    const handleBackButtonClick = () => {
        if (location.pathname.includes('gba') || location.pathname.includes('spotlight')) {
            eventBus.dispatch(BACK_BTN_EVENT, {
                screenStateGba: gbaScreenState,
                screenStateSpotlight: spotlightScreenState
            });
            // navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
            return;
        }
        if (location.pathname.includes('certificate')) {
            navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
            return;
        }
        navigate(-1);

    };
    const handleDiscardBtnClick = () => {
        setIsDialogOpen(false)
        navigate(-1)
    }
    const handleCloseSearchClick = () => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('')
        setIsSearchVisible(false);
    };
    const handleSearchBarText = (e) => {
        dispatch(setHeaderSearchText(e?.target?.value));
        setSearchInputValue(e.target.value);
    }

    const handleMenuClick = async (data) => {
        setAnchorElUser(null);
        data.func();
        handleCloseUserMenu();
    };

    const handleRotate = () => {
        dispatch(setRotateMobile(!isRotatedMobile));
    }

    // AKIB Shorten the name of file 
    // const queryStringShorterFunc = () => {
    //     let queryStringName = queryParams.get("name")
    //     let queryArr = queryStringName.split(".")
    //     let format = queryArr[queryArr.length - 1]
    //     if (queryArr[0].length > 25) {
    //         return queryArr[0].slice(0, 21) + "..." + format
    //     } else {
    //         return queryStringName
    //     }
    // }

    // SHORTEN NAME OF FILE
    const getName = (fName) => {
        if (isEmpty(fName)) return;
        if (!fName.includes(".")) return fName;
        let tempArr = fName.split(".");
        let lastEl = tempArr.pop();
        const tempName = tempArr.toString();
        if (fName.length > 24) {
            return tempName.slice(0, 20) + "..." + lastEl;
            // return { fName: tempName.slice(0, 20) + "...", extension: lastEl }
        }
        return fName;
        // return { fName: tempArr.toString(), extension: lastEl }
    }

    const showRotateBtn = () => {
        let showBtn = false;
        for (let i = 0; i < pagesToViewRotateBtn.length; i++) {
            if (window.location.href.includes(pagesToViewRotateBtn[i])) {
                showBtn = true;
                break;
            }
        }
        return showBtn;
    }

    return (
        <>
            <Box className={classes.headerBox2} style={{ height: getHeaderHeight() }}>
                <AppBar component="nav" className={classes.appBarRoot} sx={{
                    boxShadow: location.pathname.includes("content-repository") ? "rgba(0, 0, 0, 0.15) 0px 4px 5px 0px !important" : 'none !important'
                }}>
                    <Toolbar className={classes.toolbar} ref={containerRef}
                        sx={{
                            // paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null,
                        }}>
                        <div>
                            {
                                showHeaderBackButton && (
                                    <Box sx={{ display: displayDrawerStyleMobile, justifyContent: 'flex-start' }}
                                        onClick={handleBackButtonClick}
                                        className={`disableSelection`}
                                    >
                                        <HeaderBackButton />
                                    </Box>
                                )
                            }
                            <Box sx={{ mr: 2, display: displayDrawerStyleDesktop }}>
                                {/* <img src="/images/login-screen/login_logo.svg" alt="logo" width={150} /> */}
                            </Box>
                        </div>

                        <div className={classes.headerText}>
                            {location.pathname === '/privacy-policy' ? "Privacy Policy" : ""}
                            {
                                getName(queryParams.get("name"))
                            }
                            {/* {getName(queryParams.get("name"))?.extension} */}
                        </div>
                        {
                            showRotateBtn() && <div className={`${classes.centerFlex}`}> <ScreenRotationIcon style={{ color: '#F4511E' }} onClick={handleRotate} /> </div>
                        }
                        {
                            showSearchHeader && !isDocViewing && location.pathname !== '/privacy-policy' && (

                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <img src="/images/icons/search.svg"
                                        alt="search"
                                        width={25}
                                        height={25}
                                        onClick={handleSearchBtnClick}
                                        className={`disableSelection`}
                                    />
                                </Box>

                            )
                        }

                        {
                            isSearchVisible && !isDocViewing && (
                                <Slide direction="left" in={isSearchVisible} container={containerRef.current}>
                                    <div className={classes.searchBox} style={{ top: isNativeApp() ? constants.HEADER_TOP_PADDING : '' }}>
                                        <img src="/images/icons/search.svg" alt="search" width={18} className={classes.searchIcon} />
                                        <input onChange={handleSearchBarText} autoFocus />
                                        <img src="/images/icons/close.svg" alt="search" width={20} className={classes.closeIcon} onClick={handleCloseSearchClick} />
                                    </div>
                                </Slide>
                            )
                        }

                        {
                            !isMobile && (
                                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', flexGrow: 0 } }} className={classes.accountDetailBox}>
                                    <div>
                                        <div className={`${classes.avatarBox} ${classes.centerFlex}`} onClick={handleOpenUserMenu}>
                                            {
                                                isEmpty(commonUtil.getUserName()) ? (
                                                    <PermIdentityIcon />
                                                ) : (commonUtil.getUserName()?.charAt(0).toUpperCase())
                                            }
                                        </div>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {
                                                constants.HEADER_DROPDOWN.map((item) => (
                                                    <MenuItem key={item.id} onClick={() => handleMenuClick(item)}>
                                                        <img src={item.icon_orange} alt="icon" className={classes.dropDownIcon} width={20} />
                                                        <Typography textAlign="center" className={classes.menuText}>{item.name}</Typography>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </div>
                                </Box>
                            )
                        }
                    </Toolbar>
                </AppBar>

                <Box component="nav">
                    <Drawer
                        // container={container}
                        className={classes.mobileDrawer}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: displayDrawerStyleMobile,
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <ListItem className={classes.listBox} onClick={handleDrawerToggle}
                            style={{ paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null }}
                        >
                            <ListItemButton>
                                <CloseIcon sx={{ color: palette.orange, fontSize: 30 }} />
                            </ListItemButton>
                        </ListItem>
                        <SideDrawer />
                    </Drawer>
                </Box>
            </Box >

            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        handleConfirmBtnClick={() => setIsDialogOpen(false)}
                        confirmBtnName={'No'}
                        discardBtnName={'Yes'}
                        handleDiscardBtnClick={handleDiscardBtnClick}
                    />
                )
            }
        </>
    );
}


export default Header2;