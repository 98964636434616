import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Components
import AudioControls from "../audio-controls";

// utils
import isEmpty from "../../utils/isEmpty";

//styles
import useStyle from "./index.styles";
import useGlobalAbortController from "../../utils/abortController";
import { markRepoViewed } from "../../redux/content-repo/content-repo.api";

const AudioPlayer = ({ index, tracks, from, setIsShareButtonVisible, setIsDownloadButtonVisible }) => {
    const classes = useStyle({ from });
    const isMobile = useSelector(state => state?.common?.isMobile);
    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const [trackIndex, setTrackIndex] = useState(index);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const timeElapsedRef = useRef();
    // console.log("index, tracks :", tracks);
    // Destructure for conciseness
    const { path, enableFileDownload = true, enableFileShare = true } = tracks[trackIndex]?.Repository;
    // const [audioPath, setAudioPath] = useState(path)

    // Refs
    const audioRef = useRef(new Audio(path));
    const intervalRef = useRef();
    const isReady = useRef(false);
    const audioLoadIntervalRef = useRef();
    const totalTimeRef = useRef();
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const { setNewAbortSignal } = useGlobalAbortController();

    // Destructure for conciseness
    const { duration } = audioRef.current;
    let totalTrackDuration = '0:00';

    // const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : "0%";
    const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, from(#564273), to(#564273))`;
    // const trackStyling = ` -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))`;

    useEffect(() => {
        console.log("tracks", tracks.length, tracks)
        if (!isAudioLoaded) {
            audioLoadIntervalRef.current = setInterval(() => {
                if (isNaN(audioRef.current.duration)) return
                clearInterval(audioLoadIntervalRef.current);
                audioLoadIntervalRef.current = null;
                getTrackDuration();
                setIsAudioLoaded(true);
            }, 1000);
        }

        audioRef.current.pause();
        audioRef.current = new Audio(tracks[trackIndex]?.Repository?.path);
        setTrackProgress(audioRef.current.currentTime);
        if (isReady.current) {
            // audioRef.current.play();
            // setIsPlaying(true);
            startTimer();
        } else {
            // Set the isReady ref as true for the next pass
            isReady.current = true;
            setIsPlaying(true)
        }

    }, [trackIndex])


    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    const getDisplayTimer = () => {
        let audioCurrentTime = Math.round(audioRef.current.currentTime);
        let timeInSeconds = audioCurrentTime % 60 > 9 ? (audioCurrentTime % 60) : '0' + (audioCurrentTime % 60);
        let timeInMinutes = parseInt(audioCurrentTime / 60);
        return (`${timeInMinutes}:${timeInSeconds}`);
    }

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            timeElapsedRef.current.innerHTML = getDisplayTimer();
            if (audioRef.current.ended) {
                setIsPlaying(false);
                clearInterval(intervalRef.current);
                // handlePlayBtnClick(true);
                // hanldeTrackEnd();
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, 1000);
    };

    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        timeElapsedRef.current.innerHTML = getDisplayTimer();
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };

    const toPrevTrack = () => {
        setIsAudioLoaded(false);
        setIsPlaying(false);
        isReady.current = false;
        if (trackIndex - 1 < 0) {
            setTrackIndex(tracks.length - 1);
        } else {
            setTrackIndex(trackIndex - 1);
        }
    };

    const toNextTrack = () => {
        if (trackIndex < tracks.length - 1) {
            setIsAudioLoaded(false);
            setIsPlaying(false);
            isReady.current = false;
            setTrackIndex(trackIndex + 1);
        } else {
            // setTrackIndex(0);
            return
        }
    };

    const getTrackDuration = () => {
        let trackDuration = Math.round(audioRef.current.duration);
        let timeInSeconds = trackDuration % 60 > 9 ? (trackDuration % 60) : '0' + (trackDuration % 60);
        let timeInMinutes = parseInt(trackDuration / 60);
        if (isEmpty(totalTimeRef.current)) return
        totalTimeRef.current.innerHTML = timeInMinutes + ':' + timeInSeconds;
    }

    // Handles cleanup and setup when changing tracks
    useEffect(() => {

        // if (isEmpty(audioRef.current)) return
        // audioRef.current.onloadedmetadata(() => {
        setIsShareButtonVisible(enableFileShare);
        setIsDownloadButtonVisible(enableFileDownload);
        
        if (from === 'SALES_ENABLEMENT' || from === 'GENERIC_DOC_VIEWER') return;
        const signal = setNewAbortSignal();
        let repo_id = from === 'ILA' ? tracks[trackIndex]?.Repository?.id : tracks[trackIndex]?.repositoryId
        markRepoViewed(repo_id, signal)
        // })
        // }
    }, [from, trackIndex]);


    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        };
    }, []);

    return (
        <div className={classes.audioPlayer} >
            <div className={classes.backDrop}>
                {
                    !isMobile ?
                        (<img src={`/images/documents/music-2x.png`} width={100} height={100} />)
                        :
                        (<img src={`/images/documents/music-1x.png`} width={100} height={100} />)
                }
            </div>
            <div className={classes.trackName}>{tracks[trackIndex].name}</div>
            <div className={classes.audioControls}>
                <AudioControls
                    isPlaying={isPlaying}
                    isReady={isReady.current}
                    onPrevClick={toPrevTrack}
                    onNextClick={toNextTrack}
                    onPlayPauseClick={setIsPlaying}
                    from={from}
                    isDarkMode={isDarkMode}
                />
                <div className={classes.trackSlider}>
                    <input
                        type="range"
                        value={trackProgress}
                        step="1"
                        min="0"
                        max={duration ? duration : `${duration}`}
                        className={classes.slider}
                        onChange={(e) => onScrub(e.target.value)}
                        onMouseUp={onScrubEnd}
                        onTouchEnd={onScrubEnd}
                        onKeyUp={onScrubEnd}
                        style={{ background: trackStyling }}
                    />
                </div>

                <div className={classes.timerBox}>
                    <div className={classes.time} ref={timeElapsedRef}> {'0.00'} </div>
                    <div className={classes.time} ref={totalTimeRef}>{totalTrackDuration}</div>
                </div>
            </div>
        </div>
    )
}

export default AudioPlayer;