import { makeStyles } from "@mui/styles";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    recordVideoRoot: {
        height: 'inherit',
        color: theme.palette.white,
    },
    videoBox: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    recordingOverlayRoot: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        fontSize: '1em'
    },
    videoTag: {
        objectFit: 'cover',
    },
    progressBarBox: {
        position: 'relative',
        backgroundColor: 'grey',
        height: '5px'
    },
    progressBar: {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: 'inherit',
        backgroundColor: '#43a047',
        transition: '1s'
    },
    overlayBox: {
        display: 'grid',
        height: 'calc(100% - 4px)',
        paddingBottom: '2rem',
        alignContent: 'space-between',
        justifyItems: 'center',
        position: 'relative',
        zIndex: '2',
        gridTemplateRows: '8% 84% 8%'
    },
    notificationBox: {
        padding: '1rem 1rem 0.5rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        backgroundImage: 'linear-gradient(#000000b3, #00000000)'
    },
    text: {
        width: '4rem',
        fontSize: '1em',
        fontWeight: 700
    },
    waitingTimeText: {
        fontSize: '5em',
        fontWeight: 900
    },
    wrongGradientBox: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundImage: 'linear-gradient(#ffffff00, #ff00007d)',
        height: '70%',
        display: 'none'
    },
    stopBtn: {
        //     backgroundColor: '#FF0000 !important',
        borderRadius: '4px !important',
        fontWeight: `600 !important`,
        padding: '8px !important',
        // fontSize: '0.9em !important',
        width: 'calc(100% - 2rem) !important'
        //     // width: '90% !important'
        //     // fontSize: '0.7 !important'
    },

    // assist
    assistBoxContainer: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '70% 30%',
        width: '100%',
    },
    assistBox: {
        display: 'grid',
        alignContent: 'flex-end',
        position: 'relative',
        width: '100%',
        height: '100%',
        marginBottom: '1rem'
        // overflow: 'hidden'
    },
    assistTextContainer: {
        display: 'grid',
        gap: '1rem',
        padding: '0.8rem 1rem',
        backgroundColor: 'rgb(43 43 43 / 50%)',
        position: 'absolute',
        bottom: '0',
        right: '-2rem',
        left: '-2rem',
        animation: "$animateOpacity 0.3s ease 0s 1 normal forwards running",
    },
    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    assistTextBtns: {
        display: 'flex',
        gap: '0.8rem',
        overflow: 'auto',
        paddingBottom: '0.5rem'
    },
    assistCloseIcon: {
        textAlign: 'center',
        cursor: 'pointer'
    },
    textBtn: {
        border: '3px solid #FFF',
        borderRadius: ' 4px',
        padding: '0.5rem',
        minWidth: '120px',
        maxWidth: '200px',
        textAlign: 'center',
        fontSize: '1em',
        backgroundColor: "rgb(152 152 152 / 50%)"

    },
    assistBtn: {
        position: 'absolute',
        bottom: '0',
        right: '0rem',
        backgroundColor: "rgb(0 0 0 / 50%)",
        padding: '1rem',
        borderRadius: '5px 0 0 5px',
        cursor: 'pointer'
    },
    assistText: {
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
    },
    // assist
    buttonBox: {
        width: '100%',
        textAlign: 'center'
    },

    // teleprompter

    transcriptBtn: {
        position: 'absolute',
        bottom: '0',
        left: '0rem',
        backgroundColor: "rgb(0 0 0 / 50%)",
        padding: '1rem',
        borderRadius: '5px 0 0 5px',
        cursor: 'pointer',
        transformOrigin: 'center center',
        transform: 'rotate(180deg) translateY(-10%)'
    },
    transcriptBtnText: {
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
    },
    transcriptBtnContainer: {
        textAlign: 'center',
    },
    transcriptTextContainer: {
        width: '100%',
        height: '65%',
        maxHeight: '65%',
        position: 'absolute',
        top: '18%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgb(23 23 23 / 90%)',
        fontSize: '1.5em',
        zIndex: '99',
        display: 'grid',
        gridTemplateRows: '14% 86%',
        overflowY: 'hidden'

    },
    transcriptTextHeader: {
        display: 'grid',
        padding: '1rem',
        gridTemplateColumns: '90% 10%'
    },
    transcriptTextBody: {
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
    },
    transcriptText: {
        padding: '0 1rem',
        lineHeight: '2em',
        fontSize: '1em',
        overflowY: 'hidden',
    },

    //avatar 
    avatarContainer: {
        position: 'relative',
        aspectRatio: '3/4',
        height: "85%",
        marginLeft: 'auto',
        alignSelf: 'center',
        borderRadius: '1em'
        // width: '100%',
        // top: '65%',
        // right: '0',
        // zIndex: '2'
    },

    errorBoxWrapper: {
        width: '100% !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        gap: '0.1rem !important'
    },
    errorBox: {
        backgroundColor: 'rgba(255,0,0,0.5)',
        padding: '1rem',
        height: 'fit-content',
        display: 'none',

    },
    errorText: {
        color: 'white',
        fontSize: '1em',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    errorIcon: {
        color: 'white',
        fontWeight: '800 !important',
        verticalAlign: 'middle',
    },
    errorContainerWrapper: {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        width: '100%',
        padding: '1rem',
        backgroundColor: 'rgba(255,0,0,0.75)',
        transition: '0.3s linear',
        zIndex: '999',
    },
    errorContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        padding: '1rem',
        backgroundColor: 'rgba(0,0,0,0.5)',
        transition: '0.3s linear',
        borderRadius: '4px',
        zIndex: '999',

        visibility: 'hidden',
        opacity: '0',
        "&::before": {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: '4px',
            zIndex: '1',
            filter: 'blur(3px)',
        },

        "& h3": {
            textAlign: 'center',
            color: theme.palette.white,
            fontSize: '1.75em',
            animation: `$shakeObj 1.5s infinite linear`,
        }
    },

    "@keyframes shakeObj": {
        "0%": { transform: "translateX(5px)" },
        "10%": { transform: "translateX(-5px)" },
        "20%": { transform: "translateX(5px)" },
        "30%": { transform: "translateX(-5px)" },
        "40%": { transform: "translateX(5px)" },
        "50%": { transform: "translateX(-5px)" },
        "60%": { transform: "translateX(5px)" },
        "70%": { transform: "translateX(-5px)" },
        "80%": { transform: "translateX(5px)" },
        "90%": { transform: "translateX(-5px)" },
        "95%": { transform: "translateX(5px)" },
        "100%": { transform: "translateX(0px)" },
    },
}))

export default useStyles;