import React from "react";

// styles
import useStyles from "./index.styles";

// lottie
import genericLottie from '../../../lottie-assets/spotlight/generic-animation.json'
import Lottie from "react-lottie-player";

// utils
import isEmpty from "../../../utils/isEmpty";

const CARD_TITLE_MAP = {
    "Duration": "DURATION",
    "SpeechRate": "SPEECH RATE",
    "KeyMatch": "KEY MATCH",
    "TranscriptMatch": "TRANSCRIPT MATCH",
    "Tone": "TONE",
    "Clarity": "CLARITY",
    "EyeContact": "EYE CONTACT",
    "ConfidenceIndex": "CONFIDENCE INDEX"
}

const SpotlightResultLoader = (props) => {
    const {
        data,
    } = props;
    const classes = useStyles();

    const isCriteriaContains = (criteria, key) => {
        if (isEmpty(criteria)) return false;
        let findObj = criteria.find((item) => (item.name?.toUpperCase() === key?.toUpperCase()));
        if (isEmpty(findObj)) return false;

        let isContains = !isEmpty(findObj) && (findObj?.check == 1);
        return isContains;
    }

    return (
        <div className={classes.lottieLoader}>
            {
                data.filter(elem => elem.check == 1).map((item, index) => {
                    return <div className={classes.card} key={index} style={{
                        position: 'relative'
                    }}>
                        <Lottie loop animationData={genericLottie} play />
                        <h6 className={classes.cardTitle}>{CARD_TITLE_MAP[item.name]}</h6>
                    </div>
                })
            }
        </div>
    )
}

export default SpotlightResultLoader;