import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// theme
import palette from '../../../../theme/palette';

// material UI : 
import CloseIcon from '@mui/icons-material/Close';

// styles
import useStyles from './index.styles';
import { Box, Drawer } from '@mui/material';

import { openToast } from '../../../../redux/common/common-slice';

// components : 
import SearchBar from '../search-bar';
import StatusSearch from '../status-search';
import CategorySearch from '../category-search';

// utils : 
import isEmpty from '../../../../utils/isEmpty';

// apis : 
import { getAllModulesAsync } from '../../../../redux/microskill/microskill.api';

const SearchPage = (props) => {

    const { handleSearchBarClick, isSearchPageVisible, moduleDataList, isPlaceholderVisible } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { selectedLanguage, selectedMicrokillCategories } = useSelector(state => state?.common);

    const classes = useStyles({ isDarkMode });
    const dispatch = useDispatch();

    // states : 
    const [searchInputValue, setSearchInputValue] = useState('');
    const [microskillDataList, setMicroskillDataList] = useState([]);
    const [learningJourneyDataList, setLearningJourneyDataList] = useState([]);
    const [formsDataList, setFormsDataList] = useState([]);
    const [isStatusSearchLoading, setIsStatusSearchLoading] = useState(true);
    const [isCategorySearchLoading, setIsCategorySearchLoading] = useState(true);

    // refs : 
    const debouncingTimerRef = useRef(null);

    const handleModuleDataAsync = async (search) => {

        setIsStatusSearchLoading(true);
        setIsCategorySearchLoading(true);

        let queryParam = `?startIndex=0&limit=200&sort=createdOn&sortDirection=desc`;
        queryParam += selectedLanguage !== "Default" ? `&languages=${selectedLanguage}` : '';
        queryParam += !isEmpty(selectedMicrokillCategories) ? `&categoryIds=${selectedMicrokillCategories.map(elem => elem.id).join(",")}` : '';
        queryParam += !isEmpty(search) ? `&search=${search}` : '';

        try {

            setIsStatusSearchLoading(true);
            setIsCategorySearchLoading(true);

            const moduleData = await getAllModulesAsync(queryParam);

            if (moduleData.status === 200 || moduleData.status === 201) {

                setIsStatusSearchLoading(true);
                setIsCategorySearchLoading(true);

                setMicroskillDataList(moduleData?.data?.data?.microskills);
                setLearningJourneyDataList(moduleData?.data?.data?.learnerJourney);
                setFormsDataList(moduleData?.data?.data?.forms);

                setIsStatusSearchLoading(false);
                setIsCategorySearchLoading(false);

            } else if (moduleData.status === 500) {
                dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }));

                setIsStatusSearchLoading(false);
                setIsCategorySearchLoading(false);
            }

        } catch (error) {
            console.error('Error fetching search module data:', error);

            setIsStatusSearchLoading(false);
            setIsCategorySearchLoading(false);
        }
    };

    const handleSearchBarText = (e) => {

        setSearchInputValue(e?.target?.value);
        clearTimeout(debouncingTimerRef.current);

        debouncingTimerRef.current = setTimeout(() => {
            // setIsSearchDataLoading(true);
            // setIsCategorySearchLoading(true); //

            handleModuleDataAsync(e?.target?.value);
            // setIsSearchDataLoading(false);
            // setIsCategorySearchLoading(false);
        }, 500);
    }

    useEffect(() => {
        handleModuleDataAsync("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component="nav">
            <Drawer
                className={classes.mobileDrawer}
                variant="temporary"
                open={true}
                onClose={''}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div className={classes.searchPageContainer}>

                    <div className={classes.searchPageHeaderWrapper}>

                        <div className={classes.searchPageHeader}>
                            <h4>All Results</h4>
                            <CloseIcon
                                color={isDarkMode ? palette.white : palette.orange}
                                style={{ height: '25px', width: '25px' }}
                                onClick={handleSearchBarClick} />
                        </div>

                        <SearchBar
                            handleSearchBarText={handleSearchBarText}
                            searchInputValue={searchInputValue}
                            isSearchPageVisible={isSearchPageVisible}
                            isPlaceholderVisible={isPlaceholderVisible}
                        />

                    </div>


                    {!(searchInputValue?.trim() !== '') ?
                        <StatusSearch
                            isStatusSearchLoading={isStatusSearchLoading}
                            pastSearches={moduleDataList?.data?.data?.pastSearches}
                            recentlyLaunched={moduleDataList?.data?.data?.recentlyLaunched}
                            recentlyLearned={moduleDataList?.data?.data?.recentlyLearned}
                            moduleDataList={moduleDataList}
                            handleSearchBarClick={handleSearchBarClick}
                        />
                        : <CategorySearch
                            isCategorySearchLoading={isCategorySearchLoading}
                            microskillDataList={microskillDataList}
                            learningJourneyDataList={learningJourneyDataList}
                            formsDataList={formsDataList}
                            handleSearchBarClick={handleSearchBarClick}
                            searchInputValue={searchInputValue}
                        />}
                </div>

            </Drawer>
        </Box>
    )
}

export default SearchPage;