import React, { useState, forwardRef, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

// mui
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { makeStyles } from "@mui/styles";

// utils 
import isEmpty from "../../utils/isEmpty";

// constants
import constants from "../../constants";
import palette from "../../theme/palette";

// styles
const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    videoPlayerBox: {
        position: 'relative',
        overflow: 'hidden',
        minWidth: '180px'
    },
    reactPlayerClass: {
        '& video': {
            objectFit: ({ objectFit }) => `${objectFit} !important`
        }
    },
    icon: {
        position: 'absolute',
        // transition: 'visibility 200ms linear, fill 200ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms !important'
        // left: '0'
    },
}))

const CustomReactPlayer = forwardRef((props, ref) => {
    const {
        mediaUrl,
        width = '100%',
        height = '100%',
        defaultPlaying = false,
        borderRadius = '8px',
        playsinline = true,
        onPlay = () => { },
        onPause = () => { },
        onMediaEnd = () => { },
        controls = false,
        isPlaying,
        videoStyle,
        customPlayButton = true,
        fullScreenBtn = true,
        iconColor = "",
        iconOpacity = "0.8",
        objectFit = "contain",
        muted = false,
        playerRef = null,
        showYtInfo = true,
        loop = false
    } = props;

    const classes = useStyles({ objectFit });
    const [isVideoPlaying, setIsVideoPlaying] = useState(defaultPlaying);
    const [isPauseButtonHide, setIsPauseButtonHide] = useState(false);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const videoPlayerRef = useRef()

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const handlePlay = () => {
        // console.log("abbb:", !isEmpty(isPlaying))
        setIsVideoPlaying(true);
        onPlay();
    }
    const handlePause = () => {
        setIsVideoPlaying(false);
        onPause();
    }
    const handlVideoStop = () => {
        if (loop) {
            handlePlay();
            return
        }
        setIsVideoPlaying(false);
        onPause();
        onMediaEnd();
    }

    const hanldeVideoPlayPause = () => {
        if (isVideoPlaying) handlePause()
        else handlePlay()
    }

    useEffect(() => {
        async function Func() {
            if ((isPlaying || isVideoPlaying) && !isPauseButtonHide) {
                setIsTimerRunning(true) // prevent multiple timer calls
                await sleep(1000 * 3) // hide after 3s
                setIsPauseButtonHide(true)
                setIsTimerRunning(false)
            }
        }
        Func();
    }, [isPlaying, isVideoPlaying, isPauseButtonHide])

    return (
        <div className={`${classes.videoPlayerBox} ${classes.centerFlex}`}
            style={{ width: width, height : height, borderRadius: borderRadius }}
            onClick={() => {
                if (isTimerRunning) return; // prevent multiple clicks while timer running
                setIsPauseButtonHide(false)
            }}
        >
            <ReactPlayer
                // light="http://placekitten.com/200/300" // thumbnail
                url={mediaUrl}
                playing={!isEmpty(isPlaying) ? isPlaying : isVideoPlaying}
                width={width}
                height={'100%'}
                onEnded={handlVideoStop}
                playsinline={playsinline}
                style={{ margin: "auto", ...videoStyle }}
                className={classes.reactPlayerClass}
                controls={controls}
                stopOnUnmount={true}
                muted={muted}
                config={{
                    youtube: {
                        playerVars: {
                            showinfo: showYtInfo ? 1 : 0,
                            fs: fullScreenBtn ? 1 : 0
                        }
                    }
                }}
                ref={!isEmpty(playerRef) ? playerRef : videoPlayerRef}
            // playIcon={<PlayCircleIcon />}
            />
            {
                customPlayButton && !(!isEmpty(isPlaying) ? isPlaying : isVideoPlaying) && (<PlayCircleIcon className={classes.icon} sx={{ fontSize: 80, opacity: iconOpacity, color: iconColor }} onClick={handlePlay} />)
            }
            {
                customPlayButton && (!isEmpty(isPlaying) ? isPlaying : isVideoPlaying) && (<PauseCircleIcon className={classes.icon} sx={{ fontSize: 80, opacity: iconOpacity, color: iconColor, visibility: isPauseButtonHide ? 'hidden' : 'visible' }} onClick={handlePause} />)
            }
        </div>
    )
})

export default CustomReactPlayer;