import React, { useMemo } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

// Styles
import useStyles from './index.styles';

// MUI Components
import { Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Rating, Slider, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

// mui-icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StarIcon from '@mui/icons-material/Star';
import isEmpty from '../../../utils/isEmpty';
import dayjs from 'dayjs';

const QUES_TYPE_ENUM = {
    SINGLE_CHOICE: "SINGLE_CHOICE_QUESTION",
    MULTI_SELECT: "MULTI_CHOICE_QUESTION",
    OPEN_ENDED: "OPEN_ENDED",
    NUM_RATE: "NUMBER_RATING",
    DATE: "DATE",
    STAR_RATE: "STAR_RATING",
    CUSTOM: "CUSTOM",
}

const AnswersContainer = (props) => {
    const { questionId, questionType, answers, options, handleQuestionAttemptData, attemptLevelData, handleSetAttemptLevelData } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    const marks = [
        {
            value: options.minLimit,
            label: options.minLimit
        },
        {
            value: options.maxLimit,
            label: options.maxLimit
        }
    ];

    const getCurrentQuestionData = () =>
        attemptLevelData.find(ques => ques.questionId === questionId);

    const getSelectedValue = () => {
        const currentQuestionData = getCurrentQuestionData();
        return !isEmpty(currentQuestionData?.answers) ? currentQuestionData.answers[0] : null;
    };

    const getMultiSelectCheckedValue = (answer) => {
        const currentQuestionData = getCurrentQuestionData();
        return currentQuestionData?.answers?.some(ans => ans.id === answer.id) || false;
    };

    const handleSingleChoice = (e, value) => {
        const answer = JSON.parse(value);

        // Update the attemptLevelData array
        const updatedAttemptLevelData = [...attemptLevelData].map(ques =>
            ques.questionId === questionId
                ? { ...ques, answers: [answer] }
                : ques
        );

        // Prepare the question data
        const questionData = [{
            id: questionId,
            type: questionType,
            options,
            answers: [answer],
        }];

        // Update the question attempt data
        handleQuestionAttemptData({ questions: questionData });

        // Update the overall attempt level data
        handleSetAttemptLevelData(updatedAttemptLevelData);
    };

    const handleMultiChoice = (e, answer) => {
        // Update the attemptLevelData array
        const updatedAttemptLevelData = [...attemptLevelData].map(ques => {
            // If this is not the current question, return it unchanged
            if (ques.questionId !== questionId) return ques;

            // For the current question, update the answers
            const updatedAnswers = ques.answers.some(ans => ans.id === answer.id)
                // If the answer is already present, remove it
                ? ques.answers.filter(ans => ans.id !== answer.id)
                // If the answer is not present, add it to the array
                : [...ques.answers, answer];

            // Return the updated question data
            return {
                ...ques,
                answers: updatedAnswers
            };
            // Sort the updated data by sequence
        })

        // Prepare the question data for the current question
        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            // Find the current question in the updated data and get its answers
            answers: updatedAttemptLevelData.find(ques => ques.questionId === questionId).answers,
        }];

        // Update the question attempt data
        handleQuestionAttemptData({ questions: questionData });

        // Update the overall attempt level data
        handleSetAttemptLevelData(updatedAttemptLevelData);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedHandle = useMemo(() => debounce((questionData) => {
        handleQuestionAttemptData({ questions: questionData });
    }, 1 * 1000), []);

    const handleOpenEnded = (e, questionId) => {
        const inputValue = e.target.value;

        // Update the attemptLevelData array
        const updatedAttemptLevelData = [...attemptLevelData].map(ques => {
            // If this is not the current question, return it unchanged
            if (ques.questionId !== questionId) return ques;

            return {
                ...ques,
                answers: !isEmpty(inputValue) ? [{ answer: inputValue }] : []
            };
        });

        // Prepare the question data
        const questionData = [{
            id: questionId,
            type: questionType,
            options,
            answers: updatedAttemptLevelData.find(ques => ques.questionId === questionId).answers,
        }];

        // Update the overall attempt level data
        handleSetAttemptLevelData(updatedAttemptLevelData)

        // Update the question attempt data after a little time with debounced handling
        debouncedHandle(questionData);
    };

    const handleRatingChange = (e, value) => {

        // Update the attemptLevelData array
        const updatedAttemptLevelData = [...attemptLevelData].map(ques => {
            // If this is not the current question, return it unchanged
            if (ques.questionId !== questionId) return ques;

            return {
                ...ques,
                answers: [{ answer: value.toString() }]
            };
        })

        // Prepare the question data for the current question
        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            // Find the current question in the updated data and get its answers
            answers: updatedAttemptLevelData.find(ques => ques.questionId === questionId).answers
        }];

        // Update the question attempt data
        handleQuestionAttemptData({ questions: questionData });

        // Update the overall attempt level data
        handleSetAttemptLevelData(updatedAttemptLevelData);
    }

    const handleDateChange = (e, value) => {

        // Update the attemptLevelData array
        const updatedAttemptLevelData = [...attemptLevelData].map(ques => {
            // If this is not the current question, return it unchanged
            if (ques.questionId !== questionId) return ques;

            return {
                ...ques,
                answers: [{ answer: moment(e.$d).format('DD-MM-YYYY') }]
            };
        })

        // Prepare the question data for the current question
        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            // Find the current question in the updated data and get its answers
            answers: updatedAttemptLevelData.find(ques => ques.questionId === questionId).answers
        }];

        // Update the question attempt data
        handleQuestionAttemptData({ questions: questionData });

        // Update the overall attempt level data
        handleSetAttemptLevelData(updatedAttemptLevelData);
    }

    const handleFileUpload = (e) => { }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <div className={`${classes.mainAnswerContainer}`}>
            {
                (questionType === QUES_TYPE_ENUM.SINGLE_CHOICE || questionType === QUES_TYPE_ENUM.MULTI_SELECT) && (
                    <FormControl sx={{ width: '100%' }}>
                        {questionType === QUES_TYPE_ENUM.MULTI_SELECT && answers.map((ans) => {
                            return <FormControlLabel
                                key={ans.id}
                                label={ans.answer}
                                control={<Checkbox style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }} />}
                                checked={getMultiSelectCheckedValue(ans)}
                                onChange={(e) => handleMultiChoice(e, ans)}
                                className={classes.formControlLabel}
                            />
                        })}

                        {questionType === QUES_TYPE_ENUM.SINGLE_CHOICE &&
                            <RadioGroup
                                value={isEmpty(getSelectedValue()) ? null : JSON.stringify(getSelectedValue())}
                                onChange={(e, value) => handleSingleChoice(e, value)}
                            >
                                {
                                    answers.map((ans) => {
                                        const ansId = ans.id;
                                        return <FormControlLabel
                                            key={ansId}
                                            value={JSON.stringify(ans)}
                                            label={ans.answer}
                                            control={<Radio style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }} />}
                                            className={classes.formControlLabel}
                                        />
                                    })
                                }
                            </RadioGroup>
                        }
                    </FormControl>
                )
            }

            {
                questionType === QUES_TYPE_ENUM.OPEN_ENDED &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop}`}>
                    <textarea
                        rows={1}
                        className={classes.openEnded}
                        placeholder={t('Enter your Answer')}
                        value={isEmpty(getSelectedValue()) ? '' : getSelectedValue().answer}
                        onChange={(e) => handleOpenEnded(e, questionId)} />
                </div>
            }

            {
                questionType === QUES_TYPE_ENUM.NUM_RATE &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop} ${classes.sliderComponent}`}>
                    <Slider size='medium' className={classes.numberRating} defaultValue={options.minLimit}
                        step={1}
                        min={options.minLimit}
                        max={options.maxLimit}
                        valueLabelDisplay='auto'
                        marks={marks}
                        value={isEmpty(getSelectedValue()) ? null : parseInt(getSelectedValue().answer)}
                        onChangeCommitted={(e, value) => handleRatingChange(e, value)}
                    />
                </div>
            }

            {
                questionType === QUES_TYPE_ENUM.DATE &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop}`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker className={classes.datePicker}
                                format='DD/MM/YYYY'
                                value={getSelectedValue() ? dayjs(JSON.stringify(getSelectedValue()), 'DD/MM/YYYY') : null}
                                onChange={(e, value) => handleDateChange(e, value)}
                                views={['year', 'month', 'day']}
                            />
                        </DemoContainer>
                    </LocalizationProvider></div>
            }

            {questionType === QUES_TYPE_ENUM.STAR_RATE &&
                <Rating
                    className={classes.starRating}
                    size='large'
                    value={isEmpty(getSelectedValue()) ? null : parseInt(getSelectedValue().answer)}
                    onChange={(e, value) => handleRatingChange(e, value)}
                    emptyIcon={
                        <StarIcon fontSize="inherit" className={classes.starIcon} />
                    }
                />
            }

            {
                questionType === QUES_TYPE_ENUM.CUSTOM &&
                <Button className={classes.uploadFileButton} component="label" variant="contained" disableElevation startIcon={<CloudUploadIcon />} onChange={(e) => handleFileUpload(e)}>
                    {t("Upload file")}
                    <VisuallyHiddenInput type="file" />
                </Button>
            }
        </div>
    )
};

export default AnswersContainer;