import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    scenarioRoot: {
        position: 'relative',
        // background: '#FFF',
        height: 'inherit',
        // aspectRatio: '9/16',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'grid',
        alignItems: 'center',
        fontSize: '1.3em',
        [theme.breakpoints.down('sm')]: {
            aspectRatio: 'auto',
        },
    },
    scenarioContainer: {
        height: 'inherit',
        display: 'grid',
        gridTemplateRows: ' 85% 15%',
        padding: '1em',
        // padding: '1.5em',
        position: 'relative',
        zIndex: 2,
        overflow: 'hidden',
    },
    scenarioBox: {
        display: 'grid',
        rowGap: '0.25em',
        gridTemplateRows: '10% 72% 16%',
        height: '100%',
        color: ({viewSampleRecording}) => viewSampleRecording ? '#FFF' : theme.palette.fontColor,
    },
    mainText: {
        fontSize: '2.5em',
        fontWeight: 700,
        fontFamily: 'montserrat',
    },
    desciptionText: {
        color: ({viewSampleRecording}) => viewSampleRecording ? '#FFF' : theme.palette.fontColor,
        height: '100%',
        overflowY: 'auto',
        fontSize: '1.2em',
        '& p': {
            fontFamily: 'Open Sans',
            lineHeight: '1.5em'
        }
    },
    btnContainer: {
        paddingTop: '0.5em',
        display: 'grid',
        // justifyItems: 'center',
        textAlign: 'center',
        alignItems: 'flex-start',
        gap: '0.5em',
    },

    animatedBtn: {
        animation: '$heartBeat 1s infinite linear',
    },
    "@keyframes heartBeat": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },

    skipButton: {
        color: ({viewSampleRecording}) => viewSampleRecording ? '#FFF !important' : `${theme.palette.fontColor} !important`,
        // fontSize: '0.6em'
    },
    closeTranscriptBtn: {
        textTransform: 'uppercase !important',
        fontWeight: '700 !important',
        borderWidth: '2px'
    },
    spotlightConfigDetails: {
        marginTop: '1em'
    },
    previewVideoBox: {
        position: 'absolute',
        inset: '0 0 0 0',
    },
    transcriptBox: {
        display: 'grid',
        gridTemplateRows: '85% 15%',
    },

    microskillContainerParent: {
        // [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            columnGap: '0.3em'
        // }
    },
    mcHeading: {
        width: '55%',
        fontSize: '2em',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: '90%',
            float: 'left',
            // fontSize: '2.5em'
        }
    },
    mcHeading2: {
        fontSize: '1em',
        textAlign: 'center',
        // [theme.breakpoints.down('md')]: {
        //     fontSize: (i18n) => i18n.language === 'en' ? '1em' : '0.8em'
        // }
    },
    skipButtonWrapper: {
        marginTop: '0.1em !important',
        fontSize: '1em !important',
        paddingLeft: `0 !important`,
        paddingRight: `0 !important`,
    }
}))

export default useStyles;