import React, { useEffect, useState } from 'react'

// mui
import LanguageIcon from '@mui/icons-material/Language';

// Styles : 
import useStyles from './index.styles'

import { useTranslation } from 'react-i18next'

// Utils : 
import isEmpty from '../../../utils/isEmpty';

// constants
import constants from '../../../constants';

// Components : 
import ImageContent from '../content/image-content';
import VideoContent from '../content/video-content';
import { useNavigate } from 'react-router';

const { BULLETINS_TYPE } = constants

const Bulletinbanner = ({ cardData, bulletinData }) => {

    const { type, id : bulletinId } = bulletinData

    const { i18n } = useTranslation();
    const styles = useStyles(i18n);
    const navigate = useNavigate();

    const [cardContent, setCardContent] = useState({})

    const contentMapping = () => {
        const result = { media: null, title: null, body: null };
        cardData.contents.forEach(item => {
            if (item.name === findMediaTypeFromCardType(cardData.cardType) || item.name === "YOUTUBE") {
                result.media = item;
                return
            }
            switch (item.name) {
                case 'TITLE':
                    result.title = item;
                    break;
                case 'BODY':
                    result.body = item;
                    break;
                default:
                    break;
            }
        });

        setCardContent(result)
    };

    useEffect(() => {
        if (isEmpty(cardData)) return
        contentMapping();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardData, type])

    function findMediaTypeFromCardType(cardType) {
        const mediaTypeMapping = {
            VTB: "VIDEO",
            ITB: "IMAGE",
            ATB: "AUDIO",
            LTB: "LINK"
        };

        return mediaTypeMapping[cardType] || "";
    }

    const handleBulletinBannerClick = () => {
        if (type === BULLETINS_TYPE.BULLETIN) {
            navigate(`/bulletins/${bulletinId}`);
            return
        }
        navigate({
            pathname: `/bulletins/${bulletinId}`,
            search: `?bulletInCardTypes=LTB`
        })
    }

    return (
        <>
            <div className={`${styles.cardContainer}`} onClick={handleBulletinBannerClick}>
                {
                    type === BULLETINS_TYPE.NEWS_BULLETIN && (
                        <div className={`${styles.linkIconWrapper} ${styles.centerFlex}`} >
                            <LanguageIcon className={styles.linkIcon} />
                        </div>
                    )
                }
                <div className={`${styles.cardGradient} ${styles.asessmentModel}`}>
                    {
                        (cardData?.cardType === 'LTB' && !isEmpty(cardContent.media)) && (
                            <ImageContent imageUrl={cardContent?.media?.thumbnail} />
                        )
                    }
                    {
                        (cardData?.cardType === 'ITB' && !isEmpty(cardContent.media)) && (
                            <ImageContent imageUrl={cardContent?.media?.content} />
                        )
                    }

                    {
                        (cardData?.cardType === 'VTB' && !isEmpty(cardContent.media)) && (
                            <VideoContent
                                videoUrl={cardContent?.media?.content}
                                autoPlay={true}
                                muted={true}
                                controls={false}
                                type={cardContent?.media?.name}
                                loop={true}
                                height="100%"
                                showYtInfo={false}
                                styling={{ objectFit: "cover" }}
                            />
                        )
                    }

                    {
                        (cardData?.cardType === 'ATB' && !isEmpty(cardContent.media)) && (
                            <img
                                src={!isEmpty(cardContent?.media?.thumbnail) ? cardContent?.media?.thumbnail : 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExM3Y4NmpjeWZkY3M1NWt6NW9ocGY5djk0cXMxOG03a2F3aDlkaGM3dyZlcD12MV9naWZzX3NlYXJjaCZjdD1n/X0iqiPHFdrEctzDKMU/giphy.gif'}
                                width="100%"
                                height="100%"
                                alt="audio"
                            />
                        )
                    }
                    {/* {
                        (cardData?.cardType === 'ATB' && !isEmpty(cardContent.media)) && (
                            <img
                                src={!isEmpty(cardContent?.media?.thumbnail) ? cardContent?.media?.thumbnail : 'https://www.clipartbest.com/cliparts/9ip/XLz/9ipXLzAiE.gif'}
                                width="100%"
                                height="100%"
                                alt="audio"
                            />
                        )
                    } */}

                </div>

                <div className={`${styles.centerFlex} ${styles.nameContainer}`}>
                    <div className={`${styles.centerFlex} ${styles.headingConatiner}`}>

                        {
                            !isEmpty(cardContent?.title) && (
                                <h2 className={styles.microSkillName}>
                                    <div dangerouslySetInnerHTML={{ __html: cardContent.title.content }}></div>
                                </h2>
                            )
                        }

                        {
                            !isEmpty(cardContent?.body) && (
                                <h4 className={styles.aboutText}>
                                    <div dangerouslySetInnerHTML={{ __html: cardContent.body.content }}></div>
                                </h4>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Bulletinbanner;