import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// styles
import useStyles from './index.styles';
import { Box, Button, CircularProgress } from '@mui/material';

// utils :
import isEmpty from '../../../../utils/isEmpty';

// lotties : 
import Lottie from 'react-lottie-player';
import NO_DATA_FOUND from '../../../../lottie-assets/common/no-data-found.json';

//redux 
import { setFormIdData, setIsFormOpen } from "../../../../redux/form/form-slice";
import theme from '../../../../theme';

export const SEARCHED_DATA = {
    MICROSKILLS: {
        ID: 'microskillId',
        NAME: 'name'
    },
    LEARNING_JOURNEYS: {
        ID: 'journeyId',
        NAME: 'journeyName'
    },
    FORMS: {
        ID: 'id',
        NAME: 'name'
    }
};

const ID_ENUM = [
    {
        findingKey: "microskillId",
        typeOfObj: "MICROSKILLS"
    },
    {
        findingKey: "journeyId",
        typeOfObj: "LEARNING_JOURNEYS"
    },
    {
        findingKey: "formId",
        typeOfObj: "FORMS"
    }
]

const StatusSearch = (props) => {

    const {
        isStatusSearchLoading,
        pastSearches,
        recentlyLaunched,
        recentlyLearned,
        handleSearchBarClick,
        setOpenCategoryFilter } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { isMobile, isTablet } = useSelector(state => state?.common);

    const classes = useStyles({ isDarkMode });

    // redux : 
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // states : 

    // refs : 
    const lottieRef = useRef(null);

    const handleRecentlyLaunchedClick = (currentItem, type) => {

        if (type === 'MICROSKILLS') {
            const microskillId = currentItem?.microskillId;
            navigate(`/challenge-list/${microskillId}`);
            return;
        }

        if (type === 'LEARNING_JOURNEYS') {
            const journeyId = currentItem?.journeyId;
            navigate(`/learning-journey/${journeyId}`);
            return;
        }

        if (type === 'FORMS') {
            handleSearchBarClick();


            if (!isMobile || !isTablet) {
                setOpenCategoryFilter(false);
            }

            const currentFormId = currentItem?.formId;
            dispatch(setFormIdData(currentFormId));
            dispatch(setIsFormOpen(true));
            return;
        }
    }

    const handlePastSearchesClick = (currentItem) => {
        const type = currentItem.type;

        if (type === 'MICROSKILL') {
            const microskillId = currentItem?.microskillId;
            navigate(`/challenge-list/${microskillId}`);
            return;
        }

        if (type === 'JOURNEY') {
            const journeyId = currentItem?.journeyId;
            navigate(`/learning-journey/${journeyId}`);
            return;
        }

        if (type === 'FORM') {
            handleSearchBarClick();

            if (!isMobile || !isTablet) {
                setOpenCategoryFilter(false);
            }

            const currentFormId = currentItem?.formId;
            dispatch(setFormIdData(currentFormId));
            dispatch(setIsFormOpen(true));
            return;

        }
    }




    return (
        <Box component="nav">

            <div className={classes.searchPageContainer}>

                {!isStatusSearchLoading ? <div className={classes.searchesContainer}>
                    <>
                        {(pastSearches?.length > 0 || recentlyLaunched?.length > 0 || recentlyLearned?.length > 0) ? <>

                            {!isEmpty(pastSearches) ? <section className={classes.statusWiseSearchesContainer}>

                                <h4 className={classes.searchesHeaderText}>Past Searches</h4>

                                <ul className={classes.listWrapper}>
                                    {pastSearches?.map((item, index) => (
                                        <Button
                                            variant="text"
                                            className={classes.navigationText}
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                borderRadius: '0',
                                                [theme.breakpoints.up('md')]: {
                                                    padding: '0 2rem',
                                                    cursor: 'pointer',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                    color: `${theme.palette.fontColor}`,
                                                },
                                            }}
                                            onClick={() => handlePastSearchesClick(item)}
                                        >
                                            <p className={classes.searchedText}>
                                                {item?.name}
                                            </p>
                                            <div className={`${classes.navigationBtn} ${classes.centerFlex}`}>
                                                <img src="/images/icons/left_arrow.svg" alt="icon" width={15} />
                                            </div>
                                        </Button>
                                    ))}
                                </ul>
                            </section> : (<></>)}

                            {!isEmpty(recentlyLaunched) ? (
                                <section className={classes.statusWiseSearchesContainer}>
                                    <h4 className={classes.searchesHeaderText}>Recently Launched</h4>
                                    <ul className={classes.listWrapper}>
                                        {recentlyLaunched.map((item, index) => {
                                            const keyArray = Object.keys(item);
                                            const foundType = ID_ENUM.find((key) => keyArray.includes(key.findingKey));

                                            return (
                                                <Button
                                                    variant="text"
                                                    className={classes.navigationText}
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        borderRadius: '0',
                                                        justifyContent: 'space-between',
                                                        [theme.breakpoints.up('md')]: {
                                                            padding: '0 2rem',
                                                            cursor: 'pointer',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                            color: `${theme.palette.fontColor}`,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleRecentlyLaunchedClick(item, foundType?.typeOfObj);
                                                    }}
                                                >
                                                    <p className={classes.searchedText}>
                                                        {(foundType?.typeOfObj === 'MICROSKILLS' && item?.Microskill?.name) && item?.Microskill?.name}
                                                        {(foundType?.typeOfObj === 'LEARNING_JOURNEYS' && item?.journeyName) && item?.journeyName}
                                                        {(foundType?.typeOfObj === 'FORMS' && item?.Form?.name) && item?.Form?.name}
                                                    </p>
                                                    <div className={`${classes.navigationBtn} ${classes.centerFlex}`}>
                                                        <img src="/images/icons/left_arrow.svg" alt="icon" width={15} />
                                                    </div>
                                                </Button>
                                            );
                                        })}
                                    </ul>
                                </section>
                            ) : (
                                <></>
                            )}


                            {!isEmpty(recentlyLearned) ? (
                                <section className={classes.statusWiseSearchesContainer}>
                                    <h4 className={classes.searchesHeaderText}>Recently Learned</h4>
                                    <ul className={classes.listWrapper}>
                                        {recentlyLearned?.map((item, index) => {
                                            const keyArray = Object.keys(item);
                                            const foundType = ID_ENUM.find((key) => keyArray.includes(key.findingKey));

                                            return (
                                                <Button
                                                    variant="text"
                                                    className={classes.navigationText}
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        borderRadius: '0',
                                                        justifyContent: 'space-between',
                                                        [theme.breakpoints.up('md')]: {
                                                            padding: '0 2rem',
                                                            cursor: 'pointer',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                            color: `${theme.palette.fontColor}`,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleRecentlyLaunchedClick(item, foundType?.typeOfObj);
                                                    }}
                                                >
                                                    <p className={classes.searchedText}>
                                                        {(foundType?.typeOfObj === 'MICROSKILLS' && item?.Microskill?.name) && item?.Microskill?.name}
                                                        {(foundType?.typeOfObj === 'LEARNING_JOURNEYS' && item?.journeyName) && item?.journeyName}
                                                        {(foundType?.typeOfObj === 'FORMS' && item?.Form?.name) && item?.Form?.name}
                                                    </p>
                                                    <div className={`${classes.navigationBtn} ${classes.centerFlex}`}>
                                                        <img src="/images/icons/left_arrow.svg" alt="icon" width={15} />
                                                    </div>
                                                </Button>
                                            );
                                        })}
                                    </ul>
                                </section>
                            ) : (
                                <></>
                            )}

                        </> : <div className={classes.noDataFound}>
                            <Lottie
                                ref={lottieRef}
                                animationData={NO_DATA_FOUND}
                                style={{ width: '100%', height: '100%' }}
                                loop={true}
                                play={true}
                                onComplete={() => console.log('Animation completed')}
                            />
                        </div>}
                    </>

                </div> :

                    (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )

                }


            </div>

        </Box>
    )
}

export default StatusSearch;