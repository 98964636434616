import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

// styles 
import useStyles from "./index.styles";

// mui
import { Button, CircularProgress } from "@mui/material";
import isEmpty from "../../../utils/isEmpty";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate, useParams } from "react-router";
import { postBulletinEndAttempt } from "../../../redux/microskill/microskill.api";


const NavigationHeader = (props) => {

    const { navigationData, selectedDate, handleBulletinHeaderClick, handleBackButtonClick } = props;
    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });
    const { isMobile, isTablet } = useSelector(state => state?.common);

    const navigate = useNavigate();

    const containerRef = useRef(null);

    useEffect(() => {
        // Get the index of the clicked button
        const buttonIndex = navigationData.findIndex(day => day.date === selectedDate);

        // Scroll to the button if it exists
        if (containerRef.current && buttonIndex >= 0) {
            const buttonElement = containerRef.current.children[buttonIndex];
            if (buttonElement) {
                // Get the width of the button
                const buttonWidth = buttonElement.getBoundingClientRect().width;

                // Get the half of the container's width
                const containerWidth = containerRef.current.getBoundingClientRect().width;
                const halfContainerWidth = containerWidth / 2;

                // Calculate the scroll position to center the clicked button
                const scrollToPosition = buttonIndex * buttonWidth - (halfContainerWidth - (buttonWidth / 2));

                // Scroll to the calculated position
                containerRef.current.scrollTo({
                    left: scrollToPosition,
                    behavior: 'smooth',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (

        <>
            {/* Mobile & Tablet View  */}
            {(isMobile || isTablet) && <div
                className={`${classes.bulletinHeaderWrapper} scrollbar-hidden`}
                ref={containerRef}

                sx={(theme) => ({
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.up("md")]: {
                        overflowY: 'scroll',
                    },
                })}
            >

                {(!isMobile && !isTablet) && <div className={`${classes.card} ${classes.backBtn}`} onClick={handleBackButtonClick}>
                    <Button variant="outlined"
                        fullWidth
                        sx={theme => ({
                            border: 1.5,
                            [theme.breakpoints.up("md")]: {
                                background: `${theme.palette.mobileHeaderBg} !important`,
                            }
                        })}>
                        <KeyboardArrowLeftIcon />
                        <span className={classes.backBtnText}>Back</span>
                    </Button>
                </div>
                }


                {
                    isEmpty(navigationData) ?

                        <div className={classes.centerFlex} style={{ justifyContent: 'center', height: '100%', width: '100%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {navigationData?.map((item) => (

                                <Button
                                    className={`${selectedDate === item?.date ? classes.timeContainerClicked : classes.timeContainer}`}
                                    sx={theme => ({
                                        whiteSpace: 'nowrap',
                                        boxShadow: 'none !important',
                                        borderRadius: '50% !important',
                                        minWidth: 'fit-content',
                                        aspectRatio: `1/1`,
                                        padding: '1rem',
                                        cursor: 'pointer',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
                                            boxShadow: 'none !important',
                                        },

                                        [theme.breakpoints.up("md")]: {
                                            flexDirection: "row",
                                            gap: '1rem',
                                            borderRadius: '0.2rem !important',
                                            width: '100%',
                                            aspectRatio: `1`,
                                            padding: '1rem',
                                            cursor: 'pointer',
                                            height: '10%',
                                        },
                                    })}
                                    key={item.id}
                                    onClick={() => handleBulletinHeaderClick(item)}
                                >
                                    <span className={classes.dateContainer}>
                                        {item?.date}
                                    </span>
                                    <span className={classes.dayContainer}>
                                        {item?.day}
                                    </span>
                                </Button>
                            ))}
                        </>
                }

            </div>}

            {/* Desktop View  */}
            {(!isMobile && !isTablet) && <div
                className={`${classes.bulletinHeaderWrapper} scrollbar-hidden`}
                ref={containerRef}

                sx={(theme) => ({
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.up("md")]: {
                        overflowY: 'scroll',
                    },
                })}
            >

                {(!isMobile && !isTablet) && <div className={`${classes.card} ${classes.backBtn}`} onClick={handleBackButtonClick}>
                    <Button variant="outlined"
                        fullWidth
                        sx={theme => ({
                            border: 1.5,
                            [theme.breakpoints.up("md")]: {
                                background: `${theme.palette.mobileHeaderBg} !important`,
                            }
                        })}>
                        <KeyboardArrowLeftIcon />
                        <span className={classes.backBtnText}>Back</span>
                    </Button>
                </div>
                }


                <div className={`${classes.dateWrapper} scrollbar-hidden`}>
                    {
                        isEmpty(navigationData) ?

                            <div className={classes.centerFlex} style={{ justifyContent: 'center', height: '100%', width: '100%' }}>
                                <CircularProgress />
                            </div>
                            :
                            <>
                                {navigationData?.map((item) => (

                                    <Button
                                        className={`${selectedDate === item?.date ? classes.timeContainerClicked : classes.timeContainer}`}
                                        sx={theme => ({
                                            whiteSpace: 'nowrap',
                                            boxShadow: 'none !important',
                                            borderRadius: '50% !important',
                                            minWidth: 'fit-content',
                                            aspectRatio: `1/1`,
                                            padding: '1rem',
                                            cursor: 'pointer',
                                            height: '100%',
                                            "&:hover": {
                                                backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
                                                boxShadow: 'none !important',
                                            },

                                            [theme.breakpoints.up("md")]: {
                                                flexDirection: "row",
                                                gap: '1rem',
                                                borderRadius: '0.2rem !important',
                                                width: '100%',
                                                aspectRatio: `1`,
                                                padding: '1rem',
                                                cursor: 'pointer',
                                                height: '10%',
                                            },
                                        })}
                                        key={item.id}
                                        onClick={() => handleBulletinHeaderClick(item)}
                                    >
                                        <span className={classes.dateContainer}>
                                            {item?.date}
                                        </span>
                                        <span className={classes.dayContainer}>
                                            {item?.day}
                                        </span>
                                    </Button>
                                ))}
                            </>
                    }

                </div>
            </div>
            }

        </>
    );
};

export default NavigationHeader;




