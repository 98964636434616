import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    laWinScreenRoot: {
        height: '100%',
        width: '100%',
        userSelect: 'none',
        position: 'relative',
        '@media(max-width: 300px)': {
            fontSize: '12px'
        },
    },
    laWinScreenBox: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.ilaCorrect1,
        color: theme.palette.white,
        borderRadius: '0.5rem',
        display: 'grid',
        padding: '1rem 2rem 0rem',
        justifyContent: 'center',
        textAlign: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            padding: '1rem',
            borderRadius: '0rem',
        }
    },
    completeTxt: {
        fontWeight: 700,
        letterSpacing: '7px',
        textDecoration: 'underline',
        fontSize: '1.2rem',
        marginTop: '2rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1em',
            fontWeight: 600,
            letterSpacing: '3px',
        }
    },
    congratsText: {
        fontWeight: 900,
        fontSize: '2.5rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.8em',
        }
    },
    subText: {
        fontFamily: 'Open Sans',
        fontSize: '1.35rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '0.9em',
        }
    },
    detailsBox: {
        gap: '3rem',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down("md")]: {
            gap: '2rem',
        }
    },
    detailText: {
        fontWeight: 900,
        fontSize: '2.4rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.6em',
        }
    },
    iconBox: {
        gap: '1rem'
    },
    challengeBtnText: {
        width: '90%',
        transform: 'scale(1) ',
        lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
        fontWeight: '700 !important',
    },
    actionButton: {
        fontSize: '2rem',
        fontWeight: 900,
        textTransform: 'capitalize !important',
        fontFamily: 'MotionControl',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.6em',
            fontWeight: 700,
        }
    },

    // audioPlayer
    audioPlayerContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1
    },

    // confetti lottie animation 
    lottieContainer: {
        inset: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    },

    ilaWinScreenBtnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
    },

    ilaInnerBtnContainer: {
        // width: '100%',
        // display: 'grid',
        // gap: '1em',
        width: '100%',
    }
}))

export default useStyles