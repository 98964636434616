import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    root: {
        width: '100%',
        height: '100%'
    },

    loadingForm: {
        height: '100%',
        width: '100%'
    },

    paperProps: {

    },

    formContainer: {
        height: '100%',
        display: 'grid',
        [theme.breakpoints.down('md')]: {
            gridTemplateRows: '1fr 7fr 1fr'
        },
    },


    formSubmitContainer: {
        padding: '0rem 1rem',
        // boxShadow: '0 0 10px grey'
        [theme.breakpoints.up('md')]: {
            padding: '0.5rem 1rem'
        }
    },

    formSubmitButton: {
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
        color: 'white !important'
    },
}))

export default useStyles