import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// mui
import { Button, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import VideocamIcon from '@mui/icons-material/Videocam';

// components
import CustomReactPlayer from "../../custom-react-player";

// redux
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight-slice";

// utils 
import isEmpty from "../../../utils/isEmpty";

// constants

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";


// styled components
const MicroskillContainer = styled('div')(({ viewSampleRecording, theme }) => ({
    border: viewSampleRecording ? '2px solid #FFF' : `2px solid ${theme.palette.fontColor}`,
    borderRadius: '10px',
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    flexDirection: 'column',
    padding: '0.5rem 0.3rem',
    fontSize: '0.8em',

    // center flex
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Scenario = (props) => {
    const { screenStateList, spotlightData,
        isStartrecording, setIsStartrecording,
        viewSampleRecording, setViewSampleRecording,
        viewTranscript, setViewTranscript,
        totalAttempts } = props;
    // console.log("spotlightData:", spotlightData);
    const classes = useStyles({ viewSampleRecording });
    const { t } = useTranslation()
    const dispatch = useDispatch();
    // const [viewSampleRecording, setViewSampleRecording] = useState(!isEmpty(spotlightData.mediaPath));
    // const [viewTranscript, setViewTranscript] = useState(spotlightData.isTranscript);
    // const [isStartrecording, setIsStartrecording] = useState(false);

    const [displayTranscriptScreen, setDisplayTranscriptScreen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    //media path -> view sample recording
    //transcript -> view transcript
    //start recording -> start recording

    const handleSkipClick = () => {
        if (!isStartrecording) {
            setViewSampleRecording(false)
            setIsStartrecording(true);
        }
        else {
            // setScreenState(screenStateList.INSTRUCTION);
            dispatch(handleSpotlightScreenState(screenStateList.INSTRUCTION));
        }
    };
    useEffect(() => {
        if (!isEmpty(spotlightData)) {
            if ((spotlightData.maxAttempts - totalAttempts) <= 0) {
                // console.log("button needs to be disabled")
                setIsButtonDisabled(true)
            }
        }
    }, [spotlightData, totalAttempts])

    const handleContainedBtnClick = () => {
        if (isButtonDisabled) return;

        if (viewSampleRecording) { // view sample recording
            // setScreenState(screenStateList.DEMO_VIDEO);
            console.log("1");
            dispatch(handleSpotlightScreenState(screenStateList.DEMO_VIDEO));
            if (spotlightData.isTranscript) {
                setViewTranscript(true)
            }
        }
        else if (viewTranscript) { // view transcript
            console.log("2");
            setDisplayTranscriptScreen(true);
        }
        else if (isStartrecording) { // start recording
            console.log("3");
            // setScreenState(screenStateList.RECORD);
            dispatch(handleSpotlightScreenState(screenStateList.RECORD));
        }
        else {
            console.log("4");
            // setScreenState(screenStateList.DEMO_VIDEO);
            dispatch(handleSpotlightScreenState(screenStateList.DEMO_VIDEO));
        }

    }
    const handleCloseTranscript = () => {
        // console.log("close");
        setViewTranscript(false);
        setDisplayTranscriptScreen(false);
        setViewSampleRecording(false);
        setIsStartrecording(true);
    }

    const getBtnDetails = () => {
        if (viewSampleRecording) return { text: 'View Sample Recording', icon: <VideocamIcon sx={{ fontSize: 20 }} /> }
        else if (viewTranscript) return { text: 'View Transcript', icon: <MenuIcon sx={{ fontSize: 20 }} /> }
        else return { text: 'Start Recording', icon: <VideocamIcon sx={{ fontSize: 20 }} /> }
    }

    return (
        <div className={classes.scenarioRoot}>
            {
                viewSampleRecording && (
                    <div className={`${classes.previewVideoBox} ${classes.centerFlex}`}
                        style={{
                            filter: 'blur(15px) brightness(0.8)',
                        }}
                    >
                        <CustomReactPlayer
                            mediaUrl={spotlightData.mediaPath}
                            borderRadius={'0px'}
                            defaultPlaying={true}
                            customPlayButton={false}
                            objectFit={"cover"}
                            muted={true}
                        // onPause={handleOnPause}
                        // onPlay={handleOnPlay}
                        // isPlaying={isVideoPlaying}  
                        />
                    </div>
                )
            }
            <div className={classes.scenarioContainer} style={{
                backgroundColor: viewSampleRecording ? `rgba(0, 0, 0, 0.4)` : ""
            }}>
                {
                    !displayTranscriptScreen ? (
                        <div className={classes.scenarioBox}>
                            <div className={classes.mainText}>{t("Scenario")}:</div>
                            <div className={`${classes.desciptionText} scrollbar`}>
                                <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: spotlightData?.scenario }}></p>

                            </div>

                            <div className={classes.microskillContainerParent}>
                                <MicroskillContainer viewSampleRecording={viewSampleRecording}>
                                    <div className={classes.mcHeading2}>{t("Maximum Attempts")}</div>
                                    <h2 className={classes.mcHeading}>{spotlightData?.maxAttempts}</h2>

                                </MicroskillContainer>
                                {/* Microskills Completed */}
                                <MicroskillContainer viewSampleRecording={viewSampleRecording}>
                                    <div className={classes.mcHeading2} style={{ wordBreak: 'break-word' }}>{t("Remaining Attempts")}</div>
                                    <h2 className={classes.mcHeading}>{spotlightData?.maxAttempts - totalAttempts}</h2>
                                </MicroskillContainer>
                                <MicroskillContainer viewSampleRecording={viewSampleRecording}>
                                    <div className={classes.mcHeading2} style={{ wordBreak: 'break-word' }}>{t("Suggested Duration")}</div>
                                    <h2 className={classes.mcHeading}>{spotlightData?.timeLimit}<span style={{ fontSize: '0.5em' }}>s</span></h2>
                                </MicroskillContainer>
                            </div>
                        </div>
                    ) : (
                        <div className={`${classes.desciptionText} scrollbar`}>
                            <p dangerouslySetInnerHTML={{ __html: spotlightData.formatted }}></p>
                        </div>
                    )
                }

                <div className={classes.btnContainer}>
                    {
                        !displayTranscriptScreen && (
                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={theme => ({
                                    borderRadius: '4px',
                                    fontSize: '1em',
                                    fontWeight: 600,
                                    padding: 1,
                                    "&.Mui-disabled": {
                                        backgroundColor: `${theme.palette.fontColor}8A`
                                    }
                                })}
                                disabled={isButtonDisabled}
                                startIcon={getBtnDetails().icon}
                                onClick={handleContainedBtnClick}
                                className={`fixed_ratio_20_3 ${getBtnDetails().text === 'Start Recording' && !isButtonDisabled ? classes.animatedBtn : ""}`}
                            >
                                {t(getBtnDetails().text)}
                            </Button>
                        )
                    }
                    {
                        displayTranscriptScreen && (
                            <Button
                                variant="outlined"
                                fullWidth
                                size="large"
                                sx={theme => ({ borderRadius: 1, color: theme.palette.whiteToOrange })}
                                className={`fixed_ratio_20_3 ${classes.closeTranscriptBtn}`}
                                // startIcon={!viewTranscript ? <MenuIcon size="large" /> : <VideocamIcon size="large" />}
                                onClick={handleCloseTranscript}
                            >
                                {t('Close Transcript')}
                            </Button>
                        )
                    }
                    {
                        !viewTranscript && !displayTranscriptScreen && (
                            <Button size="large" disableRipple onClick={handleSkipClick} className={`fixed_ratio_20_3 ${classes.skipButtonWrapper}`}>
                                <u className={classes.skipButton}>
                                    {!isStartrecording ? t('SKIP') : t('4 things to note before you begin')}
                                </u>
                            </Button>
                        )
                    }
                </div>
            </div>

        </div >
    )
}

export default Scenario;