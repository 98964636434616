import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Components
import { AccessAlarm } from '@mui/icons-material';

// Styles
import useStyles from "./index.styles";

const FormInfoContainer = forwardRef((props, ref) => {
    const { formName, formDescription, isAssessment, handleCloseForm, totalTime } = props;

    const { i18n } = useTranslation();
    const classes = useStyles(i18n);

    return (
        <div className={`${classes.mainContainer}`}>
            <div className={`${classes.centerFlex} ${classes.iconContainer}`}>
                {
                    isAssessment ? (
                        <div className={`${classes.timerContainer} ${classes.centerFlex}`}>
                            <AccessAlarm style={{ color: '#F4511E', margin: 'auto' }} />
                            <div className={classes.timerDiv}>
                                <span style={{ fontSize: '1.2rem' }} ref={ref}>{totalTime}</span>
                                <span style={{ fontSize: '1.2rem' }}>s</span>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.closeIcon}>
                            <img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />
                        </div>
                    )
                }
            </div>
            <div className={`${classes.centerFlex} ${classes.formNameDescriptionContainer}`}>
                <div className={classes.formName}>{formName}</div>

                <div className={classes.formDescription}>{formDescription}</div>
            </div>
        </div>
    );
});

export default FormInfoContainer;