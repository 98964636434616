import React, { useEffect } from "react";
import { t } from "i18next";

// MUI
import { Button, CircularProgress } from "@mui/material";

// Utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil";

// Styles
import useStyles from "./index.styles";

// lotties
import timeupFormSubmissionLottieData from "../../../lottie-assets/timeout_forms.json";
import Lottie from "react-lottie-player";


const FormsLooseScreen = (props) => {

    const { name, formData, attemptData, handleCloseForm, handleTryAgain, isTimeUp } = props;

    const [loading, setLoading] = React.useState(false);

    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(formData) && !isEmpty(attemptData)) {
            setLoading(false);
        }
    }, [formData, attemptData])
    return (
        <>
            {
                loading ?
                    (<div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                        <CircularProgress />
                    </div>) :
                    (
                        <div className={classes.mainContainer}>
                            <div className={classes.infoContainer} style={{ width: "100%" }}>
                                <div className={`${classes.closeIconContainer} ${classes.centerFlex}`}>
                                    <img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />)
                                </div>

                                <div className={classes.formName} style={{ padding: "1rem" }}>{name}</div>

                                <div className={`${classes.thanksContainer} ${classes.centerFlex}`} style={{ padding: "1rem" }}>
                                    {
                                        isTimeUp ? <div className={classes.centerFlex}>
                                            <Lottie
                                                animationData={timeupFormSubmissionLottieData}
                                                loop={false}
                                                play={true}
                                                style={{ width: '40%' }}
                                            />
                                        </div> : <div>
                                            <img src='/images/icons/form-cross.png' alt="form-close" className={classes.imgIcon} />
                                        </div>
                                    }
                                    <div className={classes.tryAgainText}>
                                        <div className={classes.greetingText}>{isTimeUp ? "TIME's UP" : "TRY AGAIN"}</div>
                                        <div className={classes.usernameText}>
                                            {commonUtils.getUserName() === null || commonUtils.getUserName() === undefined || commonUtils.getUserName() === '' ? commonUtils.getLoginUserName() : `${commonUtils.getUserName()} (${commonUtils.getLoginUserName()})`}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.msgText}>
                                    {t("You did not pass the assessment.")}
                                </div>
                            </div>


                            <div className={`${classes.container} ${classes.scoringContainer}`}>
                                {/* {showResult && (
                                    <div className={classes.sectionsWrapper}>
                                        {Object.keys(contextWiseAccuracy).map((contextId, index) => {
                                            return (
                                                <div key={index} className={classes.container}>
                                                    <div className={classes.sectionText}>Section {index + 1}:</div>
                                                    <div className={classes.sectionValue}>{contextWiseAccuracy[contextId]}%</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )} */}
                                {/* {showResult && (
                                    <div className={`${classes.centerFlex} ${classes.totalScoreContainer}`}>
                                        <div className={classes.scoreText}>{t("TOTAL SCORE")}:</div>
                                        <div className={classes.scoreValue}>{score} / {maxScore}</div>
                                    </div>
                                )} */}
                            </div>

                            <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
                                <Button
                                    variant="contained"
                                    className={classes.exitButton}
                                    onClick={() => handleTryAgain()}
                                >Try Again</Button>
                                <Button
                                    variant="contained"
                                    className={classes.exitButton}
                                    onClick={() => handleCloseForm()}
                                >Exit</Button>
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default FormsLooseScreen;