import { makeStyles } from "@mui/styles";
import constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    langListUi: {
        // background: '#fff',
        // fontColor: `${theme.palette.fontColor}`,

        backgroundColor: theme.palette.headerBg,
        [theme.breakpoints.down('md')]: {
            height: '100vh',
            width: '100vw',
        }
    },
    listBox: {
        padding: theme.spacing(0, 1),
        height: constants.HEADER_HEIGHT_MOBILE,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    langListBox: {
        padding: theme.spacing(0, 1),
        justifyContent: 'space-between !important',
        [theme.breakpoints.down('md')]: {
            minHeight: '3rem !important',
        }
    },
    menuText: {
        // color: '#000 !important',
        fontSize: '1rem !important',
        color: `${theme.palette.fontColor} !important`,
        [theme.breakpoints.up("md")]: {
            color: '#000 !important',
        }

    },
    selectAppLanguage: {
        color: `${theme.palette.fontColor} !important`,
        marginBottom: '0rem',
        fontSize: '1.2rem',
        fontWeight: '600',
        [theme.breakpoints.up("md")]: {
            color: '#000 !important',
        }
    }
}
))

export default useStyles;