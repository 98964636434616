import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    audioContentWrapper: {
        width: '95vw',
        position: 'relative',
        height: '100%',

        [theme.breakpoints.up("md")]: {
            width: '100%'
        }

    },
    waveformStyle: {
        position: 'relative',
        width: '100%',
        height: 'fit-content',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    audioPlayerBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
        cursor: 'pointer',
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#fff',
        height: '100%',
        zIndex: '9',
    },
}));

export default useStyles;
