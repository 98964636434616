import axiosInstance from "../../utils/axios";

export const fetchForm = async () => {
    try {
        const respData = await axiosInstance.get(`/forms`);
        return respData;
    } catch (err) {
        console.log("fetchForm:", err)
        // dispatch(fetchFail(err));
    }
}

export const fetchFormData = async (formId = '') => {
    try {
        const respData = await axiosInstance.get(`/forms/${formId}`);
        return respData;
    } catch (err) {
        return err
        console.log("fetchFormData :", err)
        // dispatch(fetchFail(err));
    }
}

export const startFormAttempt = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/forms/start-form-attempt`, payload);
        return respData;
    }
    catch (err) {
        console.log("Attempt Form :", err)
    }
}

export const submitFormAttemptData = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/forms/form-attempt-data`, payload);
        return respData;
    } catch (err) {
        console.log("submitFormAttemptData:", err)
        // dispatch(fetchFail(err));
    }
}

export const submitEndFormAttempt = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/forms/end-form-attempt`, payload);
        return respData;
    } catch (err) {
        console.log("submitEndFormAttempt:", err)
        // dispatch(fetchFail(err));
    }
}