import IframeComponent from "../../../iframe";
import PDFviewerSafari from "../../../pdf-viewer-safari";

const LinkContent = ({url}) => {

    const isPdf = url.endsWith('.pdf');
    const iFrameStyles = {
        border: 'none',
    }

    return (isPdf 
        ? <PDFviewerSafari
            docData={{ path: url, enableFileDownload: false, enableFileShare: false }} 
            from={"GENERIC_DOC_VIEWER"} 
        /> 
        : <IframeComponent src={url} style={iFrameStyles} />)
};

export default LinkContent;