import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    loginContainer: {
        backgroundColor: palette.bgThemeColor,
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
    },
    loginBox: {
        height: '80%',
        width: '88%',
        flexWrap: 'wrap',
        borderRadius: '5px',
        boxShadow: '0 22px 16px 0 rgb(0 0 0 / 23%)',
        overflow: 'hidden',
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    side1: {
        flex: '1 1 55%',
        backgroundImage: palette.linearGradientPrimary,
        display: 'grid',
        gridAutoRows: '60% 40%',
        height: '100%',
        padding: '2rem 1.8rem'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // "& :nth-child(1)": {
        //     width: '78%',
        //     height: '100%',
        //     objectFit: 'contain'
        // },
    },
    circleLogoImage: {
        width: '50%'
    },
    coverImage: {
        width: '50%',
        height: '100%',
        objectFit: 'contain'
    },
    loginContentBox: {
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    welcomeText: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        fontFamily: 'Montserrat',
    },
    divider: {
        width: '15%',
        height: '.4rem',
        backgroundColor: palette.orange,
        marginBottom: '12px',
    },
    logoText: {
        fontFamily: 'Montserrat',
        fontSize: '3.1rem',
        marginTop: '0px',
        fontWeight: 'bold',
        // marginBottom: '12px',
    },
    mainTextDesc: {
        fontSize: '1.2rem',
    },
    side2: {
        flex: '1 1 45%',
        backgroundColor: '#fff',
        height: '100%',
        padding: '3rem',
        display: 'grid',
        color: '#000'
    },
    signInMainText: {
        // fontFamily: 'Montserrat',
        fontSize: '2em',
        fontWeight: 'bold',
        // color: COLORS.textLight,
        // marginBottom: '1rem'
        color: palette.greyText1
    },
    signInDetails: {
        fontSize: '1rem',
        // color: COLORS.textLight,
        lineHeight: '2rem',
        color: palette.greyText1
    },
    labelClass: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        fontSize: '0.9rem',
        // margin: '1.5rem 0',
        display: 'block',
        color: palette.white,

        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
            fontWeight: '600',
            marginBottom: '0.5rem'
        },
    },
    muiBtn: {
        width: '100%',
        fontSize: '0.9rem !important',
        fontWeight: 'bold !important',
        fontFamily: 'Montserrat !important',
        margin: '1rem 0 !important'
    },
    checkBox: {
        // color: COLORS.primaryOrange
    },
    checkBoxContainer: {
        margin: '2rem 0'
    },
    rememberMeText: {
        fontSize: '0.75rem',
        // color: COLORS.textLight,
        padding: '1rem'
    },
    circleLogoBox: {
        width: '100%',
        textAlign: 'end'
    },
    iconBox: {
        width: '37%',
    },
    iconTextBox: {
        color: '#ffffff',
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center'
    },
    logoMaintext: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
    },
    logoSubText: {
        fontSize: '0.45rem',
        fontFamily: 'Montserrat',
    },
    mobileLoginContainer: {
        // backgroundImage: 'linear-gradient(rgb(79, 0, 96), rgb(8, 0, 55))',
        backgroundImage: 'linear-gradient(#4F0060, #4F0060)',
        height: '100vh',
        width: '100vw',
    },
    mobileLoginBox: {
        backgroundImage: 'url(/images/login-screen/login_illustration_bg.svg)',
        height: '100vh',
        width: '100vw',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'contain',
        display: 'grid',
        gridTemplateRows: '31% 69%',
        // alignItems: 'center',
    },
    mobileLogoContainer: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%'
        }
    },
    mobileLoginForm: {
        width: '90%',
        margin: 'auto',
        color: '#FFF',
        height: '100%',
        display: 'grid',
        gridAutoRows: '45% 55%'
    },
    loginFormBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    mobileLoginInputField: {
        fontFamily: "Montserrat",
        // height: "52px",
        borderRadius: "5px",
        fontSize: "14px",
        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4)",
        background: palette.white,
        // background: palette.voilet,
        // color: 'rgba(255, 255, 255, 0.6)',
        color: palette.greyText4,
        fontWeight: "500",
        '& .MuiOutlinedInput-input': {
            color: `${palette.greyText4} !important`,
            boxShadow: `0 0 0 100px ${palette.white} inset !important`,
            borderRadius: '0 5px 5px 0 !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "transparent !important",
        }
    },
    textBoxMobile: {
        padding: '1.2rem 0',
        display: 'grid',
        gridTemplateRows: '25% 25% 25% 25%',
        alignItems: 'center',
        textAlign: 'center'
    },
    text: {
        fontSize: '0.7rem'
    },
    checkboxContainer: {
        display: 'flex',
        // gap: '1rem',
        alignItems: 'center'
    },
    rememberText: {
        fontSize: '0.8rem'
    },
    emailInput: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.15)'
        }
    },
    textBoxDesktop: {
        fontSize: '0.8rem',
        padding: '2.5rem 1rem',
        textAlign: 'center',
        color: palette.orange,
        cursor: 'pointer'
    },
    desktopPwdBox: {
        margin: '0.8rem 0'
    },
    flagLabel: {
        position: 'absolute !important',
        top: '-12px !important',
        left: '0px !important'
    },
    numberInputBox: {
        '& ::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'textfield'
        },
        '& ::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'textfield'
        }
    },
    selectBox: {
        marginTop: '0 !important',
        color: `#FFF !important`,
        opacity: '0.7',
        color: `#000 !important`,
        '&.Mui-disabled > div': {
            '-webkit-text-fill-color': `rgba(0, 0, 0, 0.9) !important`,
            // '-webkit-text-fill-color': `rgba(255, 255, 255, 0.8) !important`,
        },
        [theme.breakpoints.up('sm')]: {
            '&.Mui-disabled > div': {
                '-webkit-text-fill-color': `rgba(0, 0, 0, 0.8) !important`,
            },
        }
    },
    mobileInputBox: {
        backgroundColor: `${palette.white} !important`,
        borderRadius: '5px',
        // backgroundColor: `${palette.voilet2}`,
        // borderRadius: '5px',
    },
    mobileInput: {
        color: `${palette.greyText4} !important`,
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px 11px 0.8rem !important',
            backgroundColor: `${palette.white}`
        }
    },
    requestOtpBtn: {
        fontFamily: 'Montserrat !important',
        fontWeight: '800 !important',
        padding: '8px 22px',
        backgroundColor: '#F4511E !important',
        borderRadius: '0'
    },
    textBoxMobileOtp: {
        // padding: '1.2rem 0',
        display: 'grid',
        gridTemplateRows: '25% 75%',
        alignItems: 'center',
        textAlign: 'center'
    },
    loginChatBox: {
        display: 'grid',
        gap: '2rem'
    },
    copyright: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        height: '100%',
        padding: '1rem 0'
    },
    btnContainer: {
        margin: '1rem 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0'
        }
    },
    otpHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mobileOtpLoadingButton: {
        borderRadius: '0.1rem',

        fontSize: '1rem',
        color: "#FFF !important",
        border: "1px solid #F4511E",
        fontWeight: 'bold',
        marginTop: '1rem !important',
        backgroundColor: 'transparent',
        padding: "8px 22px !important",
        '& .MuiLoadingButton-loadingIndicator': {
            color: '#F4511E',
            backgroundColor: 'transparent',
        }
    },
    desktopOtpLoadingButton: {
        borderRadius: '0.1rem',
        padding: "8x 22px",
        fontSize: '1rem',
        color: `${palette.orange} !important`,
        border: "1px solid #F4511E",
        fontWeight: 'bold',
        marginTop: '1rem !important',
        backgroundColor: 'transparent',
        '& .MuiLoadingButton-loadingIndicator': {
            color: '#F4511E',
            backgroundColor: 'transparent',
        }
    },
    otpBoxDesktop: {
        display: 'flex',
        fontFamily: 'motionControl',
        gap: '0.2rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        '& input': {
            // width: '3rem',
            // height: '3rem',
            padding: '0.5rem',
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: '600',
            color: palette.greyText2,
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                outline: 'none',
                borderRadius: '0.2rem',
                // backgroundColor: palette.voilet,
                backgroundColor: '#fff',
                marginBottom: '0.5rem',
                // color: "#FFF",
                fontSize: '1.8rem',
            },
        }
    },
    toggleSwitchBox: {
        marginBottom: '1.2rem',


    },
    toggleSwitchText: {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
    resentOtpContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5rem',
    },
    resendOtpTimer: {
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.2rem',
    },
    resendOtpBtn: {
        fontWeight: '700',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    timerValue: {

    }
}))

export default useStyles