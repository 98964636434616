// Material UI imports:
import { Button, List, MenuItem, Typography } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

// Styles:
import useStyles from './index.styles';
import palette from "../../../theme/palette";

// Components:
import HeaderBackButton from "../header-back-button";

// Translation:
import { useTranslation } from "react-i18next";

// Constants:
import constants from "../../../constants";

// Redux:
import { useDispatch, useSelector } from "react-redux";

// APIs:
import { postUserLanguage } from "../../../redux/user-specific/userSpecific.api";
import theme from "../../../theme";

const LanguagesListUi = ({
    handleTranslateBackBtnClick,
    setSelectedLanguage,
    setLanguageChanging,
    selectedLanguage,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { isMobile, isTablet } = useSelector((state) => state.common);

    return (
        <List sx={{
            paddingTop: '0rem',
            display: 'grid',
            gridTemplateRows: '10% 90%',
        }} className={classes.langListUi}>


            <MenuItem
                className={`${classes.listBox}`}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className={classes.selectAppLanguage}>
                    {t("Select App Language")}
                </div>

                {(isTablet || isMobile) && (
                    <div
                        onClick={handleTranslateBackBtnClick}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img src='/images/icons/close.svg' alt="close-icon" width={25} />
                    </div>
                )}
            </MenuItem>


            <div className={`{classes.languageWrapper} scrollbar`}
                style={{ overflowY: 'scroll', }}
            >

                {constants.LANGUAGES_LIST?.map((elem) => (
                    <Button
                        className={classes.langListBox}
                        key={`${elem.id}-${elem.key}`}
                        onClick={async () => {
                            dispatch(setSelectedLanguage(elem.name));
                            setLanguageChanging(false);
                            i18n.changeLanguage(elem.key);
                            await postUserLanguage({ "language": elem.name });
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: `0 1rem`,
                            "&:hover": {
                                backgroundColor: 'transparent !important',
                            },
                            [theme.breakpoints.up("md")]: {
                                padding: '0.5rem 1rem',
                                color: 'black !important',
                            }
                        }}
                    >
                        <Typography textAlign="center" style={{ fontWeight: '500' }} className={classes.menuText}>
                            {elem.name}
                        </Typography>
                        {selectedLanguage === elem.name && (
                            <CheckRoundedIcon sx={{ color: palette.orange, fontSize: 22 }} />
                        )}
                    </Button>
                ))}

            </div>



        </List>
    );
};

export default LanguagesListUi;
