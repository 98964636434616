import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileDrawer: {
            zIndex: '9999 !important',
            '& .MuiDrawer-paper': {
                width: '100% !important',
                backgroundColor: `${theme.palette.voilet} !important`,
                backgroundImage: 'none !important'
            },
        },
        closeIcon: {
            right: '1.5rem',
            top: '50%',
        },
        searchPageContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            color: theme.palette.fontColor,
        },
        searchesHeaderText: {
            fontSize: '1.2rem',
            fontWeight: '600',
        },
        listWrapper: {
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.2rem',
            fontWeight: '400',
            flexDirection: 'column',
            gap: '0.5rem',
            overflowY: 'auto',
            height: '100%',
        },
        navigationText: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: '500',
        },
        searchesContainer: {
            display: 'grid',
            gridTemplateRows: '6% 94%',
            height: '100%',
            gap: '1rem',
            [theme.breakpoints.up("md")]: {
                height: "72vh" // this height is given according to its ancestor's height property
            }
        },
        navigationBtn: {
            width: '35px',
            transform: 'rotate(180deg)',
            background: '#f4511e29',
            aspectRatio: '1/1',
            borderRadius: '50%',
        },
        toggleContainer: {
            background: theme.palette.darkVoilet2,
            borderRadius: '2rem',
        },
        searchToggleContainer: {
            [theme.breakpoints.up('md')]: {
                padding: '0 2rem',
            },
        },
        searchedText: {
            fontSize: '1rem',
            fontWeight: '600',
            width: '100%',
            textAlign: 'left',
            color: theme.palette.fontColor,
        },
        noDataFound: {
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    })
})

export default useStyles