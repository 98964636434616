import React, { useEffect, useRef } from 'react';

// redux
import { markRepoViewed } from "../../redux/content-repo/content-repo.api";

// utils
import useGlobalAbortController from '../../utils/abortController';

const viewerConfig = {
  embedMode: "FULL_WINDOW",
  showAnnotationTools: false,
  showDownloadPDF: false,
  showPrintPDF: false,
  showThumbnails: false,
  showBookmarks: false,
  enableLinearization: true,
};

const clientId = process.env.REACT_APP_ADOBE_EMBED_API_CLIENT_ID;

const AdobePDFViewer = (props) => {
    const { 
        docData: { id: docId, path: pdfUrl, name: fileName, enableFileDownload = true, enableFileShare = true },
        from,
        setIsShareButtonVisible = () => { }, 
        setIsDownloadButtonVisible = () => { }, 
    } = props;

    const viewerRef = useRef(null);
    const { setNewAbortSignal } = useGlobalAbortController();
    
    useEffect(() => {
        if (!window.AdobeDC) {
          console.error('Adobe Embed API is not loaded');
          return;
        }
    
        const adobeDCView = new window.AdobeDC.View({ clientId });
        adobeDCView.previewFile(
          {
            content: { location: { url: pdfUrl } },
            metaData: { 
              fileName: fileName,
              hasReadOnlyAccess: true,
            },
          },
          viewerConfig
        );

        // api to mark pdf is viewed
        if (from !== 'SALES_ENABLEMENT' && from !== 'GENERIC_DOC_VIEWER') {
          const signal = setNewAbortSignal();
          markRepoViewed(docId, signal);
      }

        setIsDownloadButtonVisible(enableFileDownload);
        setIsShareButtonVisible(enableFileShare);
      }, [pdfUrl]);

    return <div id="adobe-dc-view" ref={viewerRef} style={{ width: '100%' }}></div>;
};

export default AdobePDFViewer;
