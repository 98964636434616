import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    loadingForm: {
        height: '100%',
        width: '100%'
    },
    dialog: {
        backdropFilter: "blur(10px) !important",
        "& .MuiDialogContent-root": {
            padding: "2rem !important",
        },
        "& .MuiDialogActions-root": {
            padding: "1rem !important",
        },
    },
    dialogPaper: {
        height: '100vh !important',
        overflow: 'hidden !important',
        margin: '0 !important',
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important',
            margin: 'auto !important',
        },
        maxHeight: "calc(100% - 64px) !important",
    },

    paperProps: {
        width: '40%',
        [theme.breakpoints.down("md")]: {
            width: '95vw'
        }
    },

    formContainer: {
        height: '100%',
        display: 'grid',
        [theme.breakpoints.down('md')]: {
            gridTemplateRows: '1fr 7fr 1fr'
        },
    },


    formSubmitContainer: {
        padding: '0rem 1rem',
        // boxShadow: '0 0 10px grey'
        [theme.breakpoints.up('md')]: {
            padding: '0.5rem 1rem'
        }
    },

    formSubmitButton: {
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
    }
}));

export default useStyles;