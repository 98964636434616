import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    spotlightRoot: {
        backgroundColor: '#000',
        userSelect: 'none',
        position: 'fixed',
        overflow: 'hidden',
    },
    spotlightBox: {

        margin: 'auto',
        background: theme.palette.spotLightBg,
        overflow: 'hidden',
        aspectRatio: '9/16',
        borderRadius: '8px',
        // backdropFilter: 'blur(7px)',
        border: '2px solid #FFFFFF',
        position: 'relative',
        // overflow: 'hidden',
        width: '',
        [theme.breakpoints.down('sm')]: {
            aspectRatio: 'none',
            width: '100%',
            borderRadius: 'inherit',
            border: 'none'

        }
    },
    backBtnBox: {
        position: 'absolute',
        left: '2rem',
        top: '2rem',
        height: '2rem',
        border: '2px solid #FFF',
        aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    fixedBackBtnBox: {
        position: 'fixed',
        left: '1rem',
        top: '2rem',
        height: '2rem',
        cursor: 'pointer',
        zIndex: '9999',
        transform: 'translateY(-50%)'
    },

    // face detection model css
    faceModelContainer: {
        width: '100%',
        height: '100%',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        gridAutoRows: '70% 30%',
        // gap: '8rem',
        // backgroundColor: 'transparent',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 5,
        transition: 'background 200ms ease'

    },
    faceModel: {
        width: '80%',
        aspectRatio: '3/4',
        // alignSelf: 'center',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: '5px dashed',
        borderColor: '#fff',
        transition: 'border-color 300ms linear',
    },
    faceModelText: {
        color: theme.palette.white,
        fontSize: '2rem',
        textAlign: 'center'
    }
}))

export default useStyles