import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material UI : 
import { styled, } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

// theme
import palette from '../../../../theme/palette';

// i18-next
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './index.styles';


const SearchBoxWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    [theme.breakpoints.up('sm')]: {
        background: theme.palette.lightVoilet1,
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 2),
}));

const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '600',
    fontSize: '1.2rem',
    position: "relative",
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}));

const SearchBar = (props) => {

    const { handleSearchBarText, CategoryFilterComp, searchInputValue, isPlaceholderVisible = false } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { isMobile, isTablet, selectedMicrokillCategories } = useSelector(state => state?.common);

    const classes = useStyles({ isDarkMode });

    const dispatch = useDispatch();
    const url = window.location.href;

    // states : 
    const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
    const [toggleCategories, setToggleCategories] = useState(null);
    const [microkillCategoriesList, setMicroskillCategoriesList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    // refs : 
    const containerRef = useRef(null);
    const filterBtnContainerRef = useRef();

    let texts = [
        'Search for “Microskills”',
        'Search for “Learning Journeys”',
        'Search for “Forms”',
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchBoxWrapper style={{ width: '100%' }} >
            <Search
                className={`search ${classes.search}`}
                sx={theme => ({
                    display: 'none',
                    [theme.breakpoints.down('md')]: {
                        display: url.includes('/learn') ? 'grid !important' : 'none',
                    },
                    width: '100%',
                    height: '100%',
                    gridTemplateColumns: "90% 10%",
                    borderRadius: openCategoryFilter ? `4px 4px 0 0` : "4px",
                })}
            >

                <div className={classes.searchInputBox}>
                    <StyledInputBase
                        placeholder=''
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => { handleSearchBarText(e) }}
                        value={searchInputValue}
                        autoFocus={isPlaceholderVisible}
                    />

                    {(!isPlaceholderVisible) && <div className={classes.rolllerBox}>
                        {texts.map((item, index) => (
                            <div key={index} className={classes.rollerItem} style={{ animationDelay: `${(10 / 3) * index}s` }}>{item}</div>
                        ))}
                    </div>}
                </div>

                <SearchIconWrapper className={classes.centerFlex} style={{ justifyContent: 'flex-end' }}>
                    <SearchIcon
                        style={{ height: '50px', width: '30px', color: isDarkMode ? palette.white : palette.orange }}
                    />
                </SearchIconWrapper>

            </Search>
            {
                (!isMobile && !isTablet) && openCategoryFilter && url.includes("/learn") && <CategoryFilterComp
                    openCategoryFilter={openCategoryFilter}
                    containerRef={containerRef}
                    toggleCategories={toggleCategories}
                    setToggleCategories={setToggleCategories}
                    filterBtnContainerRef={filterBtnContainerRef}
                    microkillCategoriesList={microkillCategoriesList}
                    selectedMicrokillCategories={selectedMicrokillCategories}
                    dispatch={dispatch}
                />
            }
        </SearchBoxWrapper>
    )
}

export default SearchBar;