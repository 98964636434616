import React, { useState, useEffect } from "react";

// mui comps and styles
import { Button, CircularProgress } from "@mui/material";
import useStyles from "./index.styles"; // Assume a hook for styles

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil"; // Utilities for user info and other helpers

// lotties
import Lottie from "react-lottie-player";
import timeupFormSubmissionLottieData from "../../../lottie-assets/timeout_forms.json"
import { t } from "i18next"; // i18next for translations

const FormsLoseScreen = ({
  name, // Form or challenge name
  formData, // Data related to the form
  attemptData, // Data about the user's attempt
  handleCloseForm, // Function to close the form
  isChallenge, // Boolean: whether this is a challenge
  handleTryAgain, // Function to handle "Try Again"
  isTimeUp, // Boolean: whether the timer expired
}) => {
  const [loading, setLoading] = useState(true); // Loading state for data processing
  const classes = useStyles(); // Styles hook

  // Effect to toggle the loading state based on the availability of formData and attemptData
  useEffect(() => {
    setLoading(isEmpty(formData) || isEmpty(attemptData));
  }, [formData, attemptData]);

  // Function to get the user's display name
  const getDisplayName = () => {
    const userName = commonUtils.getUserName();
    const loginUserName = commonUtils.getLoginUserName();
    return userName
      ? `${userName} (${loginUserName})`
      : loginUserName || "Guest";
  };

  // Render a loading spinner while data is being processed
  if (loading) {
    return (
      <div className={`${classes.centerFlex} ${classes.loadingForm}`}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      {/* Form Information Section */}
      <div
        className={classes.infoContainer}
        style={{ width: isChallenge ? "95%" : "100%" }}
      >
        {/* Close Icon for non-challenge forms */}
        {!isChallenge && (
          <div className={`${classes.closeIconContainer} ${classes.centerFlex}`}>
            <img
              src="/images/icons/form-close-icon.svg"
              alt="form-close"
              className={classes.closeIcon}
              onClick={handleCloseForm}
            />
          </div>
        )}

        {/* Form Name */}
        <div className={classes.formName}>
          {name}
        </div>

        {/* Outcome Section */}
        <div
          className={`${classes.thanksContainer} ${classes.centerFlex}`}
          style={{ padding: isChallenge ? "0" : "1rem" }}
        >
          {/* Show either "Time's Up" animation or "Try Again" icon */}
          <div className={classes.centerFlex}>
            {isTimeUp ? (
              <Lottie
                animationData={timeupFormSubmissionLottieData}
                loop={false}
                play
                style={{ width: "40%" }}
              />
            ) : (
              <img
                src="/images/icons/form-cross.png"
                alt="form-cross"
                className={classes.imgIcon}
              />
            )}
          </div>

          {/* Display Outcome Text */}
          <div className={classes.tryAgainText}>
            <div className={classes.greetingText}>
              {isTimeUp ? "TIME'S UP" : "TRY AGAIN"}
            </div>
            <div className={classes.usernameText}>{getDisplayName()}</div>
          </div>
        </div>

        {/* Assessment Failure Message */}
        <div className={classes.msgText}>
          {t("You did not pass the assessment.")}
        </div>
      </div>

      {/* Action Buttons Section */}
      <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
        {/* Try Again Button */}
        <Button
          variant="contained"
          className={classes.exitButton}
          onClick={handleTryAgain}
        >
          {t("Try Again")}
        </Button>

        {/* Exit or Challenge List Button */}
        <Button
          variant="contained"
          className={classes.exitButton}
          onClick={handleCloseForm}
        >
          {isChallenge ? t("Challenge List") : t("Exit")}
        </Button>
      </div>
    </div>
  );
};

export default FormsLoseScreen;