import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// components
import DialogAlert from "../../components/dialog-alert";
import HeaderBackButton from "../../components/layout-elements/header-back-button";
import FormAsChallenge from "../../components/form-as-challenge";

// mui
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// redux

// utils
import isEmpty from "../../utils/isEmpty";

// constants

// styles
import useStyles from "./index.styles";

const FormsChallenge = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const navigate = useNavigate();
    const { microskillId } = useParams();

    let [searchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const { t } = useTranslation()
    const classes = useStyles();

    const handleBackButtonClick = () => {
        if(formSubmitted) {
            handleConfirmBtnClick();
            return;
        }
        setIsDialogOpen(true);
    }

    const handleConfirmBtnClick = () => {
        setIsDialogOpen(false);
        navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `&journeyId=${journeyId}` : ''}`);
    }

    const handleDiscardBtnClick = () => {
        setIsDialogOpen(false);
    }

    return (
        <div className={classes.formRootWrapper}>
            {
                isMobile && (
                    <div className={classes.formHeader} style={{ gridTemplateColumns: '12% 88%' }} >
                        <HeaderBackButton onClick={handleBackButtonClick} />
                        <div className={classes.headerName}>{queryParams.get("name")}</div>
                    </div>
                )
            }

            <div className={`${classes.formRoot} ${isMobile ? classes.centerFlex : ''}`}>
                {
                    !isMobile && (
                        <div className={`${classes.backBtnBox} ${classes.centerFlex}`} onClick={handleBackButtonClick}>
                            <KeyboardBackspaceIcon sx={{ color: "#FFF" }} />
                        </div>
                    )
                }

                <div className={classes.formBox}>
                    <FormAsChallenge setFormSubmitted={setFormSubmitted} />
                </div>

                {
                    isDialogOpen && (
                        <DialogAlert
                            isOpen={isDialogOpen}
                            content={t("Going back will lead to loss of data. Do you really want to exit?")}
                            confirmBtnName={t('Yes')}
                            handleConfirmBtnClick={handleConfirmBtnClick}
                            discardBtnName={t('No')}
                            handleDiscardBtnClick={handleDiscardBtnClick}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default FormsChallenge;