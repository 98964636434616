import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

// components
import NavigationHeader from "./navigation-header";
import Content from "./content";
import DialogAlert from "../../components/dialog-alert";

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import useStyles from "./index.styles";

//redux
import { getAllBulletinsAsync, postBulletinEndAttempt, postBulletinStartAttempt } from "../../redux/microskill/microskill.api";
import { openToast } from "../../redux/common/common-slice";

//utils
import isEmpty from "../../utils/isEmpty";
import { useSelector } from "react-redux";

const defaultFilters = 'ITB,VTB,ATB'

const BulletinBoard = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();

    const { bulletinId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);

    // States
    const [bulletinsData, setBulletinsData] = useState([]);
    const [navigationData, setNavigationData] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedBulletinId, setSelectedBulletinId] = useState(bulletinId);
    const [selectedBulletinData, setSelectedBulletinData] = useState({});
    const [viewFullScreen, setViewFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState();

    // Refs
    const contentRef = useRef(null);
    const firstRenderRef = useRef(true);
    const selectedBulletinIdRef = useRef(null);
    const analyticsRenderRef = useRef(true);

    const getAllBulletinsDataAsync = async () => {
        try {
            setIsLoading(true);

            const cardTypes = searchParams.get('bulletInCardTypes') ?? defaultFilters;
            const queryParam = `?bulletInCardTypes=${cardTypes}`;

            const moduleData = await getAllBulletinsAsync(queryParam);
            if (moduleData.status === 200 || moduleData.status === 201) {
                const sortedData = moduleData.data.data.sort((a, b) => moment(a.launchesOn) - moment(b.launchesOn));
                setBulletinsData(sortedData);
                setIsLoading(false);
            } else if (moduleData.status === 500) {
                setIsLoading(false);
                dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }));
            }
        } catch (error) {
            setIsLoading(false);
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Error fetching bulletins', toastSeverity: 'error' }));
        }
    };

    const extractBulletinDates = (bulletins) => {
        return bulletins.map(bulletin => {
            const date = new Date(bulletin.launchesOn);
            return {
                id: bulletin.id,
                date: date.getDate(),
                month: date.toLocaleDateString('en-US', { month: 'long' }),
                year: date.getFullYear(),
                day: date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase()
            };
        });
    };



    const getBulletinDataById = (id) => {
        const bulletin = bulletinsData.find(b => b.id === id);
        if (!bulletin) return null;

        const sortedContents = bulletin.content.contents.sort((a, b) => a.sequence - b.sequence);
        const title = sortedContents.find(item => item.name === "TITLE")?.content || "";
        const videoContent = sortedContents.find(item => item.name === "VIDEO" || item.name === "YOUTUBE");
        const audioContent = sortedContents.find(item => item.name === "AUDIO");
        const imageContent = sortedContents.find(item => item.name === "IMAGE");
        const description = sortedContents.find(item => item.name === "BODY")?.content || "";
        const linkContent = sortedContents.find(item => item.name === "LINK") || "";

        let url = videoContent?.content || audioContent?.content || imageContent?.content || linkContent?.content || "";
        let cardType = "";

        if (videoContent) {
            cardType = videoContent.name === "YOUTUBE" ? "YTB" : "VTB";
        } else if (audioContent) {
            cardType = "ATB";
        } else if (imageContent) {
            cardType = "ITB";
        } else if (linkContent) {
            cardType = "LTB"
        }

        const createdOnDate = new Date(bulletin.launchesOn);
        const createdOnString = `${createdOnDate.getDate()} ${createdOnDate.toLocaleString('default', { month: 'long' })} ${createdOnDate.getFullYear()}`;

        return {
            title,
            url,
            description,
            cardType: cardType.charAt(0).toUpperCase(),
            launchesOn: createdOnString
        };
    };

    const formatDate = (inputDate) => {
        const dateObj = new Date(inputDate);

        return {
            date: dateObj.getDate(),
            day: dateObj.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(),
            month: dateObj.toLocaleDateString('en-US', { month: 'long' }),
            year: dateObj.getFullYear()
        };
    }

    useEffect(() => {
        const index = bulletinsData.findIndex(b => b.id === selectedBulletinId);
        if (index !== -1) {
            setActiveIndex(index);

            // Analytics : 
            postBulletinStartAttempt({ bulletinId: selectedBulletinId });
            // Analytics :

            const bulletinData = getBulletinDataById(selectedBulletinId);
            setSelectedBulletinData(bulletinData);

            // It is upto logic on which data you want to display the selected Bulletins Data : 

            // createdOn : 
            // const dateData = formatDate(bulletinData.createdOn);

            // updatedOn : 
            // const dateData = formatDate(bulletinData.updatedOn);

            // launchesOn : 
            const dateData = formatDate(bulletinData.launchesOn);

            setSelectedDate(dateData?.date);
            selectedBulletinIdRef.current = selectedBulletinId;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBulletinId, bulletinsData]);

    useEffect(() => {
        if (!isEmpty(bulletinsData)) {
            const extractedNavigationData = extractBulletinDates(bulletinsData);
            setNavigationData(extractedNavigationData);
        }
    }, [bulletinsData]);

    useEffect(() => {
        getAllBulletinsDataAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleBulletinHeaderClick = (currentItem) => {
        setSelectedDate(currentItem.date);

        // Analytics :
        postBulletinEndAttempt({ bulletinId: selectedBulletinId });
        // Analytics :

        setSelectedBulletinId(currentItem.id);
    };

    const _handlePrevClick = () => {
        if (activeIndex > 0) {
            const prevIndex = activeIndex - 1;
            setActiveIndex(prevIndex);

            // Analytics : 
            postBulletinEndAttempt({ bulletinId: selectedBulletinId });
            // Analytics :

            setSelectedBulletinId(bulletinsData[prevIndex].id);
        }
    };

    const _handleNextClick = () => {
        if (activeIndex < bulletinsData.length - 1) {
            const nextIndex = activeIndex + 1;
            setActiveIndex(nextIndex);

            // Analytics : 
            postBulletinEndAttempt({ bulletinId: selectedBulletinId });
            // Analytics :

            setSelectedBulletinId(bulletinsData[nextIndex].id);
        }
    };

    const _handleViewFullScreen = () => {
        if (contentRef.current) {
            if (!document.fullscreenElement) {
                contentRef.current.requestFullscreen().catch(err => {
                    console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
                setViewFullScreen(true);
            } else {
                document.exitFullscreen();
                setViewFullScreen(false);
            }
        }
    };

    // Handle ESC key to exit fullscreen
    const handleKeyDown = (event) => {
        if (event.key === 'Escape' && document.fullscreenElement) {
            _handleViewFullScreen(); // Toggle fullscreen off
        }
    };

    const handleUnmount = () => {
        if ((isMobile || isTablet) && !firstRenderRef.current) {
            postBulletinEndAttempt({ bulletinId: selectedBulletinIdRef.current });
        }
        firstRenderRef.current = false;
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            handleUnmount();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleBackButtonClick = () => {
        postBulletinEndAttempt({ bulletinId: selectedBulletinId })
        navigate("/learn");
    };

    const handleTabChange = () => {
        if (!analyticsRenderRef.current) {
            postBulletinEndAttempt({ bulletinId: selectedBulletinIdRef.current });
        }
        analyticsRenderRef.current = false;
    }

    // event listner for browser tab change
    useEffect(() => {
        window.addEventListener('visibilitychange', handleTabChange);

        return () => {
            window.removeEventListener('visibilitychange', handleTabChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <>
            {isLoading ?
                <div className={classes.circularLoader}>
                    <CircularProgress />
                </div> :

                <>
                    {isEmpty(bulletinsData) ?
                        <DialogAlert
                            isOpen={isEmpty(bulletinsData)}
                            content={`No Bulletins Found`}
                            confirmBtnName={'Back'}
                            handleConfirmBtnClick={() => { navigate(`/learn`) }}
                        />

                        :
                        <div className={classes.root}>
                            <NavigationHeader
                                navigationData={navigationData}
                                selectedDate={selectedDate}
                                handleBulletinHeaderClick={_handleBulletinHeaderClick}
                                handleBackButtonClick={_handleBackButtonClick}
                            />
                            <div ref={contentRef} className={classes.viewFullScreen}>
                                <Content
                                    selectedBulletinData={selectedBulletinData}
                                    handlePrevClick={_handlePrevClick}
                                    handleNextClick={_handleNextClick}
                                    handleViewFullScreen={_handleViewFullScreen}
                                    viewFullScreen={viewFullScreen}
                                />
                            </div>
                        </div>}
                </>

            }

        </>
    );
};

export default BulletinBoard;

