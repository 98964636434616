import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

// mui
import { Button, CircularProgress } from "@mui/material";
import Fade from '@mui/material/Fade';

// components
import LaVoiceOverAudioPlayer from "../../la-voiceover-audio-player";

// redux
import { handleGbaScreenState } from "../../../redux/gba/gba-slice";
import { useDispatch } from "react-redux";

// utils
import commonUtil from "../../../utils/commonUtil";
import utilityFunctions from "../../../utils/utilityFunctions";
import isEmpty from "../../../utils/isEmpty";


// theme
import palette from "../../../theme/palette";

// constants
import constants from "../../../constants";

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

// lottie
import Lottie from 'react-lottie-player';
import WinScreenConfetti from '../../../lottie-assets/win-screen-confetti.json'

let timeout = 1000;
let delay = 400;

// const SNOW_COLORS = ["#1E88E5", "#F63B34", "#00CC2E", "#FFDD00", "#56DAB8", "#F35DCB", "#00897B", "#FF2596", "#43A047"]

const LaWinScreen = (props) => {
    const { score, time, maxScore } = props;
    const { microskillId, challengeId } = useParams();
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');
    
    const learnerJourneyEnded = useSelector(state => state?.common?.learnerJourneyEnded);
    const completedText = props.completeText === undefined || props.completeText === null ? 'LEARNING AID' : props.completeText;

    // let searchParam = isEmpty(location.search.split('?')[1]) ? '' : `&${location.search.split('?')[1]}`

    const classes = useStyles();
    const { i18n } = useTranslation()
    const navigate = useNavigate();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const [time1, setTime1] = useState(false);
    const [time2, setTime2] = useState(false);
    const [time3, setTime3] = useState(false);
    const [time4, setTime4] = useState(false);
    const [time5, setTime5] = useState(false);
    const [nextChallengeData, setNextChallengeData] = useState({});
    const [showNextChallengeBtn, setShowNextChallengeBtn] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [isChallengeDataLoading, setIsChallengeDataLoading] = useState(true);
    const { t } = useTranslation();
    const { getChallengeListData, handleChallengeListCardClick } = utilityFunctions;

    // let searchParam = isEmpty(location.search.split('?')[1]) ? '' : `&${location.search.split('?')[1]}`


    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    useEffect(() => {
        (async function () {
            await sleep(delay);
            setTime1(true);

            await sleep(delay);
            setTime2(true);

            await sleep(delay);
            setTime3(true);

            await sleep(delay);
            setTime4(true);

            await sleep(delay);
            setTime5(true);
        })();
    }, [])

    useEffect(() => {
        setIsChallengeDataLoading(true);
        getChallengeListData(microskillId,
            challengeId,
            setNextChallengeData,
            setShowNextChallengeBtn,
            setShowCertificate);
        setIsChallengeDataLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microskillId]);


    const handleNextBtnClick = () => {
        dispatch(handleGbaScreenState(constants.GBA_SCREEN_STATE.START));
        handleChallengeListCardClick(nextChallengeData, navigate, microskillId, journeyId);
    };

    const handleCertificateClick = () => {
        if (!showCertificate) return;
        navigate(`/challenge-list/${microskillId}/certificate${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
    }

    const ActionButton = ({ variant = "contained", color = "gbabtn", bg = palette.yellow, btnText, handleBtnClick }) => {

        return (
            <Button variant='contained'
                fullWidth
                size="small"
                color="gbabtn"
                className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                sx={{
                    fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                    fontWeight: '300',
                    backgroundColor: bg,
                    lineHeight: '0',
                    color: { color },
                }}
                onClick={handleBtnClick} >
                {t(btnText)}
            </Button>
        );
    };

    const handleChallengeListClick = () => {
        navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ""}`);
    }


    return (
        <>
            <div className={classes.laWinScreenRoot}>
                <div className={classes.audioPlayerContainer}>
                    <LaVoiceOverAudioPlayer voiceOverTrack={"/audio/LA-Win.mp3"} autoPlay={true} loop={false} />
                </div>
                <div className={classes.lottieContainer}>
                    <Lottie
                        animationData={WinScreenConfetti}
                        // iterations={2}
                        loop={false}
                        play={true}
                        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>
                <div className={classes.laWinScreenBox}>
                    <Fade
                        in={time1}
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={{ enter: timeout }}
                    // {...(true ? { timeout: timeout } : {})}
                    >
                        <div>
                            <img src="/images/icons/tickmark.png" alt="icon" width={isMobile ? 70 : 100} />
                            <div className={classes.completeTxt}>{t(`${completedText} COMPLETED`)}!</div>
                        </div>
                    </Fade>

                    <Fade
                        in={time2}
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={{ enter: timeout }}
                    // {...(true ? { timeout: timeout + delay } : {})}
                    >
                        <div className={classes.congratsText}>{t("CONGRATULATIONS")}!</div>
                    </Fade>

                    <Fade
                        in={time3}
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={{ enter: timeout }}
                    // {...(true ? { timeout: timeout + (2 * delay) } : {})}
                    >
                        <div className={classes.subText}>{t("You have successfully mastered this challenge")}</div>
                    </Fade>


                    <Fade
                        in={time4}
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={{ enter: timeout }}
                    // {...(true ? { timeout: timeout + (3 * delay) } : {})}
                    >
                        <div className={`${classes.detailsBox} ${classes.centerFlex}`} style={{ justifyContent: 'space-around' }}>
                            {maxScore > 0 && (
                                <div className={`${classes.iconBox} ${classes.centerFlex}`}>
                                    {
                                        parseInt(score) > 0 && (<img src="/images/icons/trophy.png" alt="icon" width={isMobile ? 25 : 40} />)
                                    }
                                    <span className={classes.detailText}>{score} / {maxScore}</span>
                                </div>
                            )}

                            <div className={`${classes.iconBox} ${classes.centerFlex}`}>
                                <img src="/images/icons/clock.png" alt="icon" width={isMobile ? 25 : 40} />
                                <span className={classes.detailText}>{commonUtil.getMinutesFromSeconds(time)}</span>
                            </div>
                        </div>
                    </Fade>


                    <Fade
                        in={time5}
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={{ enter: timeout }}
                    >
                        {
                            learnerJourneyEnded ? (
                                <Button variant="contained"
                                    disableElevation
                                    fullWidth
                                    onClick={() => navigate(`/learning-journey/${journeyId}`)}
                                >
                                    <span className={`fixed_ratio_20_3 p-0 ${classes.challengeBtnText} ${classes.centerFlex}`}>{t("Back to journey")}</span>
                                </Button>
                            ) : (
                                <div className={classes.ilaWinScreenBtnContainer}>

                                    <div className={classes.ilaInnerBtnContainer}>

                                        {isChallengeDataLoading ? <div className={classes.centerFlex}><CircularProgress /></div>
                                            : <>

                                                {showNextChallengeBtn &&
                                                    (<ActionButton btnText="NEXT CHALLENGE" handleBtnClick={handleNextBtnClick} />)
                                                }

                                                {
                                                    showCertificate &&
                                                    (<ActionButton btnText="SHOW MY CERTIFICATE" handleBtnClick={handleCertificateClick} />)
                                                }
                                            </>
                                        }

                                        <Button
                                            variant={!showNextChallengeBtn && !showCertificate ? 'contained' : 'text'}
                                            fullWidth
                                            size="small"
                                            // color={}
                                            className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                                            sx={{
                                                fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                                                fontWeight: '500',
                                                fontFamily: 'MotionControl',
                                                color: !showNextChallengeBtn && !showCertificate ? "gbabtn" : "#FFF" 

                                            }}
                                            onClick={handleChallengeListClick} >
                                            {t("CHALLENGE LIST")}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </Fade>

                    {/* <Grow
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: timeout + (5*delay) } : {})}
                    >

                        </Grow> */}
                </div>
            </div>

        </>
    )
}

export default LaWinScreen;