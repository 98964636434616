import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";
import commonUtil from "../../../utils/commonUtil";

const isHDScreen = commonUtil.getScreenResolution() === 'HD';


// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem 0 0 0',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms',
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
            // gridTemplateRows: '7% 20% 73% 0%',
        },
    },
    questionBox: {
        display: 'grid',
        gridTemplateColumns: '20% 60%'
    },
    question: {
        position: 'relative',
        fontWeight: 'bold',
        // marginTop: '3rem',
        textAlign: 'center',
        padding: '0 1rem',
        // hyphens: 'auto',
        fontSize: isHDScreen ? '1rem' : '1.6rem',
        [theme.breakpoints.down("md")]: {
            // wordBreak: 'break-all',
            fontWeight: '600',
            fontSize: isHDScreen ? '0.8rem' : '1rem'
        }
    },
    mcqRootContainer: {
        // display: 'grid',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
        // alignContent: 'space-between',
    },
    mcqBox: {
        width: '95%',
        margin: 'auto',
        position: 'relative',
        display: 'grid',
        padding: '0 2rem',
        gap: '1.2rem',
        gridTemplateColumns: 'repeat(4, 1fr)',

        // overflowWrap: 'anywhere',
        // hyphens: 'auto',

        fontSize: isHDScreen ? '0.6em' : '0.9em',
        [theme.breakpoints.down("md")]: {
            gap: '1rem',
            padding: '0 1rem',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '0 0',
            overflowWrap: 'break-word',

        }
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-2rem'
    },
    cardBox: {
        backgroundColor: palette.ilaCardBlue,
        borderRadius: '10px',
        height: '100%',
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 2px 0px',
        transition: 'all 0.3s linear',
        minHeight: '7rem',
        outline: '2px solid transparent',
        position: 'relative',
        zIndex: '5'


    },
    card: {
        padding: '1rem 0.6rem',
        height: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans',
        transitio: 'all 0.2s linear',
        fontSize: isHDScreen ? '0.6rem' : '0.8rem',

        // overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        
        [theme.breakpoints.down("sm")]: {
            hyphens: 'auto',
            hyphenateLimitChars: 12,
            padding: '0.3rem',
            fontSize: isHDScreen ? '0.6rem' : '0.7rem',
        },
    },
    aimContainer: {
        height: '100%',
        position: 'relative',
        cursor: 'pointer',
        touchAction: 'none'
    },
    aimBox: {
        position: 'absolute',
        bottom: 'calc(0rem)',
        width: '11rem',
        height: '7rem',
        backgroundImage: 'url(/images/icons/pointer-arc.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        // backgroundPosition: 'center center',
        // backgroundOrigin: 'center center'
        // borderRadius: '10rem 10rem 0 0',
        // border: '2px #fff',
        // borderStyle: 'dashed solid solid solid',
        // mozBorderStyle: 'solid'
    },
    aimBoxArc: {
        width: '100%',
        height: '100%',
        objectFit: 'fill',
        position: 'absolute',
        top: '0px',
        left: '0px'
    },
    navigation: {
        '--height': '120px',
        '--angle': '0deg',
        position: 'absolute',
        bottom: '0',
        height: 'var(--height)',
        left: '50%',
        width: '1px',
        transform: 'translateX(-50%)',
        // backgroundColor: 'white',
        backgroundColor: 'transparent',
        transformOrigin: 'center bottom',
        transform: 'rotate(var(--angle))'
    },
    navigationIcon: {
        '--posFromBottom': '4.7rem',
        position: 'absolute',
        bottom: 'var(--posFromBottom)',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    ball: {
        // '--top': '5.2rem',
        '--transisnTime': '500ms',
        position: 'absolute',
        bottom: 'var(--top)',
        left: '50%',
        width: '18px',
        height: '18px',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        zIndex: '9',
        background: 'rgba(255, 255, 255, 1)',
        transition: 'bottom var(--transisnTime) linear'
    },
    guideText: {
        position: 'relative',
        top: '60%',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        width: '100%',
        height: '100%'
    },
    responseTypeText: {
        position: 'relative',
        top: '10px',
        textAlign: 'center',
        transitio: 'all 0.2s linear',
        fontFamily: 'Montserrat',
        fontSize: '1rem',
        fontWeight: 800,
        paddingBottom: '0',
        margin: '0',
        [theme.breakpoints.down("md")]: {
            fontSize: '0.8rem',
            fontWeight: 700,
            // width: '70%'
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.6rem',
            fontWeight: 700,
            // width: '70%'
        }
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '2rem'
    },

}))

export default useStyles