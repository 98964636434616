import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

// translation : 
import { useTranslation } from "react-i18next";

import webViewJavaScriptBridge from 'webview-javascript-bridge';

// components : 
import ShimmerLearn from "../../components/shimmer/shimmer-learn";
import MicroskillCategoryContainer from "../../components/microskill-category-container";
import NoMicroskill from "../../components/no-microskills";
import CarouselComponent from '../../components/carousel';
import SplashScreen from "../../components/splash-screen";
import LearningJourneyContainer from "../../components/learning-journey-container";
import PrivacyPolicyBanner from "../../components/privacy-policy-banner";
import SearchBar from "../../components/layout-elements/search/search-bar";
import SearchPage from "../../components/layout-elements/search/search-page";
import ProgressStatusMenu from "../../components/layout-elements/progress-status-menu";

// redux : 
import {
    openToast,
    setSelectedLanguage,
    setSelectedMicroskillCategories,
} from "../../redux/common/common-slice";
import { getAllModulesAsync } from "../../redux/microskill/microskill.api";
import { handleRecentlyLearned } from "../../redux/microskill/microskill-slice";
import { LearnPage } from "../../redux/event-tracking/event-tracking-slice";
import {
    setSplashScreenViewFlag,
    setPrivacyPolicyDetails
} from "../../redux/common-persist/common-persist-slice";
import { setUser, setUserProfileData } from "../../redux/auth/auth-slice";

// api : 
import { submitUserActivityData } from "../../redux/event-tracking/event.api";
import { notificationViewMark } from "../../redux/notifications/notification.api";
import { getUserProfile } from "../../redux/user-specific/userSpecific.api";

// lottie : 
import Lottie from "react-lottie-player";
import noMicroskillAnimation from "../../lottie-assets/noMicroskillAnimation.json";

// constants : 
import constants from "../../constants";

// utils : 
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil";

// styles : 
import useStyles from "./index.styles";

const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const FULL_HEIGHT_DESKTOP = constants.FULL_HEIGHT_VALUE;
const FULL_HEIGHT_MOBILE = constants.FULL_HEIGHT_MOBILE_VALUE

// var bridge = require("webview-javascript-bridge-promised");

const Learn = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userActivityData = useSelector(state => state.event);
    const tokens = useSelector(state => state.auth.tokens);

    // const userActivityDelay = 2000;
    let companyId = commonUtil.getCompanyId();
    const { t, i18n } = useTranslation();
    const url = window.location.href;
    let isNativeApp = localStorage.getItem("isNative");


    // redux states : 
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const selectedLanguage = useSelector(state => state?.common?.selectedLanguage);
    const selectedMicrokillCategories = useSelector(state => state?.common?.selectedMicrokillCategories);
    const recentlyLearnedIds = useSelector(state => state?.microskill?.recentlyLearnedIds);
    const isSplashScreenViewed = useSelector(state => state?.commonPersist?.isSplashScreenViewed);
    let viewMicroskillByCategory = useSelector(state => state.common.viewMicroskillByCategory);
    const headerSearchText = useSelector(state => state?.common?.headerSearchText);
    const userDetail = useSelector(state => state?.auth?.user);
    const privacyPolicy = useSelector(state => state?.commonPersist?.privacyPolicyDetails);

    // states : 
    const [isModulesLoading, setIsModulesLoading] = useState(true);
    const [modulesOriginalData, setModulesOriginalData] = useState([]);
    const [moduleDataList, setModuleDataList] = useState([]);
    const [allModules, setAllModules] = useState([]);
    const [recentlyLearned, setRecentlyLearned] = useState([]);
    const [noMicroskill, setNoMicroskill] = useState(false);
    const [policyExpirationDate, setPolicyExpirationDate] = useState('');
    const [isPolicyWarning, setIsPolicyWarning] = useState(-1);
    const [learningJourneyData, setLearningJourneyData] = useState([]);
    const [isSearchPageVisible, setIsSearchPageVisible] = useState(false);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('all');
    const [preserveAllModules, setPreserveAllModules] = useState([]);
    const [recentlyLaunchedData, setRecentlyLaunchedData] = useState([]);
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(false);
    const [bulletinData, setBulletinData] = useState(null)
    const [formsData, setFormsData] = useState([]);
    const [isMsFetched, setIsMsFetched] = useState(false);
    const [newsBulletin, setNewsBulletin] = useState({});

    // refs : 
    const firstRenderRef = useRef(true);

    // company specific
    const isBajajICV = companyId === process.env.REACT_APP_BAJAJ_ICV_COMPANY_ID;
    const isIndigo = companyId === process.env.REACT_APP_INDIGO_COMPANY_ID;
    const isHaleon = companyId === process.env.REACT_APP_HALEON_COMPANY_ID;

    const logos = userDetail?.companyTheme?.logo;

    useEffect(() => {
        let is_app_started_from_notification = window.is_app_started_from_notification;
        let app_notification_data = window.app_notification_data;

        if (typeof app_notification_data === 'string') {
            app_notification_data = commonUtil.parseJSONFromFlutter(app_notification_data);
        }

        const notificationId = localStorage.getItem("notification_messageId");
        // console.log("Learn is_app_started_from_notification:", is_app_started_from_notification)
        // console.log("Learn app_notification_data:", app_notification_data);
        // console.log("Learn notification_messageid:", notificationId);

        if (is_app_started_from_notification && isEmpty(app_notification_data) && !isEmpty(notificationId)) {
            const payload = {
                messageId: notificationId,
                status: 'CLICKED'
            };
            notificationViewMark(payload).then((markViewNotification) => {
                if (markViewNotification.status === 200 || markViewNotification.status === 201) {
                    // console.log('completed');
                }
            });
        }
        if (is_app_started_from_notification && !isEmpty(app_notification_data) && !isEmpty(notificationId)) {
            const jsonData = app_notification_data;
            if (jsonData.type === 'microskill') {
                navigate(`/challenge-list/${jsonData.microskillId}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.is_app_started_from_notification, window.app_notification_data, window.notification_messageId]);

    useEffect(() => {

        (async function () {
            if (window.messageForAlkemUserNative?.companyCode !== undefined) {
                window.messageForAlkemUserNative = undefined;
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.messageForAlkemUserNative?.companyCode]);

    useEffect(() => {

        (async function () {
            if (!isEmpty(window.messageForAlkemUserNativeCompanyCode)) {
                window.messageForAlkemUserNativeCompanyCode = undefined;
                window.messageForAlkemUserNativeEncryptedText = undefined;
            }
            if (!isEmpty(window.messageForAlkemUserNativeEncryptedText)) {
                window.messageForAlkemUserNativeCompanyCode = undefined;
                window.messageForAlkemUserNativeEncryptedText = undefined;
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.messageForAlkemUserNativeCompanyCode, window.messageForAlkemUserNativeEncryptedText]);

    useEffect(() => {
        if (!commonUtil.validToken(tokens)) return
        if (firstRenderRef.current) {
            dispatch(LearnPage({ oldScreen: userActivityData.currentScreen, currentScreen: 'LEARN', nextScreen: '', activityData: { screen: 'Learn Page Loaded' } }));
            firstRenderRef.current = false;
        }
        else {
            submitUserActivityData(userActivityData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActivityData, tokens])

    useEffect(() => {
        if (!commonUtil.validToken(tokens)) return
        if (isEmpty(selectedLanguage)) { // if language is not selected, fetch user profile details
            fetchUserProfileDetails()
        }
        if (!isEmpty(selectedLanguage)) {
            handleModuleDataAsync({ search: headerSearchText });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens, selectedLanguage, selectedMicrokillCategories]);

    const modifyModuleData = (moduleData) => {
        const tempModuleCategoryData = {};

        if (viewMicroskillByCategory) {
            moduleData.data.data.microskills
                .sort((x, y) => {
                    let xNullSeq = x.Microskill?.Category?.sequence;
                    let yNullSeq = y.Microskill?.Category?.sequence;

                    if (xNullSeq === null && yNullSeq !== null) {
                        return 1; // Move null values to the end
                    } else if (xNullSeq !== null && yNullSeq === null) {
                        return -1; // Keep non-null values at the beginning
                    } else {
                        return xNullSeq - yNullSeq; // Sort non-null values in ascending order
                    }
                })
                .forEach(item => {
                    if (isEmpty(tempModuleCategoryData[item.Microskill.categoryId])) {
                        tempModuleCategoryData[item.Microskill.categoryId] = [];
                        tempModuleCategoryData[item.Microskill.categoryId].push(item);
                    } else {
                        tempModuleCategoryData[item.Microskill.categoryId].push(item);
                    }
                });
            const moduleCategoryData = [...Object.values(tempModuleCategoryData)];

            if (isEmpty(moduleCategoryData)) setIsModulesLoading(false);
            setPreserveAllModules(moduleCategoryData)

            dispatch(LearnPage({ activityData: { message: moduleData?.data?.message, pagination: moduleData?.data?.pagination } }));

        } else if (moduleData.status === 500) {
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }))
            dispatch(LearnPage({ activityData: { error: moduleData?.data } }));
        }
        else {
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }))
            dispatch(LearnPage({ activityData: { error: moduleData?.data } }));
        }
    }

    const handleCategoryStatusData = (selectedStatusFilter) => {

        let notStarted = [];
        let inProgress = [];
        let completed = [];

        if (selectedStatusFilter === 'all') {

            setAllModules(preserveAllModules);

            // check to not show recently launched data if there is only one or less MS launched
            if (preserveAllModules.length <= 1) {
                setIsModulesLoading(false);
                return;
            }

            let recentlyLaunched = [];

            // For Recently Launched Modules
            preserveAllModules.forEach((module) => {
                module.forEach((item) => {

                    let daysDiff = moment().diff(moment(item.launchesOn), 'days');

                    // recently launched
                    if (daysDiff <= 30) {
                        if (!(item.completion && moment().diff(moment(item.completedOn), 'days') > 1)) recentlyLaunched.push(item)
                    }
                });
            });

            recentlyLaunched.sort((a, b) => (moment(b.launchesOn) - moment(a.launchesOn)));
            setRecentlyLaunchedData(recentlyLaunched);
            setIsModulesLoading(false);
        } else {

            preserveAllModules.forEach((module) => {
                const tempArr = [];
                module.forEach((item) => {

                    let completionPerTemp = Math.round((item.completedChallenges * 100) / item.totalActivechallenges);
                    let completionPer = item.totalActivechallenges === 0 ? 0 : completionPerTemp;

                    // not started
                    if (selectedStatusFilter === 'notStarted') {
                        if (completionPer === 0) tempArr.push(item);
                        return;
                    }

                    //  in progress
                    if (selectedStatusFilter === 'inProgress') {
                        if (completionPer > 0 && completionPer < 100) tempArr.push(item);
                        return;
                    }

                    // completed
                    if (selectedStatusFilter === 'completed') {
                        if (completionPer === 100) tempArr.push(item);
                        return;
                    }
                });


                if (selectedStatusFilter === 'notStarted') {
                    if (!isEmpty(tempArr)) notStarted.push(tempArr);
                    return;
                }

                if (selectedStatusFilter === 'inProgress') {
                    if (!isEmpty(tempArr)) inProgress.push(tempArr);
                    return;
                }

                if (selectedStatusFilter === 'completed') {
                    if (!isEmpty(tempArr)) completed.push(tempArr);
                    return;
                }
            });

            if (selectedStatusFilter === 'notStarted') {
                notStarted.sort((a, b) => (moment(b.launchesOn) - moment(a.launchesOn)));
                setAllModules(notStarted);
                setIsModulesLoading(false);
                return;
            }

            if (selectedStatusFilter === 'inProgress') {
                inProgress.sort((a, b) => (moment(b.launchesOn) - moment(a.launchesOn)));
                setAllModules(inProgress);
                setIsModulesLoading(false);
                return;
            }

            if (selectedStatusFilter === 'completed') {
                completed.sort((a, b) => (moment(b.completedOn) - moment(a.completedOn)));
                setAllModules(completed);
                setIsModulesLoading(false);
                return;
            }

        }
    }

    const fetchUserProfileDetails = async () => {
        const apiResp = await getUserProfile();
        if (apiResp.status === 200 || apiResp.status === 201) {
            let userProfileData = apiResp?.data?.data;
            if (!isEmpty(userProfileData)) {
                dispatch(setUserProfileData(userProfileData));
                if (!isEmpty(userProfileData?.languagePref)) {
                    dispatch(setSelectedLanguage(userProfileData.languagePref))
                    let langCode = constants.LANGUAGES_LIST?.filter(e => e.name === userProfileData.languagePref)[0]
                    i18n.changeLanguage(langCode?.key);

                    if (!isEmpty(userDetail) && (isEmpty(userDetail?.companyLogo) || isEmpty(userDetail?.companyTheme))) {
                        let companyLogo = userProfileData?.companyData.logo
                        let companyTheme = userProfileData?.companyData.theme
                        dispatch(setUser({ ...userDetail, companyLogo, companyTheme }))
                    }
                } else {
                    dispatch(setSelectedLanguage('Default'))
                }
            }
        } else { // if any error occured, set Default language as UI language
            dispatch(setSelectedLanguage('Default'))
        }
    }

    function isKeyExists(obj, key) {
        if (isEmpty(obj)) return false;
        return Object.keys(obj).includes(key);
    }

    const handleModuleDataAsync = async ({ search }) => {

        let queryParam = `?startIndex=0&limit=1000&sort=createdOn&sortDirection=desc`;
        queryParam += selectedLanguage !== "Default" ? `&languages=${selectedLanguage}` : ''
        queryParam += !isEmpty(selectedMicrokillCategories) ? `&categoryIds=${selectedMicrokillCategories.map(elem => elem.id).join(",")}` : ''

        const moduleData = await getAllModulesAsync(queryParam);

        if (moduleData && [200, 201].includes(moduleData.status)) {

            setIsMsFetched(true);

            // extract data from module
            const extractedData = moduleData?.data?.data;

            // util function
            function setIfNotEmpty(key, setter) {
                if (isKeyExists(extractedData, key) && !isEmpty(extractedData[key])) {
                    setter(extractedData[key]);
                }
            };

            if (isNativeApp) {
                const turnOffInterval = setInterval(() => {
                    webViewJavaScriptBridge.sendMessage({ action: 'TurnOffNativeSplashScreenChannel', params: { data: true, screenshot: !(isBajajICV || isHaleon) }, })
                }, 1000);

                setTimeout(() => {
                    clearInterval(turnOffInterval);
                }, 15 * 1000);
            }

            setModuleDataList(moduleData);
            setLearningJourneyData(extractedData?.learnerJourney);

            setIfNotEmpty("bulletIn", setBulletinData);
            setIfNotEmpty("newsBulletin", setNewsBulletin);
            setIfNotEmpty("forms", setFormsData);

            setNoMicroskill(
                extractedData?.microskills.length === 0 &&
                isEmpty(extractedData?.learnerJourney) &&
                isEmpty(headerSearchText)
            );

            setModulesOriginalData(extractedData?.microskills);

        } else if (moduleData.status === 500) {
            setIsModulesLoading(false);
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }))
        }
    };

    useEffect(() => {
        if (noMicroskill) return
        let splashScreenTime = isIndigo ? 5000 : 3000;
        setTimeout(() => {
            dispatch(setSplashScreenViewFlag(true))
        }, splashScreenTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noMicroskill]);

    useEffect(() => {
        if (isEmpty(moduleDataList)) return;

        modifyModuleData(moduleDataList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens, viewMicroskillByCategory, moduleDataList])

    useEffect(() => {
        if (!isEmpty(modulesOriginalData)) handleRecentlyLearnedFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modulesOriginalData]);

    const handleRecentlyLearnedFunc = (d = {}) => {
        let temptRecentlyLearnedIds = [...recentlyLearnedIds];
        if (!isEmpty(d)) temptRecentlyLearnedIds = [d?.microskillId, ...temptRecentlyLearnedIds];
        // if (!isEmpty(d)) temptRecentlyLearnedIds?.unshift(d?.microskillId);

        // cleanup recently learned old ids
        const recentlyLearnedIdsSet = new Set();
        let tempList = [];
        modulesOriginalData.forEach(n => {
            let tempFind = temptRecentlyLearnedIds.find(m => m === n.Microskill.id)
            if (!isEmpty(tempFind)) {
                recentlyLearnedIdsSet.add(tempFind);
                tempList.push(n);
            }
        });

        setRecentlyLearned(tempList);
        dispatch(handleRecentlyLearned([...recentlyLearnedIdsSet]));
    }

    useEffect(() => {
        if (url.includes("/learn")) {
            dispatch(setSelectedMicroskillCategories([]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const handleViewClick = () => {
        let privacyPolicyObj = { ...privacyPolicy, policyAccepted: false };
        dispatch(setPrivacyPolicyDetails(privacyPolicyObj));
    };

    useEffect(() => {
        // For Date Format:
        const timeLimit = privacyPolicy?.timeLimit;
        const policyAcceptedOn = moment(privacyPolicy?.policyAcceptedOn).format('DD-MM-YYYY hh:mm:ss A');
        const policyCreatedOn = moment(privacyPolicy?.policyCreatedOn).format('DD-MM-YYYY hh:mm:ss A');
        const expirationDate = moment(policyCreatedOn, 'DD-MM-YYYY hh:mm:ss A').add(timeLimit, 'days').format('DD-MM-YYYY hh:mm A');
        setPolicyExpirationDate(expirationDate);
        const numberOfSeconds = moment(policyCreatedOn, 'DD-MM-YYYY hh:mm:ss A').diff(moment(policyAcceptedOn, 'DD-MM-YYYY hh:mm:ss A'), 'seconds');
        setIsPolicyWarning(numberOfSeconds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchBarClick = () => {
        setIsPlaceholderVisible(true);
        setIsSearchPageVisible((prev) => !prev);
    }

    const handleStatusFilterClick = (currentStatus) => {
        setSelectedStatusFilter(currentStatus);
    }

    useEffect(() => {

        if (isEmpty(preserveAllModules)) return;
        if (isEmpty(preserveAllModules) && isMsFetched) {
            setIsModulesLoading(false);
            return;
        };

        handleCategoryStatusData(selectedStatusFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatusFilter, preserveAllModules]);

    if (noMicroskill) {
        return (
            <div className={classes.learnContainer}
                style={{ minHeight: isMobile ? `calc(${FULL_HEIGHT_MOBILE})` : `calc(${FULL_HEIGHT_DESKTOP})` }}
            >
                <NoMicroskill />
            </div>
        )
    }

    return (
        <div className={classes.learnContainer}
            style={{ minHeight: isMobile ? `calc(${FULL_HEIGHT_MOBILE})` : `calc(${FULL_HEIGHT_DESKTOP})` }}
        >

            {/* Search */}
            {
                !isSearchPageVisible ?
                    (<div className={classes.searchBoxContainer}
                        onClick={handleSearchBarClick} >
                        <SearchBar />
                    </div>) :
                    (<SearchPage
                        handleSearchBarClick={handleSearchBarClick}
                        isSearchPageVisible={isSearchPageVisible}
                        moduleDataList={moduleDataList}
                        isPlaceholderVisible={isPlaceholderVisible} />)
            }
            {/* Search */}

            {/* Status Menu : Mobile & Tablet */}
            {
                (isMobile || isTablet) &&
                <div className={classes.privacyPolicyWarningContainer}>
                    <ProgressStatusMenu
                        handleStatusFilterClick={handleStatusFilterClick}
                        selectedStatusFilter={selectedStatusFilter}
                    />
                </div>
            }
            {/* Status Menu : Mobile & Tablet */}

            {/* Privacy Policy  */}
            {
                (privacyPolicy?.policyAccepted === true) &&
                (isPolicyWarning > 0) && (
                    <div className={classes.privacyPolicyWarningContainer}>
                        <PrivacyPolicyBanner
                            expirationDate={policyExpirationDate}
                            handleViewClick={handleViewClick} />
                    </div>
                )
            }
            {/* Privacy Policy  */}

            <div className={classes.salutationText}>
                {t("Hi")} {commonUtil.getUserName()}!
            </div>


            {/* Forms & Bulletins */}
            {isEmpty(selectedMicrokillCategories) &&
                <CarouselComponent
                    isModulesLoading={isModulesLoading}
                    bulletinData={bulletinData}
                    newsBulletinData={newsBulletin}
                    launchedFormsData={formsData} />
            }
            {/* Forms & Bulletins */}

            {/* Status Menu : Desktop  */}
            {(!isMobile && !isTablet) &&
                <div className={classes.privacyPolicyWarningContainer}>
                    <ProgressStatusMenu
                        handleStatusFilterClick={handleStatusFilterClick}
                        selectedStatusFilter={selectedStatusFilter}
                    />
                </div>}
            {/* Status Menu : Desktop  */}

            <LearningJourneyContainer data={learningJourneyData} />
            {
                !isModulesLoading ? (
                    <div className={classes.root}>

                        {
                            <>
                                {
                                    !isEmpty(recentlyLaunchedData) && (selectedStatusFilter === 'all') && (
                                        <MicroskillCategoryContainer
                                            data={recentlyLaunchedData}
                                            isRecentlyLaunched={true}
                                            name={"recently_launched"}
                                            handleRecentlyLearned={() => { }}
                                            checked={viewMicroskillByCategory}
                                        />
                                    )
                                }

                                {
                                    selectedStatusFilter !== 'all' && isEmpty(allModules) ? (
                                        <div className={classes.noMicroskillFound} >

                                            <div className={`${classes.centerFlex} ${classes.lottieBox}`}>
                                                <Lottie
                                                    loop={true}
                                                    style={{ width: isMobile ? '100%' : '80%', height: '100%' }}
                                                    play
                                                    animationData={noMicroskillAnimation}
                                                />
                                            </div>

                                        </div>
                                    ) : (
                                        Object.entries(allModules).map(([key, value]) => (
                                            <div key={`microskill-${key}`}>
                                                <MicroskillCategoryContainer data={value}
                                                    name={key}
                                                    handleRecentlyLearned={handleRecentlyLearnedFunc}
                                                    checked={false} />
                                                {!isMobile && (<div className={classes.divider} />)}
                                            </div>
                                        ))
                                    )
                                }
                            </>
                        }
                    </div>
                ) : (
                    <ShimmerLearn />
                )
            }

            {
                (logos?.darkSplashScreenLogo || logos?.lightSplashScreenLogo) && isMobile && !isSplashScreenViewed && (
                    <SplashScreen />
                )
            }
        </div>
    )
}

export default Learn;

