/* eslint-disable react-hooks/rules-of-hooks */
import { createSearchParams, useNavigate } from "react-router-dom";
import { getChallengeLists } from "../redux/microskill/microskill.api";
import isEmpty from "./isEmpty";
import { getSpotlightResultCompleted } from "../redux/spotlight/spotlight.api";

const getChallengeListData = async (
    microskillId,
    challengeId,
    setNextChallengeData,
    setShowNextChallengeBtn,
    setShowCertificate,
) => {
    try {
        // Fetch challenge list data
        const moduleData = await getChallengeLists(microskillId);

        // Check if the request was not successful
        if (moduleData.status !== 200 && moduleData.status !== 201) {
            setShowNextChallengeBtn(false);
            setShowCertificate(false);
            return;
        }

        const challenges = moduleData?.data?.data?.challenges || [];
        const sortedChallenges = challenges.sort((a, b) => a.sequence - b.sequence);

        // Find the current challenge
        const currentChallenge = sortedChallenges.find(challenge => challenge.challengeId === challengeId);
        if (isEmpty(currentChallenge)) {
            console.error("Challenge not found");
            setShowNextChallengeBtn(false);
            setShowCertificate(false);
            return;
        }

        const currentSequence = currentChallenge.sequence;
        const totalChallenges = sortedChallenges.length;
        const isCompletion = moduleData?.data?.data?.microskill?.completion || false;
        const isCertificateEnabled = moduleData?.data?.data?.microskill?.Microskill?.certificate;

        // Determine the next steps based on the current sequence
        if (currentSequence === totalChallenges && isCompletion && isCertificateEnabled) {
            setShowCertificate(true);
            setShowNextChallengeBtn(false);
        } else if (isCompletion && isCertificateEnabled) {
            setShowCertificate(true);
            setShowNextChallengeBtn(false);
        }
        else if (currentSequence < totalChallenges) {
            const nextChallenge = sortedChallenges[currentSequence];
            setNextChallengeData(nextChallenge);
            setShowNextChallengeBtn(true);
            setShowCertificate(false);
        } else {
            setShowNextChallengeBtn(false);
            if (isCompletion && isCertificateEnabled) {
                setShowCertificate(true);
                setShowNextChallengeBtn(false);
            }
        }
    } catch (error) {
        console.error("Error in getChallengeListData:", error);
        setShowNextChallengeBtn(false);
        setShowCertificate(false);
    }
};

const getSpotlightResult = async (challengeId, microskillId, navigate) => {

    let respData = await getSpotlightResultCompleted({ microskillId, challengeId });
    if (respData.status === 200 || respData.status === 201) {
        let challengeData = respData.data.data.spotlightChallengeData;
        navigate(`/spotlight-result/${microskillId}/${challengeId}/${respData.data.data.userSpotLightResponse.id}/${respData.jobId}`, { state: { challengeData } });
    }
}

const handleChallengeListCardClick = (card, navigate, microskillId, journeyId = null) => {

    const challenge = card?.Challenge;
    const challengeType = challenge?.type?.toUpperCase();
    const challengeId = card?.challengeId;
    const isCompletion = card?.completion;
    const type = card?.Challenge?.type;
    const lj_id = (operator) => !isEmpty(journeyId) ? `${operator}journeyId=${journeyId}` : '';

    // if (!challenge || isEmpty(challengeType) || card?.status?.toUpperCase() === 'LOCKED') return;

    if (type === 'SL' && isCompletion) {
        getSpotlightResult(challengeId, microskillId, navigate);
        return;
    }

    // Helper function to construct the pathname
    const buildPathname = (type, extraPath = '') => `/challenge-list/${microskillId}/${challengeId}/${type}${extraPath}`;

    // Construct query parameters
    const queryParams = `name=${challenge.name}`;

    // Map challenge types to their respective paths
    const typeToPath = {
        'GBA': buildPathname('gba'),
        'LA': buildPathname('learning-aid'),
        'SCORM': buildPathname('scorm', `/${challenge?.setting?.folderName}`),
        'SL': buildPathname('spotlight'),
    };


    const pathname = typeToPath[challengeType];

    if (pathname && challengeType !== 'FORM') {
        navigate({
            pathname,
            search: `?${createSearchParams(queryParams)}${lj_id('&')}`,
            replace: true
        });
    } else {
        const formId = challenge?.formId;
        // navigate(`/challenge-list/${microskillId}?formId=${formId}&challengeId=${challengeId}`)
        navigate({
            pathname: `/challenge-list/${microskillId}/${challengeId}/${formId}`,
            search: `?${createSearchParams(queryParams)}${lj_id('&')}`,
            replace: true
        });
    }
};



const utilityFunctions = {
    getChallengeListData,
    handleChallengeListCardClick,
}

export default utilityFunctions;