import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    formRootWrapper: {
        [theme.breakpoints.down('md')]: {
            height: '100vh',
            position: 'fixed',
            overflow: 'hidden',
            width: '100vw',
        }
    },
    formRoot: {
        userSelect: 'none',
        position: 'relative',
        backgroundColor: theme.palette.black,
        color: theme.palette.white,
        width: '100%',
        padding: '0 1rem',
        height: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        [theme.breakpoints.down('md')]: {
            padding: '0',
            height: `calc(100dvh - ${constants.HEADER_HEIGHT_MOBILE})`,
            aspectRatio: 'none'
        }
    },
    formBox: {
        position: 'relative',
        height: '100%',
        borderRadius: '8px',
        border: '2px solid #FFFFFF',
        aspectRatio: '9/16',
        width: 'auto',
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 'none',
            border: 'none',
            borderRadius: 'inherit'
        }
    },
    backBtnBox: {
        position: 'absolute',
        left: '2rem',
        top: '2rem',
        height: '2rem',
        width: '2rem',
        border: '2px solid #FFF',
        // aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    formHeader: {
        height: constants.HEADER_HEIGHT_MOBILE,
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'flex-start',
        padding: '0.5rem 1rem',
    },
    headerName: {
        fontWeight: 600,
        paddingLeft: '0.5rem',
        display: '-webkit-box',
        width: '100%',
        display: '-webkit-box',
        'line-clamp': 1,
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    }
}))

export default useStyles