import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    loadingForm: {
        height: '100%',
        width: '100%'
    },

    mainContainer: {
        height: "100%",
        display: "grid",
        padding: "0.5rem",
        gridTemplateRows: "2fr 2fr 1fr",
        gap: "1rem",
    },

    infoContainer: {
        display: "grid",
        gap: "0.5rem",
        width: "95%",
        margin: "auto",
        height: "100%",
    },

    closeIconContainer: {
        float: "right",
        justifyContent: "flex-end",

    },

    closeIcon: {
        height: "2rem",
        width: "2rem",
        float: "right",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            height: "1.5rem",
            width: "1.5rem",
        }
    },

    formName: {
        fontSize: "1.5rem",
        fontWeight: "700",
    },

    thanksContainer: {
        // flexDirection: "row",
        flexDirection: "column",
        justifyContent: "flex-start",
        columnGap: "2rem",
        // borderBottom: `2px solid red`

    },

    imgIcon: {
        height: "4rem",
        [theme.breakpoints.up("md")]: {
            fontSize: '5rem'
        }
    },

    tryAgainText: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem'
    },
    greetingText: {
        fontSize: '1rem',
        letterSpacing: '0.5rem',
        wordSpacing: '1rem',
        textAlign: 'center',
        [theme.breakpoints.up("md")]: {
            fontSize: '1.5rem'
        }
    },
    usernameText: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '1.5rem',
        [theme.breakpoints.up("md")]: {
            marginBottom: '1rem',
            fontSize: '2rem'
        }
    },
    msgText: {
        fontSize: '0.7rem',
        textAlign: 'center',
        lineHeight: '1.5',
        [theme.breakpoints.up("md")]: {
            fontSize: '1rem'
        }
    },
    dateText: {
        fontSize: '0.7rem',
        fontWeight: '700',
        [theme.breakpoints.up("md")]: {
            fontSize: '1rem'
        }
    },

    sectionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    scoringContainer: {
        flexDirection: 'column',
        padding: '0 1rem',
        gap: '1rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        justifyContent: 'center',
        '&::-webkit-scrollbar': {
            width: '8px',
            background: "#cdcdcd",
            borderRadius: '5px',

            [theme.breakpoints.down('sm')]: {
                width: '5px',
            }
            // display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '5px'
        }

    },
    sectionText: {
        fontSize: '1rem',
        fontWeight: '600',
        [theme.breakpoints.up("md")]: {
            fontSize: '1.2rem'
        }
    },
    sectionValue: {
        fontSize: '1rem',
        fontWeight: '600',
        [theme.breakpoints.up("md")]: {
            fontSize: '1.2rem'
        }
    },

    totalScoreContainer: {
        fontFamily: 'Montserrat',
        flexDirection: 'column',
        rowGap: '1rem',
    },
    scoreText: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontWeight: 900,
        [theme.breakpoints.up("md")]: {
            fontSize: '2rem'
        }
    },
    scoreValue: {
        fontSize: '3rem',
        textTransform: 'uppercase',
        fontWeight: 900,
        [theme.breakpoints.up("md")]: {
            fontSize: '4rem'
        }
    },

    buttonContainer: {
        padding: '0rem 1rem',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'flex-end',
        paddingBottom: '1rem'
    },
    exitButton: {
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
        textTransform: 'uppercase !important',
        color: `${theme.palette.white} !important`
    },

}));

export default useStyles;