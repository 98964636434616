import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// mui
import CircularProgress from '@mui/material/CircularProgress';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';

// utils
import axiosInstance from "../../utils/axios";
import isEmpty from "../../utils/isEmpty";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import { markRepoViewed } from "../../redux/content-repo/content-repo.api";
import useGlobalAbortController from "../../utils/abortController";

const PDFJS = window.pdfjsLib;

const PDFviewerSafari = (props) => {
    const { docData: { path, enableFileDownload = true, enableFileShare = true }, from, setIsShareButtonVisible = () => { }, setIsDownloadButtonVisible = () => { }, showPdfControls = true } = props
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isRotatedMobile = useSelector(state => state?.common.isRotatedMobile)

    const [pdf, setPdf] = React.useState("");
    const [images, setImages] = React.useState([]);
    const [zoomScaling, setZoomScaling] = useState(1) // for pdf custom zooming
    const pdfViewerRootRef = useRef()
    const { setNewAbortSignal } = useGlobalAbortController();

    // const {isPdfViewRotate, setIsPdfViewRotate, setIsRotateIconView} = useContext(LearningAidContext)
    // const context = useContext(LearningAidContext)

    const pdfURLdata = async () => {
        if (isEmpty(path)) return
        // api to mark pdf is viewed
        if (from !== 'SALES_ENABLEMENT' && from !== 'GENERIC_DOC_VIEWER') {
            const signal = setNewAbortSignal();
            markRepoViewed(props?.docData?.id, signal);
        }

        const resp = await axiosInstance.get(path, { responseType: 'blob' });
        let file = new File([resp.data], "name");
        const uri = URL.createObjectURL(file);
        let _PDF_DOC = await PDFJS.getDocument({ url: uri });
        setPdf(_PDF_DOC);
    }

    useEffect(() => {
        pdfURLdata();
    }, [path]);

    useEffect(() => {
        pdf && renderPage();
    }, [pdf]);

    const renderPage = async () => {
        // const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canvas");

        for (let i = 1; i <= pdf.numPages; i++) {
            var page = await pdf.getPage(i);
            var viewport = page.getViewport({ scale: 2.5 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            // setWidth(viewport.width);
            // setHeight(viewport.height);
            await page.render(render_context).promise;
            let img = canvas.toDataURL("image/png");
            // imagesList.push(img);
            setImages(prevState => [...prevState, img]);
        }
        // setImages(imagesList);
        setIsShareButtonVisible(enableFileShare);
        setIsDownloadButtonVisible(enableFileDownload); 
    }

    const handlePdfControlClick = (type) => {
        let curLocation = window.location.href
        let isCertificateViewer = curLocation.includes('generic-doc-viewer') && curLocation.includes('microskillCertificate') ? true : false
        let minZoomScaling = isCertificateViewer ? 0.7 : 1
        let zoomInFactor = isCertificateViewer ? 0.2 : 0.25

        if (zoomScaling <= minZoomScaling && (type === 'zoomout')) return
        switch (type) {
            case 'zoomin':
                setZoomScaling(zoomScaling + zoomInFactor)
                break;
            case 'zoomout':
                setZoomScaling(zoomScaling - zoomInFactor)
                break;
            case 'reset':
                setZoomScaling(1)
                break;
            default:
                setZoomScaling(1)
                break;
        }
    }

    const getPdfControlsPosition = (from, isMobile) => {
        if (from === 'GENERIC_DOC_VIEWER') return isMobile ? '3.8rem' : '2rem'
        return from === 'ILA' ? '1rem' : ''
    }

    return (
        <div className={classes.pdfViewerRoot} ref={pdfViewerRootRef}>
            {/* <embed src={path} type="application/pdf" width="100%" height="600px" /> */}

            {
                isEmpty(images) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={`scrollbar ${classes.wrapper}`} style={{
                        maxWidth: isRotatedMobile ? '100%' : '',
                    }}>
                        {/* <embed src={path} type="application/pdf" width="100%" height="600px" /> */}
                        <div
                            className={`${classes.imagesContainer} ${path.includes('microskillCertificate') ? classes.centerFlex : ''}`}
                            style={{
                                maxWidth: `${(zoomScaling + 0.25) * 100}%`,
                                transform: `scale(${zoomScaling})`
                            }}
                        >
                            {
                                !path.includes('microskillCertificate') && images?.map((image, idx) => (
                                    <div
                                        key={idx}
                                        className={`image-wrapper-pdfview ${classes.imageWrapper} ${classes.centerFlex}`}
                                        style={isRotatedMobile ? {
                                            transform: 'rotate(90deg)',
                                            height: '100dvw',
                                            display: 'block',
                                        } : {}}
                                    >
                                        <img
                                            src={image}
                                            alt="pdfImage"
                                            className={`image-pdfview ${classes.image}`}
                                        />
                                    </div>
                                ))}

                            {
                                path.includes('microskillCertificate') && !isEmpty(images) && (
                                    <div
                                        className={`image-wrapper-pdfview ${classes.imageWrapper} ${classes.centerFlex}`}
                                        style={isRotatedMobile ? {
                                            transform: 'rotate(90deg)',
                                            height: '100dvw',
                                            display: 'block',
                                        } : {}}
                                    >
                                        <img
                                            src={images[0]}
                                            alt="pdfImage"
                                            className={`image-pdfview ${classes.image}`}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                )
            }
            {
                !isEmpty(images) && showPdfControls && (
                    <div className={classes.pdfControls} style={{
                        bottom: getPdfControlsPosition(from, isMobile)
                    }}>
                        <div className={classes.pdfControl} onClick={() => {
                            handlePdfControlClick('reset')
                        }}
                            style={{
                                transition: 'visibility 0.2s ease-in-out, opacity 0.2s ease-in-out',
                                visibility: zoomScaling !== 1 ? 'visible' : 'hidden',
                                opacity: zoomScaling !== 1 ? 1 : 0
                            }}
                        >
                            <RestartAltRoundedIcon className={classes.pdfControlIcons} />
                        </div>
                        <div className={classes.pdfControl} onClick={() => {
                            handlePdfControlClick('zoomin')
                        }}>
                            <AddRoundedIcon className={classes.pdfControlIcons} />
                        </div>
                        <div
                            className={classes.pdfControl} onClick={() => {
                                handlePdfControlClick('zoomout')
                            }}
                        >
                            <RemoveRoundedIcon className={classes.pdfControlIcons} />
                        </div>
                    </div>
                )
            }
        </div>
    )

}

export default PDFviewerSafari;