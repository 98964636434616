import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

// mui comps and styles
import { Button, CircularProgress } from "@mui/material";
import useStyles from "./index.styles"; // Assuming you have a styles hook

//utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil"; // Replace with actual utils path
import utilityFunctions from "../../../utils/utilityFunctions"; // Replace with actual utils path


// lotties
import Lottie from "react-lottie-player";
import formSubmissionLottieData from "../../../lottie-assets/Form_Submission_lottie.json"

const FormsWinScreen = ({
  name, // Name of the form
  formData, // Data associated with the form
  attemptData, // Data related to the user's attempt
  handleCloseForm, // Function to close the form
  isChallenge, // Boolean to indicate if it's a challenge
  showResult, // Boolean to indicate if the result should be shown
  score, // User's score
  maxScore, // Maximum possible score
  challengeId, // Current challenge ID
  handleNextButtonClickOnForm, // Function to handle "Next" button click
  journeyId=''
}) => {
  const navigate = useNavigate(); // For navigation
  const { microskillId } = useParams(); // Microskill ID from the route
  const { t, i18n } = useTranslation(); // Translation hook for localization
  const classes = useStyles(i18n); // Styling classes based on localization

  // Local state variables
  const [loading, setLoading] = useState(false);
  const [isChallengeDataLoading, setIsChallengeDataLoading] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [nextChallengeData, setNextChallengeData] = useState({});
  const [showNextChallengeBtn, setShowNextChallengeBtn] = useState(false);

  // Utility functions (passed as props or imported)
  const {
    getChallengeListData, // Fetches data for the next challenge
    handleChallengeListCardClick, // Handles navigation for challenge card click
  } = utilityFunctions;

  // Effect to toggle loading state based on formData and attemptData
  useEffect(() => {
    setLoading(isEmpty(formData) || isEmpty(attemptData));
  }, [formData, attemptData]);

  // Effect to fetch challenge list data whenever microskillId changes
  useEffect(() => {
    if (!microskillId) return;

    setIsChallengeDataLoading(true);
    getChallengeListData(
      microskillId,
      challengeId,
      setNextChallengeData,
      setShowNextChallengeBtn,
      setShowCertificate
    );
    setIsChallengeDataLoading(false);
  }, [microskillId, challengeId, getChallengeListData]);

  // Handles the "Next Challenge" button click
  const handleNextBtnClick = () => {
    handleNextButtonClickOnForm(); // Parent-provided function
    handleChallengeListCardClick(nextChallengeData, navigate, microskillId, journeyId); // Navigates to the next challenge
  };

  // Handles the "Show Certificate" button click
  const handleCertificateClick = () => {
    if (showCertificate) {
      navigate(`/challenge-list/${microskillId}/certificate${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`); // Redirect to certificate page
    }
  };

  // Reusable button component for actions
  const ActionButton = ({ btnText, onClick, variant = "contained" }) => (
    <Button
      variant={variant}
      fullWidth
      size="large"
      className={`fixed_ratio_20_3 ${classes.actionBtn} ${classes.centerFlex}`}
      onClick={onClick}
    >
      {t(btnText)} {/* Translated button text */}
    </Button>
  );

  // Display a loading spinner while form data is being processed
  if (loading) {
    return (
      <div className={`${classes.centerFlex} ${classes.loadingForm}`}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      {/* Form Information Section */}
      <div className={classes.infoContainer}>

        {/* Form Name */}
        <div className={classes.formName}>
          {name}
        </div>

        {/* Thank You Section */}
        <div className={`${classes.thanksContainer} ${classes.centerFlex}`}>
          <div className={classes.centerFlex}>
            {/* Lottie Animation */}
            <Lottie
              animationData={formSubmissionLottieData}
              loop={false} style={{ width: "40%" }}
              play
            />
          </div>
          <div className={classes.thanksText}>
            <div className={classes.greetingText}>THANK YOU</div>
            <div className={classes.usernameText}>{commonUtils.getUserName()}</div>
          </div>
        </div>

        {/* Submission Date */}
        <div className={classes.msgText}>
          You have successfully submitted this form on&nbsp;
          <span className={classes.dateText}>
            {moment().format("DD-MM-YYYY HH:mm A")}
          </span>
        </div>
      </div>

      {/* Scoring Section */}
      {showResult && (
        <div className={`${classes.container} ${classes.scoringContainer}`}>
          <div className={`${classes.centerFlex} ${classes.totalScoreContainer}`}>
            <div className={classes.scoreText}>{t("TOTAL SCORE")}:</div>
            <div className={classes.scoreValue}>
              {score} / {maxScore}
            </div>
          </div>
        </div>
      )}

      {/* Challenge-Specific Buttons */}
      {
        isChallengeDataLoading ? (
          <div className={classes.centerFlex}>
            <CircularProgress />
          </div>
        ) : (
          <div className={`${classes.nextButtonContainer} ${classes.centerFlex}`}>

            {/* Show "Next Challenge" button if applicable */}
            {showNextChallengeBtn && (
              <ActionButton btnText="Next Challenge" onClick={handleNextBtnClick} />
            )}

            {/* Show "Show Certificate" button if applicable */}
            {showCertificate && (
              <ActionButton
                btnText="Show My Certificate"
                onClick={handleCertificateClick}
              />
            )}

            {/* Default "Challenge List" button */}
            <Button
              variant={!showNextChallengeBtn && !showCertificate ? "contained" : "text"}
              fullWidth
              size="large"
              className={`fixed_ratio_20_3 ${classes.exitButton} ${classes.centerFlex}`}
              sx={(theme) => ({
                color: !showNextChallengeBtn && !showCertificate
                  ? theme.palette.white
                  : theme.palette.black,
              })}
              onClick={handleCloseForm}
            >
              {t("Challenge List")}
            </Button>
          </div>
        )}
    </div>
  );
};

export default FormsWinScreen;
