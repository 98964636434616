import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";
// import { isNativeApp } from "../../../utils/commonUtil";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    coverCard: {
        height: '100%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'grid',
        gridTemplateRows: '40% 40% 20%'
    },
    coverCardTitle: {
        fontSize: '4rem',
        textTransform: 'uppercase',
        fontWeight: 800,
        textAlign: 'center',
        padding: '0 0.5rem',
    },
    coverCardDetails: {
        gap: '2.5rem'
    },
    iconBox: {
        gap: '1rem'
    },
    detailText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        fontFamily: 'Open Sans',
    },
    cardRoot: {
        height: '100%',
        position: 'relative',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        [theme.breakpoints.down("md")]: {
            borderRadius: '0rem',
            padding: '0rem',
        }
    },
    // voiceOver sticky btn
    voiceOverStickyBtn: {
        position: 'fixed',
        zIndex: '999',
        top: '10%',
        left: '2%',
        padding: '0.5rem',
        borderRadius: '50%',
        backgroundColor: `rgba(255, 255, 255, 0.75)`,
    },

    // hide cards initially for transition
    hidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 1s, opacity 1s linear',

    },

    // image
    imageCardRoot: {
        maxWidth: 'max-content',
        height: '100%',
        // background: '#fff',
        padding: '1rem',
        margin: 'auto',
        borderRadius: '0.5rem',
        position: 'relative',

        [theme.breakpoints.down("sm")]: {
            padding: '0rem',
            borderRadius: '0rem',
        }
    },
    onlyImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        borderRadius: '0.5rem'
    },
    //body title
    btCardRoot: {
        background: '#FFF',
        padding: '1rem',
        borderRadius: ({ viewFullScreen, isMobile }) => {
            return viewFullScreen || isMobile ? 0 : '0.5rem'
        },
        display: 'grid',
        height: '100%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        // gridTemplateRows: '30% 70%',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '0rem',
        }
    },
    laCardTitle: {
        // fontSize: '2rem',
        fontSize: '2em',
        fontWeight: 800,
        textAlign: 'center',

        // "& *": {
        //     fontSize: '2rem !important'
        // },
        color: palette.greyText1,
        [theme.breakpoints.down("md")]: {
            fontWeight: 700,
            // "& *": {
            //     fontSize: '2rem !important'
            // },
        }
    },
    laCardBodyMain: {
        fontSize: '1.2em',
        fontWeight: 600,
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
        color: palette.greyText1,

        // "& *": {
        //     fontSize: '1.2rem !important'
        // },
        [theme.breakpoints.down("md")]: {
            fontSize: '1.5em',
            fontWeight: 600,
            // "& *": {
            //     fontSize: '1.5rem !important'
            // }
        }
    },
    laCardBody: {
        color: palette.greyText1,
    },
    //body title

    // body image title
    bitCardRoot: {
        backgroundColor: '#FFF',
        padding: '1rem',
        position: 'relative',
        zIndex: '2',
        gridTemplateRows: '20% 80%',
        borderRadius: ({ viewFullScreen, isMobile }) => {
            return viewFullScreen || isMobile ? 0 : '0.5rem'
        },
        display: 'grid',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: '20% 80%',
            paddingTop: '10%',
        }
    },
    bitCardTitle: {
        fontSize: '2.25em',
        fontWeight: 800,
        textAlign: 'center',
        color: palette.greyText1,
        wordBreak: 'break-word',

        // animationName: '$animateGameLandingPage',
        // animationDelay: '1s',
        // animationDuration: '200ms',
        // animationIterationCount: 1,
        // animationTimingFunction: 'ease-in',
        // "& *": {
        //     fontSize: '2rem !important'
        // },
        [theme.breakpoints.down("md")]: {
            fontSize: '2em',
            fontWeight: 700,
            // "& *": {
            //     fontSize: '2rem !important'
            // },
        }

    },
    bitSection: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: '50% 50%',
            gridTemplateColumns: 'auto',
        }
    },
    bitImageBox: {
        width: '100%',
        overflow: 'hidden',

        // animationName: '$animateGameLandingPage',
        // animationDelay: '2s',
        // animationDuration: '200ms',
        // animationIterationCount: 1,
        // animationTimingFunction: 'ease-in',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: '1rem'
        }
    },
    bitCardBody: {
        fontSize: '1.25em',
        fontWeight: 500,
        textAlign: 'left',
        wordBreak: 'break-word',
        alignItems: 'flex-start',
        [theme.breakpoints.up("md")]: {
            paddingLeft: '1.5rem',
            alignItems: 'center',
            // "& *": {
            //     fontSize: '1.5rem !important'
            // }
        },
        color: palette.greyText1,
        // "& *": {
        //     fontSize: '1.25rem !important'
        // }
    },
    // body image title

    // body video title
    videoCardRoot: {
        height: '100%',
        width: '100%',
        position: 'relative',
        backgroundColor: '#fff',
        padding: '1rem',
        borderRadius: ({ viewFullScreen, isMobile }) => {
            return viewFullScreen || isMobile ? 0 : '0.5rem'
        },
        [theme.breakpoints.down("md")]: {
            display: 'grid',
            gridTemplateRows: '60% 40%'
        }
    },
    videoCardBox: {
        display: 'grid',
        height: '100%',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            position: 'static',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    videoBox: {
        position: 'relative',
        maxHeight: '70vh',

        [theme.breakpoints.down("md")]: {
            position: 'static',
            maxHeight: '100%'
        }

    },
    rotateButton: {
        position: 'absolute',
        zIndex: '9990',
        top: `0.5rem`,
        right: '0.5rem',
        // top: `0rem`,
        // right: '0rem',
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        padding: '0.2rem',
        border: 'none',
        color: palette.orange,
        boxShadow: '0px 0px 10px 0px #00000057',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
    rotateBtnPropmpt: {

        position: 'absolute',
        zIndex: '9990',
        top: `-2.5rem`,
        right: '0.5rem',
        borderRadius: '0.25rem',
        backgroundColor: palette.orange,
        padding: '0.5rem',
        color: palette.white,
        transform: 'scale(0)',
        transformOrigin: 'bottom right',
        transition: 'transform 300ms ease-in-out',
        animation: '$scaleUpAndDown 10s 3s 1 ease-in-out',
        [theme.breakpoints.up("md")]: {
            display: 'none'
        },
        "&::before": {
            content: '""',
            height: '1.5rem',
            width: '1rem',
            position: 'absolute',
            clipPath: 'polygon(0% 0%, 100% 0%, 50% 50%)',
            top: '98%',
            right: '0.75rem',
            backgroundColor: palette.orange
        }
    },
    "@keyframes scaleUpAndDown": {
        '0%, 100%': {
            transform: 'scale(0)'
        },
        '10%, 90%': {
            transform: 'scale(1)'
        }
    },
    rotatedVideo: {
        // height: '100%',
        // width: '100%',
        height: '100vw',
        // width: `calc(100vh - ${isNativeApp ? constants.HEADER_HEIGHT_MOBILE : constants.HEADER_HEIGHT})`,
        width: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        background: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100',
        transform: 'translate(-50%, -50%) rotate(90deg)',
    },
    VideoTag: {
        maxHeight: '100%'
    },
    videoBodyRoot: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        padding: '1rem 0',
        width: '100%',
        zIndex: 2,
        wordBreak: 'break-word',
        [theme.breakpoints.down("md")]: {
            position: 'relative',
            bottom: 'auto',
            left: 'auto',
            display: `flex`,
            flexDirection: 'column',
            // textAlign: `center`,
            paddingTop: '1rem',
            gap: '1rem'
        }
    },
    videoTitle: {
        fontSize: '3em',
        fontWeight: 700,
        textAlign: `left`,

        color: palette.greyText1,
        [theme.breakpoints.down("md")]: {
            fontSize: '2.25em',
            textAlign: `center`,

        }
    },
    videoBody: {
        fontSize: '1.5em',
        fontWeight: 500,

        color: palette.greyText1,
        [theme.breakpoints.down("md")]: {
            fontSize: '1.5em',
        }
    },
    videoTextGradient: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '150px',
        zIndex: 1,
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.99) 5%, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.001))'
    },
    // body video title

    backdrop: {
        backgroundSize: 'cover',
        inset: 0,
        position: 'absolute',
        filter: 'blur(50px)',
        backdropFilter: 'blur(1px)',
        borderRadius: '1rem',
        backgroundPosition: 'center',
        [theme.breakpoints.down("md")]: {
            filter: 'none',
            borderRadius: '0rem',
        }
    },

    documentLink: {
        position: 'absolute',
        bottom: '1rem',
        right: '1rem',
        backgroundColor: palette.orange,
        zIndex: 5,
        // padding: '1rem',
        height: '3.2rem',
        width: '3.2rem',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: '0px 0px 8px 0px rgb(0 0 0 / 50%)',
        '& img': {
            width: '1.3rem'
        }
    },
    "@keyframes animateGameLandingPage": {
        '0%': {
            visibility: 'hidden',
            opacity: 0
        },
        '100%': { visibility: 'visible', opacity: 0 }
    },

    rotationBtn: {
        color: '#F4511E !important',
        animation: `$shakeObj 2s infinite linear`,
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(10deg)" },
        "5%": { transform: "rotate(-10deg)" },
        "10%": { transform: "rotate(10deg)" },
        "15%": { transform: "rotate(-10deg)" },
        "20%": { transform: "rotate(10deg)" },
        "25%": { transform: "rotate(-10deg)" },
        "30%": { transform: "rotate(10deg)" },
        "35%": { transform: "rotate(-10deg)" },
        "40%": { transform: "rotate(10deg)" },
        // "45%": { transform: "rotate(-10deg)" },
        // "50%": { transform: "rotate(10deg)" },
        "100%": { transform: "rotate(0deg)" },
    },

    // to auto play image viewer
    rotatedImage: {
        [theme.breakpoints.down("md")]: {
            // position: 'absolute',
            // top: '0%',
            // left: '0%',
            width: `calc(100dvh - ${constants.HEADER_HEIGHT_MOBILE})`,
            height: '100vw',
            transform: 'rotate(90deg) translateX(-50%) ',
            transformOrigin: 'bottom left',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 9
            // top: `-${constants.HEADER_HEIGHT_MOBILE}`,
        },
    }

    // rotatedImage: {
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     width: '100%', /* Full viewport width (since it's rotated, this will act as the height) */
    //     height: '100%', /* Full viewport height (acts as the width after rotation) */
    //     transform: 'rotate(90deg) translate(0, -100%)', /* Adjust translate for the new rotated orientation */
    //     transformOrigin: 'top left', /* Adjusts the point of rotation */
    // }

}))

export default useStyles