import React, { useEffect, useRef, useState } from 'react';

// Styles : 
import useStyles from "./index.styles";

// WaveSurfer :
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

// Utils : 
import axiosInstance from '../../../../utils/axios';
import isEmpty from '../../../../utils/isEmpty';

// Material UI : 
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import CircularProgress from '@mui/material/CircularProgress';  // Import CircularProgress

const AudioContent = ({ audioFileUrl }) => {
    const classes = useStyles();

    // Refs : 
    const waveformRef = useRef(null);
    const waveSurferRef = useRef(null);
    const firstRenderRef = useRef(true);
    const [isPlayBtnVisible, setIsPlayBtnVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // State to track audio loading

    // Function to handle the audio stream using WaveSurfer
    const _handleAudioStream = (audioFile) => {
        const wsRegions = RegionsPlugin.create();

        // Create WaveSurfer instance with the audio URL directly
        waveSurferRef.current = WaveSurfer.create({
            container: waveformRef?.current,
            waveColor: 'hotpink',
            progressColor: 'paleturquoise',
            cursorColor: '#57BAB6',
            cursorWidth: 4,
            minPxPerSec: 100,
            url: audioFile,  // Directly use the audio URL without creating a blob URL
            fillParent: false,
            interact: true,
            hideScrollbar: true,
            // plugins: [wsRegions],
        });

        waveSurferRef.current.on('error', (error) => {
            alert(`Error occurred: ${JSON.stringify(error)}`);
            setIsLoading(false); // Hide loader if error occurs
        });

        // Listen for the finish event
        waveSurferRef.current.on('finish', () => {
            setIsPlayBtnVisible(true); // Show play button again
            waveSurferRef.current.seekTo(0); // Reset playback position
            setIsLoading(false); // Hide loader when audio finishes loading
        });

        // Listen to the "ready" event to indicate that the audio is ready to play
        waveSurferRef?.current?.on('ready', () => {
            setIsLoading(false); // Hide the loader when the audio is ready
        });
    };

    // Fetch the audio file data (direct URL, no blob conversion)
    const audioURLdata = async () => {
        if (!audioFileUrl) return null;
        return audioFileUrl;  // Return the audioFileUrl directly
    };

    useEffect(() => {
        if (isEmpty(audioFileUrl)) return;

        const fetchAudio = async () => {
            const audioUri = await audioURLdata();
            if (audioUri) {
                _handleAudioStream(audioUri);
            }
        };

        if (firstRenderRef.current) {
            fetchAudio();
            firstRenderRef.current = false;
        }

        // Cleanup function
        return () => {
            if (waveSurferRef?.current) {
                waveSurferRef?.current?.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioFileUrl]);

    const playAudio = () => {
        setIsPlayBtnVisible(false);
        waveSurferRef?.current?.play();
    };

    const pauseAudio = () => {
        setIsPlayBtnVisible(true);
        waveSurferRef?.current?.pause();
    };

    return (
        <div className={classes.audioContentWrapper}>
            {
                isLoading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress color="primary" size={60} />
                    </div>
                )
            }
            <div id="waveform" ref={waveformRef} className={classes.waveformStyle}></div>

            {/* Show loader until audio is ready */}
            <div className={classes.audioPlayerBtns}>
                {isPlayBtnVisible ? (
                    <span onClick={playAudio}>
                        <PlayCircleFilledIcon fontSize="inherit" style={{ width: '3em', height: '3em', color: '#F4511e' }} />
                    </span>
                ) : (
                    <span onClick={pauseAudio}>
                        <PauseCircleFilledIcon fontSize="inherit" style={{ width: '3em', height: '3em', color: '#F4511e' }} />
                    </span>
                )}
            </div>
        </div >
    );
};

export default AudioContent;
