import React, { useEffect, useState } from "react";
import moment from "moment";
import Lottie from "react-lottie-player";


// MUI
import { Button, CircularProgress } from "@mui/material";

// Utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil";

// Styles
import useStyles from "./index.styles";

import { useTranslation } from "react-i18next";

// lotties
import formSubmissionLottieData from "../../../lottie-assets/Form_Submission_lottie.json";

const FormsWinScreen = (props) => {

    const { name, formData, attemptData, handleCloseForm, showResult, score, maxScore } = props;

    const [loading, setLoading] = useState(false);
    // const [contextWiseAccuracy, setContextWiseAccuracy] = useState({});

    const { t } = useTranslation()
    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(formData) && !isEmpty(attemptData)) {
            setLoading(false);
        }
    }, [formData, attemptData]);

    return (
        <>
            {
                loading ?
                    (<div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                        <CircularProgress />
                    </div>) :
                    (
                        <div className={classes.mainContainer}>
                            <div className={classes.infoContainer} style={{ width: "100%" }}>
                                <div className={`${classes.closeIconContainer} ${classes.centerFlex}`}>
                                    <img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />
                                </div>

                                <div className={classes.formName} style={{ padding: '1rem' }}>{name}</div>

                                <div className={`${classes.thanksContainer} ${classes.centerFlex}`} style={{ padding: '1rem' }}>
                                    <div className={classes.centerFlex}>
                                        <Lottie
                                            animationData={formSubmissionLottieData}
                                            loop={false}
                                            play={true}
                                            style={{ width: '40%' }}
                                        />
                                    </div>
                                    <div className={classes.thanksText}>
                                        <div className={classes.greetingText}>THANK YOU</div>
                                        <div className={classes.usernameText}>{commonUtils.getUserName()}</div>
                                    </div>
                                </div>
                                <div className={classes.msgText}>
                                    You have successfully submitted this form on &nbsp;
                                    <br />
                                    <span className={classes.dateText}>{moment(new Date()).format("DD-MM-YYYY HH:mm A")}</span>
                                </div>
                            </div>

                            <div className={`${classes.container} ${classes.scoringContainer}`}>
                                {showResult && (
                                    <div className={classes.sectionsWrapper}>
                                        {/* {Object.keys(contextWiseAccuracy).map((contextId, index) => {
                                            return (
                                                <div key={index} className={classes.container}>
                                                    <div className={classes.sectionText}>Section {index + 1}:</div>
                                                    <div className={classes.sectionValue}>{contextWiseAccuracy[contextId]}%</div>
                                                </div>
                                            );
                                        })} */}
                                    </div>
                                )}
                                {showResult && (
                                    <div className={`${classes.centerFlex} ${classes.totalScoreContainer}`}>
                                        <div className={classes.scoreText}>{t('TOTAL SCORE')}:</div>
                                        <div className={classes.scoreValue}>{score} / {maxScore}</div>
                                    </div>
                                )}
                            </div>

                            <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
                                <Button
                                    variant="contained"
                                    className={classes.exitButton}
                                    onClick={() => handleCloseForm()}
                                >Exit</Button>
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default FormsWinScreen;